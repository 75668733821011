import React, { createContext, useEffect, useState } from 'react'
import { useAccount, useMsal } from '@azure/msal-react'
import { protectedResources } from '../settings/authConfig'

interface AuthCtxProviderProps {
  children: React.ReactNode | React.ReactNode[]
}

interface IDTokenInterface {
  Username: string
  at_hash: string
  aud: string
  auth_time: number
  email: string
  exp: number
  family_name: string
  given_name: string
  iat: number
  idp: string
  iss: string
  name: string
  nbf: number
  nonce: string
  sub: string
  tid: string
  upn: string
  ver: string
  roles: RolesInterface
}

export interface RolesInterface {
  Entitlements: string[] | null
  OrgRoles: string[] | null
  SegRoles: string[] | null
}
export interface AuthCtxProviderInterface {
  accessToken: string
  b2cRefreshToken?: () => void
  loggedUser: string
  loginRoles: RolesInterface
  userRole: string
}

export const AuthContext = createContext<AuthCtxProviderInterface>({
  accessToken: '',
  loggedUser: '',
  loginRoles: {} as RolesInterface,
  userRole: '',
})

function AuthContextProvider({ children }: AuthCtxProviderProps) {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [accessToken, setAccessToken] = useState('')
  const [loggedUser, setLoggedUser] = useState<string>('')
  const userName = account?.idTokenClaims?.Username?.toString()
  const index = userName?.indexOf('@')
  sessionStorage.setItem('userName', userName?.slice(0, index).toString() ?? '')
  sessionStorage.setItem(
    'AvatarName',
    account?.idTokenClaims?.name?.toString() ?? ''
  )
  const [loginRoles, setLoginRoles] = useState<RolesInterface>({
    Entitlements: [],
    OrgRoles: [],
    SegRoles: [],
  })
  const [userRole, setUserRole] = useState('')

  useEffect(() => {
    async function b2cAuth() {
      if (account && inProgress === 'none') {
        localStorage.setItem('AcctInfo', JSON.stringify(account))
        instance
          .acquireTokenSilent({
            scopes: protectedResources.api.scopes,
            account: account,
          })
          .then((response: any) => {
            const idTokenDetails = response.idTokenClaims as IDTokenInterface
            if (idTokenDetails?.roles) {
              const getRolesData = JSON.parse(idTokenDetails.roles.toString())
              setLoginRoles(getRolesData)
              if (getRolesData.Entitlements.length > 0)
                setUserRole(getRolesData.Entitlements[0])
            }
            setAccessToken(response.accessToken)
            setLoggedUser(response.idTokenClaims.name)
            // localStorage.removeItem('AccessToken')
            localStorage.setItem('AccessToken', response.accessToken)
          })
      }
    }
    b2cAuth()
  }, [account, inProgress, instance])

  async function b2cRefreshToken() {
    if (account) {
      const newRequestToken = await instance.acquireTokenSilent({
        scopes: protectedResources.api.scopes,
        account: account,
        forceRefresh: true,
      })
      localStorage.setItem('AccessToken', newRequestToken.accessToken)
      setAccessToken(newRequestToken.accessToken)
      return newRequestToken.accessToken
    }
  }

  return (
    <AuthContext.Provider
      value={{ accessToken, b2cRefreshToken, loggedUser, loginRoles, userRole }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
