import { useContext, useEffect, useState } from 'react'
import CheckBoxNew from '../../../components/checkbox/CheckBoxNew'
import Divider from '../../../components/divider/Divider'
import { SvgDisclosure } from '../../../components/svg/SvgAccountType'
import {
  SvgChevronDownMedium,
  SvgChevronUpMedium,
} from '../../../components/svg/SvgChevron'
import { WDLabelStyledCardHeader } from '../../../components/ui/WDLabel'
import { ResponsiveWrapper } from '../../../pages/accountApplicationPage/styles'
import { ApplicationInfoContext } from '../../../services/applicationContext/ApplicationPageContext'
import { DisclosureContext } from '../../../services/disclosureContext/DisclosureContext'
import { getDisclosureList } from '../../../services/disclosureContext/IDisclosure'
import { COLORS } from '../../../theme/Colors'
import { StyledDocumentPanel } from '../accountInformation/styles'
import { DividerWrapper } from '../formWip/styles'
import {
  AppInfoWrapper,
  CheckBoxWrapper,
  Container,
  ContainerOne,
  ContainerTwo,
  FormContainer,
  FormListWrapper,
  TitleGrouping,
  WDLabelClientLabel,
  WDLabelClientValues,
  WipHeadingWrapper,
} from './styles'
import { DownloadFile } from '../../../shared/models/IFormDocuments'
import FormDocsContext from '../../../store/FormDocsContext'

const DisclosureReview = (props: { isPdf?: boolean }) => {
  const { wipId, uploadedFiles, newModelData } = useContext(
    ApplicationInfoContext
  )
  const formDocsContext = useContext(FormDocsContext)
  const [toggleHeader, setToggleHeader] = useState(true)
  const [agreementList, setAgreementList] = useState<any>([])

  useEffect(() => {
    if (newModelData?.wipDetails?.wipid) {
      formDocsContext
        .FetchReqDocs({
          wipid: Number(wipId),
          lobid: newModelData?.wipDetails?.lobid,
          accttypeid:
            newModelData?.wipAccountDetailslist?.[0] &&
            newModelData?.wipAccountDetailslist?.[0]?.accounttypeid,
          acctsubtypeid:
            newModelData?.wipAccountDetailslist?.[0] &&
            newModelData?.wipAccountDetailslist?.[0]?.subaccounttypeid,
        })
        .then((res) => {
          if (res.data && res.data != null && res.data.length > 0) {
            const filterList = res.data.filter((obj) => obj.isdisclosure)
            setAgreementList(filterList)
          }
        })
    }
  }, [newModelData, wipId])
  if (uploadedFiles.filter((file) => file.isdisclosure).length === 0) return null
  return (
    <ResponsiveWrapper id="common">
      <Container>
        <StyledDocumentPanel id={props.isPdf ? 'remove' : ''}>
          <AppInfoWrapper>
            <WipHeadingWrapper
              onClick={() => {
                if (wipId) setToggleHeader(!toggleHeader)
              }}
            >
              <TitleGrouping>
                <SvgDisclosure />
                <WDLabelStyledCardHeader className="header_responsive">
                  {'Account Agreements'}
                </WDLabelStyledCardHeader>
              </TitleGrouping>
              {wipId && !toggleHeader && (
                <SvgChevronDownMedium fillColor={`${COLORS.Text.Gray}`} />
              )}
              {wipId && toggleHeader && (
                <SvgChevronUpMedium fillColor={`${COLORS.Text.Gray}`} />
              )}
            </WipHeadingWrapper>
            {toggleHeader && (
              <DividerWrapper id="product_feature">
                <Divider
                  horizontal={true}
                  bgColor={`${COLORS.Background.Neutral}`}
                  thickness={'0.1rem'}
                />
              </DividerWrapper>
            )}
            {toggleHeader && (
              <FormContainer>
                <FormListWrapper>
                  {uploadedFiles
                    .filter((file) => file.isdisclosure)
                    .map((item: DownloadFile, index: number) => {
                      const dateTime = item?.createdtimestamp
                      const dateObj = dateTime && new Date(dateTime)
                      const formattedDate =
                        dateObj && dateObj.toLocaleDateString()
                      const formattedTime =
                        dateObj &&
                        dateObj.toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true,
                        })
                      const AgreementName = agreementList.find(
                        (obj: any) => obj.docid === item.docid
                      )?.docname
                      return (
                        <CheckBoxWrapper className="container" key={index}>
                          <ContainerOne>
                            <CheckBoxNew disabled checked onChange={() => ''} />
                            <WDLabelClientLabel className="label_responsive">
                              {AgreementName}
                            </WDLabelClientLabel>
                          </ContainerOne>
                          <ContainerTwo>
                            <WDLabelClientValues className="label_responsive">
                              {`Acknowledged by ${
                                newModelData?.wipAccountDetailslist?.[0]?.parties.filter(
                                  (party) => party.relationshipTypeId === 1
                                )[0].partyname
                              } on ${formattedDate}
                            ${formattedTime}`}
                            </WDLabelClientValues>
                          </ContainerTwo>
                        </CheckBoxWrapper>
                      )
                    })}
                </FormListWrapper>
              </FormContainer>
            )}
          </AppInfoWrapper>
          {/* {toggleHeader && (   <div style={{ padding: '0.5rem' }}>
            <SVGButtonWrapper id="red">
              <SvgBackgroundDisclosure />
            </SVGButtonWrapper>
          </div>)} */}
          {/* {toggleHeader && (
            <div style={{ padding: '0.5rem' }}>
              <SVGButtonWrapper id="red">
                <SvgBackgroundDisclosure />
              </SVGButtonWrapper>
            </div>
          )} */}
        </StyledDocumentPanel>
      </Container>
    </ResponsiveWrapper>
  )
}

export default DisclosureReview
