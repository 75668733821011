import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'

export const RootContainer = styled.div`
  width: 100%;
  gap: 1rem 0;
  color: ${COLORS.UI.Text};
  display: grid;
  grid-column: 1/13;
  background-color: ${COLORS.Background.NeutralMedium};
`
export const AccountInfoBtnWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  align-self: flex-end;
  /* padding: 0.5rem 0rem; */
  gap: 0.6rem;
  /* @media (min-width: 1024px) and (max-width: 1440px) {
    width: 900px;
  }
  @media (min-width: 1440px) {
    width: 1072px;
  } */
`
export const AccountFooterButtonGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #fff;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
  box-shadow: 0 2px 2px 0 rgba(0, 36, 93, 0.15);
  /* &#stage4 {
    padding-right: 0.75rem;
  } */
`
export const CreateWipFooterGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #fff;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
  box-shadow: 0 2px 2px 0 rgba(0, 36, 93, 0.15);
`
export const GridContainerNew = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  gap: 16px;
  height: calc(-228px + 100vh);
  background: ${COLORS.Background.NeutralBackgroundMedium};
  margin-top: 16px;
  margin-bottom: 8px;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
  }

  @media screen and (max-width: 576px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
  }

  @media screen and (max-width: 375px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
  }
  @media screen and (max-width: 768px) {
    &#approvalGrid {
      row-gap: 0px !important;
    }
  }
`

export const ItemWrapper = styled.div`
  row-gap: 16px;
  display: grid;
  height: inherit;
  max-height: inherit;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2px 8px 2px 16px;
  position: static;
  top: 8%;
  &#full_col {
    z-index: 1;
  }
  #left_grp_next {
    row-gap: 16px;
    display: grid;
    height: fit-content;
  }
  ::-webkit-scrollbar {
    background: ${COLORS.Background.Gray30};
    width: 5px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    height: 100px;
    background: ${COLORS.Background.Primary10};
    top: 8px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(167, 175, 188, 0.3);
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  &.new_wip_padding {
    padding: 0px 16px 16px 16px !important;
  }
  @media screen and (min-width: 1400px) {
    &#doc_scr {
      padding: 0px 0px 16px 0px;
      grid-column: 1 / span 12;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1400px) {
    &#left_col {
      padding: 0px 8px 18px 0px;
      grid-column: span 4;
      height: inherit;
      max-height: inherit;
    }
    &#right_col {
      padding: 0px 2px 18px 0px;
      grid-column: span 8;
    }
    &#full_col {
      padding: 0px 0px 16px 0px;
      grid-column: span 12;
    }
    &#doc_scr {
      padding: 0px 0px 16px 0px;
      grid-column: 2 / span 10;
    }
  }
  @media screen and (min-width: 1400px) {
    &#left_col {
      padding: 2px 8px 2px 16px;
      grid-column: span 4;
    }
    &#right_col {
      padding: 2px 8px 2px 0px;
      grid-column: span 8;
    }
    &#full_col {
      padding: 2px 8px 2px 16px;
      grid-column: span 12;
    }
  }
  ${({ sm, md, lg }: any) => `
    @media screen and (max-width: 768px) {
      grid-column: span ${sm} !important;
      padding: 0px 16px !important;
    }
 
    @media screen and (max-width: 576px) {
      grid-column: span ${md} !important;
    }
 
    @media screen and (max-width: 375px) {
      grid-column: span ${lg} !important;
    }
  `}
`

export const NavHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: ${COLORS.Background.NeutralBackgroundMedium};
`
export const FooterWrapper = styled.div`
  background: ${COLORS.Background.NeutralBackgroundMedium};
  padding: 8px 16px;
`
export const GridAreas = styled.div`
  &#page {
    display: grid;
    width: 100%;
    grid-template-rows: auto auto 1fr;
    /* gap: 1rem; */
    background-color: ${COLORS.Background.NeutralMedium};
    @media screen and (min-width: 1400px) {
      /* grid-template-rows: 90px; */
    }
    @media screen and ((min-width: 1280px) and (max-width:1440px)) {
      /* grid-template-rows: 90px; */
    }
    @media screen and ((min-width: 1024px) and (max-width:1280px)) {
      /* grid-template-rows: 100px; */
    }
    @media screen and (max-width: 768px) {
      /* grid-template-rows: 150px; */
    }
  }
  &#new_page {
    display: grid;
    width: 100%;
    grid-template-rows: 50px 1fr 70px;
  }
`
export const ErrorToastWrapper = styled.div`
  display: grid;
  grid-column: 1 / 13;
  #full_col {
    padding-bottom: 0px !important;
  }
`
export const ResponsiveWrapper = styled.div`
  display: grid;
  grid-column: 1 / 13;
  gap: 0.5rem;
  .header_responsive {
    @media screen and (min-width: 1440px) {
      font-size: 20px;
    }
    @media screen and ((min-width: 1280px) and (max-width :1440px)) {
      font-size: 18px;
    }
    @media screen and ((min-width: 1024px) and (max-width : 1280px)) {
      font-size: 16px;
    }
  }
  .label_responsive {
    @media screen and (min-width: 1440px) {
      font-size: 16px;
    }
    @media screen and ((min-width: 1280px) and (max-width :1440px)) {
      font-size: 14px;
    }
    @media screen and ((min-width: 1024px) and (max-width : 1280px)) {
      font-size: 12px;
    }
  }
  .inputBox_responsive {
    @media screen and (min-width: 1440px) {
      height: 3rem !important;
      > div {
        height: 3rem !important;
        input {
          height: 3rem !important;
          border-radius: 8px;
          padding: 0.5rem 1rem;
          font-size: 16px;
        }
      }
    }
  }
`

export const ApprovalWrapper = styled.div`
  display: grid;
  grid-column: 1 / 13;
  gap: 1rem;
  ::-webkit-scrollbar {
    background: ${COLORS.Background.Gray30};
    width: 5px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    height: 100px;
    background: ${COLORS.Background.Primary10};
    top: 8px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(167, 175, 188, 0.3);
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }
`
export const RadioWrapper = styled.div`
  display: flex;
  flex: 0 1 30%;
  gap: 0.25rem;
`
export const RadioOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
`
export const LabelWrapper = styled.div`
  padding-left: 1rem;
`
export const ModalInfoWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: center;
  align-content: center;
  position: absolute;
  left: 25%;
  top: 25%;
  right: 25%;
  flex-direction: column;
  padding: 0.5rem;
  background-color: #e6eeff;
  font-family: 'SourceSansPro-Regular';
  font-style: normal;
  width: 50%;
  border: 1px solid #6699ff;
`
export const CommentModalWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: center;
  align-content: center;
  position: absolute;
  left: 25%;
  top: 40%;
  right: 25%;
  flex-direction: column;
  padding: 0.5rem;
  font-family: 'SourceSansPro-Regular';
  font-style: normal;
  width: 50%;
  background-color: #e6eeff;
  border: 1px solid #6699ff;
`
export const ContentInfoWrapper = styled.div`
  display: flex;
  /* background-color: ${COLORS.Background.White}; */
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`

export const RadioInfoWrapper = styled.div`
  display: flex;
  flex: 0 1 30%;
  gap: 1rem;
  flex-direction: column;
`
export const RadioOptionInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
`

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
export const LottieWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  padding: 0.5rem;
`
