import { useContext, useEffect, useState } from 'react'
import {
  StyledLabelSmallWithEllipse,
  WDLabelClient,
  WDLabelSmall,
  WDLabelStyledCardHeader,
} from '../../../components/ui/WDLabel'
import {
  ButtonWrapper,
  ChevronWrapper,
  CollapseButtonWrapper,
  ContentWrapper,
  DocNameWrapper,
  DocWrapper,
  FileNameWrapper,
  FormDocsWrapper,
  MainWrapper,
  ModalContentWrapper,
  ModalWrapper,
  OverlayWrapper,
  ReviewWrapper,
  SectionWrapper,
  TitleDoc,
} from './styles'

import Button from '../../../components/button/Button'
import Divider from '../../../components/divider/Divider'
import Label from '../../../components/label/Label'
import {
  SvgChevronDownMedium,
  SvgChevronUpMedium,
} from '../../../components/svg/SvgChevron'
import SvgEye from '../../../components/svg/SvgEye'
import { SvgDocumentType } from '../../../components/svg/SvgHeaders'
import { SvgPencil } from '../../../components/svg/SvgPencil'
import { SvgYellowWarning } from '../../../components/svg/SvgYellowWarning'
import { WDButton, WDButtonTransparent } from '../../../components/ui/WDButton'
import { ResponsiveWrapper } from '../../../pages/accountApplicationPage/styles'
import { ApplicationInfoContext } from '../../../services/applicationContext/ApplicationPageContext'
import {
  DelteFile,
  DocumentsResponseInterface,
  DownloadFile,
  GetDocumentsListResponseInterface,
} from '../../../shared/models/IFormDocuments'
import { getSessionItem } from '../../../shared/utils/utils'
import FormDocsContext from '../../../store/FormDocsContext'
import { COLORS } from '../../../theme/Colors'
import { MasterWrapperModel } from '../../AOPHomepage/styles'
import { TextLabel } from '../../attachDocument/styles'
import { OptionProps } from '../../selectDropdown/ISelectDropDown'
import { ViewDocument } from '../../viewDocument/ViewDocument'
import { DividerWrapper } from '../accountForms/formIndividual/tabs/ApplicantInfo/styles'
import { Container, StyledDocumentPanel } from '../accountInformation/styles'
import {
  AppInfoWrapper,
  TitleGrouping,
  WipHeadingWrapper,
} from '../electronicPrefs/styles'
import { LobInfoContext } from '../../../services/LobOfficeCode/LobOfficeContext'
import UploadedFiles from './UploadedFiles'
import { toastType } from '../../../shared/config/enums'
import { ToastWrapper } from '../formWip/styles'
import { Toast } from '../../../components/toast/Toast'

const FormDocumentsReview = (props: any) => {
  const applicantOptions = [
    { value: '1', label: 'Primary Applicant' },
    { value: '2', label: 'Co-Applicant' },
  ]
  const {
    wipId,
    requiredDocs,
    individualPartyData,
    setStage,
    setActiveStage,
    stageNames,
    stage,
    getWipResponse,
    newModelData,
    uploadedFiles, setUploadedFiles
  } = useContext(ApplicationInfoContext)
  const [apiPreviewStatus, setAPIPreviewStatus] = useState<{
    status: string
    text: string
  }>({
    status: '',
    text: '',
  })
  const showMessage = (errorMessage: string, status: string) => {
    if (errorMessage != '') {
      setAPIPreviewStatus({
        status: status,
        text: errorMessage,
      })
    }
  }
  const [toggleHeader, setToggleHeader] = useState(true)
  const [preview, setPreview] = useState(false)
  const [popUp, setPopUp] = useState(false)
  const [previewFile, setPreviewFile] = useState<DownloadFile>(
    {} as DownloadFile
  )
  const { lobOfcCode } = useContext(LobInfoContext)

  const [dropdownDocs, setDropdownDocs] = useState<OptionProps[]>([])
  const [requiredDocsList, setRequiredDocsList] = useState<
    GetDocumentsListResponseInterface[]
  >([])
  const Curr_wipId = Number(getSessionItem('wipId'))
  const formDocsContext = useContext(FormDocsContext)
  // const [uploadedFiles, setUploadedFiles] = useState<DownloadFile[]>([])
  const getClientIdTypes = individualPartyData?.clientIds
    ?.filter((obj: { idType: string }) => obj.idType)
    .map((item: { idType: string }) => {
      if (item.idType?.toLowerCase() === 'passport nbr') {
        return (item.idType = 'Passport')
      }
      return item.idType
    })
  const originalList = requiredDocs?.filter((doc) =>
    getClientIdTypes?.includes(doc.name)
  )

  const getDocsDropdown = () => {
    const originListDropDown: OptionProps[] = []
    originalList.forEach((doc) => {
      originListDropDown.push({
        label: doc.name,
        value: doc.docid.toString(),
      })
    })
    setDropdownDocs(originListDropDown)
  }
  const getUploadedDocs = () => {
    //fetch name and container name for uploaded doc
    if (newModelData?.wipAccountDetailslist) {
      formDocsContext
        .GetDocDetails({
          wipid: Curr_wipId,
          lobid: newModelData?.wipDetails?.lobid,
          accttypeid:
            newModelData?.wipAccountDetailslist?.[0] &&
            newModelData?.wipAccountDetailslist?.[0]?.accounttypeid,
          acctsubtypeid:
            newModelData?.wipAccountDetailslist?.[0] &&
            newModelData?.wipAccountDetailslist?.[0]?.subaccounttypeid,
        })
        .then((res: any) => {
          const localUploadedFils: DownloadFile[] = []
          if (res.data && res.data != null && res.data.length > 0) {
            res.data.map((data: DocumentsResponseInterface, index: number) => {
              const getRelationMap =
                newModelData?.wipAccountDetailslist?.[0]?.parties?.find(
                  (obj) => obj.partyId === data?.partyid
                )
              const localUploadobj: DownloadFile = {
                file: data.path.trim(),
                containerName: data?.container?.trim(),
                docid: data.docid,
                wipdocreqid: data.wipdocreqid,
                isacknowledged: data.isacknowledged,
                createdby: data.createdby,
                iscoapplicant: data.iscoapplicant,
                isdisclosure: data.isdisclosure,
                relationshipid: getRelationMap?.relationshipTypeId ?? 1,
                createdtimestamp:data.createdtimestamp,
              }

              localUploadedFils.push(localUploadobj)
            })
            // console
            setUploadedFiles(localUploadedFils)
            getDocsDropdown()
          }
        })
    }
  }

  const previewDocument = (file: DownloadFile) => {
    const getContainerPath = file?.file?.split('/')
    setPreview(true)
    setPreviewFile({ ...file, containerName: getContainerPath[0] })
  }
  const navigateToDocument = () => {
    if (stageNames[stage].stage === 4) {
      setPopUp(true)
    } else {
      setStage(1)
      setActiveStage(1)
    }
  }

  const uploadedDocId = uploadedFiles.map((file) => file.docid)

  const getRequiredDocs = () => {
    if (newModelData?.wipDetails?.wipid) {
      formDocsContext
        .FetchReqDocs({
          wipid: Curr_wipId,
          lobid: newModelData?.wipDetails?.lobid,
          accttypeid:
            newModelData?.wipAccountDetailslist?.[0] &&
            newModelData?.wipAccountDetailslist?.[0]?.accounttypeid,
          acctsubtypeid:
            newModelData?.wipAccountDetailslist?.[0] &&
            newModelData?.wipAccountDetailslist?.[0]?.subaccounttypeid,
        })
        .then((res) => {
          setRequiredDocsList(res.data.filter((obj: any) => !obj.isdisclosure))
        })
    }
  }

  let isCalled = false

  useEffect(() => {
    if (!isCalled) {
      getUploadedDocs()
      isCalled = true
    }
  }, [newModelData?.wipAccountDetailslist])
  const docNamesDropDown: OptionProps[] = []
  requiredDocs.forEach((doc, index) => {
    docNamesDropDown.push({
      label: doc.name,
      value: doc.docid.toString(),
    })
  })

  const deleteDoc = (fileToDelete: any) => {
    if (fileToDelete?.file) {
      const updatedItem = uploadedFiles.filter((file) => {
        return file.file != fileToDelete?.file
      })
      const name = fileToDelete.file.split('/').pop()
      const getFullPath = fileToDelete.file.split('/')
      getFullPath.pop()
      const reqObj: DelteFile = {
        fileName: name ? name : '',
        folderPath: getFullPath.join('/'),
        containerName: getFullPath[0],
        wipdocreqid: fileToDelete.wipdocreqid,
      }

      formDocsContext.DeleteDoc(reqObj).then((res) => {
        if (res.status == 200) {
          showMessage('Document deleted successfully', toastType.SUCCESS)
          getRequiredDocs()
          getUploadedDocs()
          setUploadedFiles(updatedItem)
          showMessage('Document deleted successfully', toastType.SUCCESS)
        } else {
          showMessage('Something went wrong while deleting', toastType.DANGER)
        }
      })
    }
  }

  const downloadDoc = (fileDetails: any) => {
    const filePath = fileDetails?.file.split('/')
    const tempFileDetails = { ...fileDetails, containerName: filePath[0] }
    //show  downloaded doc in new window
    formDocsContext.DownloadDoc(tempFileDetails).then((res) => {
      if (res.data) {
        const fileWindow = window.open('')
        if (fileWindow) {
          if (fileDetails.file.includes('pdf')) {
            const base64ToBlob = (
              base64: any,
              type = 'application/octet-stream'
            ) => {
              const binStr = window.atob(base64)
              const len = binStr.length
              const arr = new Uint8Array(len)
              for (let i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i)
              }
              return new Blob([arr], { type: type })
            }

            const blob = base64ToBlob(res.data, 'application/pdf')
            fileWindow.document.write(
              '<iframe src="' +
              URL.createObjectURL(blob) +
              '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
            )
          } else {
            const base64Flag = `data:application/jpg/png/jpeg;base64,${res.data}`
            fileWindow?.document.write(`<img src=${base64Flag} />`)
          }
          fileWindow.document.title = fileDetails.file.split('/').pop()
        }
      }
    })
  }

  return (
    <ResponsiveWrapper id="common">
      <Container ref={props.pdfNodeRef6}>
        {popUp && (
          <MasterWrapperModel>
            <OverlayWrapper>
              <ModalWrapper>
                <ModalContentWrapper>
                  <SvgYellowWarning fillColor={COLORS.Background.Warning} />
                  <ContentWrapper>
                    <WDLabelClient>
                      <Label>
                        {
                          'The request needs to be re-called to make any updates.​'
                        }
                      </Label>
                    </WDLabelClient>
                    <ButtonWrapper>
                      <WDButton>
                        <Button
                          type={'button'}
                          onClick={() => {
                            setPopUp(false)
                          }}
                        >
                          {'Ok'}
                        </Button>
                      </WDButton>
                    </ButtonWrapper>
                  </ContentWrapper>
                </ModalContentWrapper>
              </ModalWrapper>
            </OverlayWrapper>
          </MasterWrapperModel>
        )}
        <StyledDocumentPanel>
          <AppInfoWrapper>
            <WipHeadingWrapper
              onClick={() => {
                if (wipId) setToggleHeader(!toggleHeader)
              }}
            >
              <TitleGrouping>
                <SvgDocumentType />
                <WDLabelStyledCardHeader className="header_responsive">
                  {'Client Documents'}
                </WDLabelStyledCardHeader>
              </TitleGrouping>
              {wipId && !toggleHeader && (
                <SvgChevronDownMedium fillColor={`${COLORS.Text.Gray}`} />
              )}
              {wipId && toggleHeader && (
                <SvgChevronUpMedium fillColor={`${COLORS.Text.Gray}`} />
              )}
            </WipHeadingWrapper>
            {toggleHeader && (
              <DividerWrapper id="product_feature">
                <Divider
                  horizontal={true}
                  bgColor={`${COLORS.Background.Neutral}`}
                  thickness={'0.1rem'}
                />
              </DividerWrapper>
            )}
            {toggleHeader && (
              <FormDocsWrapper props={props}>
                {uploadedFiles.length > 0 && (
                  <SectionWrapper>
                    {newModelData?.wipAccountDetailslist?.[0]?.parties.map(
                      (item, index) => {
                        const filteredResult: DownloadFile[] =
                          uploadedFiles?.filter(
                            (doc) =>
                              !doc.isdisclosure &&
                              Number(doc.relationshipid) ===
                              item.relationshipTypeId
                          )
                        if (filteredResult.length === 0) return
                        let findDividerType = uploadedFiles.map(
                          (obj) => obj.relationshipid
                        )
                        if (
                          findDividerType.length > 0 &&
                          uploadedFiles.length > 0
                        ) {
                          findDividerType = [
                            ...new Set(
                              uploadedFiles.map((obj) => obj.relationshipid)
                            ),
                          ]
                        }

                        return (
                          <>
                            {newModelData?.wipAccountDetailslist?.[0]?.parties
                              .length > 1 &&
                              index === 1 &&
                              filteredResult.length > 0 &&
                              findDividerType.length > 1 && (
                                <Divider
                                  thickness={'2px'}
                                  height="1rem"
                                  bgColor={COLORS.Background.Neutral}
                                  horizontal={true}
                                />
                              )}
                            {newModelData?.wipAccountDetailslist?.[0]?.parties
                              .length > 1 &&
                              filteredResult.length > 0 && (
                                <TitleDoc className="label_responsive">
                                  {item?.relationshipToPrimaryOwner}
                                </TitleDoc>
                              )}

                            {filteredResult.map((currItem) => {
                              return (
                                <UploadedFiles
                                  key={currItem.docid}
                                  loader={false}
                                  fileToDelete={currItem}
                                  downloadDoc={() => downloadDoc(currItem)}
                                  previewDocument={previewDocument}
                                  handleDelUploadedDoc={() =>
                                    deleteDoc(currItem)
                                  }
                                  isCoApplicant={false}
                                  isDisclosure={false}
                                  uploadedFiles={filteredResult}
                                  requiredDocsList={requiredDocsList}
                                />
                              )
                            })}
                          </>
                        )
                      }
                    )}
                  </SectionWrapper>
                )}
              </FormDocsWrapper>
            )}
          </AppInfoWrapper>
        </StyledDocumentPanel>
        {apiPreviewStatus.status !== '' && (
          <ToastWrapper>
            <Toast
              text={apiPreviewStatus.text}
              type={apiPreviewStatus.status}
              openStatusCallback={(status: boolean) =>
                setAPIPreviewStatus({
                  status: '',
                  text: '',
                })
              }
            />
          </ToastWrapper>
        )}
        {preview && (
          <ViewDocument setModalCallBack={setPreview} rowData={previewFile} />
        )}
        <></>
      </Container>
    </ResponsiveWrapper>
  )
}

export default FormDocumentsReview
