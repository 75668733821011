import React, { useCallback, useContext, useEffect, useState } from 'react'
import Divider from '../../components/divider/Divider'
import { Toast } from '../../components/toast/Toast'
import { WDLabelError } from '../../components/ui/WDLabel'
import { ResponsiveWrapper } from '../../pages/accountApplicationPage/styles'
import { ApplicationInfoContext } from '../../services/applicationContext/ApplicationPageContext'
import { AuthContext } from '../../services/AuthContext'
import { DisclosureContext } from '../../services/disclosureContext/DisclosureContext'
import ToastContext from '../../services/toast/ToastContext'
import { toastType } from '../../shared/config/enums'
import {
  DelteFile,
  DocumentsResponseInterface,
  DownloadFile,
  GetDocumentsListResponseInterface,
} from '../../shared/models/IFormDocuments'
import FormDocsContext from '../../store/FormDocsContext'
import { COLORS } from '../../theme/Colors'
import { AttachDocument } from '../attachDocument/AttachDocument'
import { File } from '../attachDocument/styles'
import { Container } from '../forms/accountInformation/styles'
import DeleteConfirmationModal from '../forms/deleteConfirmationModal/DeletePopUp'
import ListofDocuments from '../forms/formDocuments/DocumentList'
import UploadedFiles from '../forms/formDocuments/UploadedFiles'
import { TitleGrouping, ToastWrapper } from '../forms/formWip/styles'
import PreviewDisclosure from '../previewFile/PreviewDisclosure'
import { ViewDocument } from '../viewDocument/ViewDocument'
import { IDisclosureForm, IFormDoc } from './IManualDisclosures'
import {
  ContentWrapper,
  DocParentWrapper,
  DocumentContainer,
  FileUploadWrapper,
  FormDocsWrapper,
  JointWrapper,
  SecondParagraph,
  StyledDocumentPanel,
  TitleDoc,
  UploadedFilesContainer,
  UploadedFilesWrapper,
  Wrapper,
} from './styles'

const ManualDisclosures = (props: any) => {
  //Context declaration
  const { loggedUser } = useContext(AuthContext)
  const formDocsContext = useContext(FormDocsContext)
  const {
    wipId,
    selectedClient,
    uploadedFiles,
    setUploadedFiles,
    primaryUploadedFiles,

    setPrimaryUploadedFiles,
    coApplicantUploadedFiles,
    setCoApplicantUploadedFiles,
    setUploadError,
    newModelData,
  } = useContext(ApplicationInfoContext)
  const { setToast } = useContext(ToastContext)
  const {
    getResponse,
    coAppDiscs,
    disclosureDocs,
    setDisclosureDocs,
    disableCondition,
  } = useContext(DisclosureContext)

  //State declaration

  const [requiredDocsList, setRequiredDocsList] = useState<
    GetDocumentsListResponseInterface[]
  >([])
  const [selectedApplicant, setSelectedApplicant] = useState<string>('')
  const [apiPreviewStatus, setAPIPreviewStatus] = useState<{
    status: string
    text: string
  }>({
    status: '',
    text: '',
  })
  const [loader, setLoader] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [fileToDelete, setFileToDelete] = useState<DownloadFile>()
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [disclosures, setDisclosures] = useState<File[]>([])
  const [selectedDisclosureIds, setSelectedDisclosureIds] = useState<
    IDisclosureForm[]
  >([])
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<IFormDoc[]>([])
  const [showPopup] = useState(false)
  const [preview, setPreview] = useState(false)
  const [loaderList, setLoaderList] = useState(true)
  const [previewFile, setPreviewFile] = useState<DownloadFile>(
    {} as DownloadFile
  )
  const [spinner, setSpinner] = useState(false)
  const [formErrors, setFormErrors] = useState({
    selctDoctypeError: '',
  })
  const applicantOptions = [
    { value: '1', label: 'Primary Applicant' },
    { value: '2', label: 'Co-Applicant' },
  ]
  const blankErrors = () => {
    setFormErrors({
      selctDoctypeError: '',
    })
    setUploadError('')
  }
  //functions
  const showMessage = (errorMessage: string, status: string) => {
    if (errorMessage != '') {
      setAPIPreviewStatus({
        status: status,
        text: errorMessage,
      })
    }
  }

  const acknowledgedDisclosures = getResponse
    ?.filter(
      (disc) => disc.partyid === selectedClient?.clientId && disc.ischecked
    )
    .map((disc) => disc.disclosureId)

  const acknowledgedDisclosuresCoApp =
    coAppDiscs &&
    coAppDiscs
      ?.filter(
        (disc) =>
          disc.partyid ===
            newModelData.wipAccountDetailslist[0]?.parties[0]?.partyId &&
          disc.ischecked
      )
      .map((disc) => disc.disclosureId)
  const handleDisclosureSelect = (disclosuresList: File[]) => {
    const updatedDisclosureList = [...disclosures, ...disclosuresList]
    setDisclosures((prevFiles) => [...prevFiles, ...disclosuresList])
    //Intialize the docids
    const updatedDisclosureIDs = updatedDisclosureList.map((disclosure) => {
      return {
        name: disclosure.name,
        discId: '',
        docid: '',
        partyid: 0,
      }
    })
    setSelectedDisclosureIds(updatedDisclosureIDs)
    const updatedDocids = updatedDisclosureList.map((file) => {
      return {
        name: file.name,
        docid: '',
        iscoapplicant: undefined,
        partyid: 0,
      }
    })
    setSelectedDocumentIds(updatedDocids)
  }

  const handleDisclosureIdChange = (updatedDocumentIds: IDisclosureForm[]) => {
    setSelectedDisclosureIds(updatedDocumentIds)
  }
  const handleDocumentIdChange = (updatedDocumentIds: IFormDoc[]) => {
    blankErrors()
    setSelectedDocumentIds(updatedDocumentIds)
  }

  const deleteDisclosure = (delFile: DownloadFile) => {
    blankErrors()
    setFileToDelete(delFile)
    setConfirmDelete(true)
  }

  const getUploadedDocs = useCallback(() => {
    formDocsContext
      .GetDocDetails({
        wipid: parseInt(wipId),
        lobid: newModelData.wipDetails.lobid,
        accttypeid:
          newModelData?.wipAccountDetailslist?.[0] &&
          newModelData?.wipAccountDetailslist?.[0]?.accounttypeid,
        acctsubtypeid:
          newModelData?.wipAccountDetailslist?.[0] &&
          newModelData?.wipAccountDetailslist[0]?.subaccounttypeid,
      })
      .then((res) => {
        const localUploadedFils: DownloadFile[] = []
        if (res.data && res.data != null && res.data.length > 0) {
          res.data.map((data: DocumentsResponseInterface) => {
            const getRelationMap =
              newModelData?.wipAccountDetailslist?.[0]?.parties?.find(
                (obj) => obj.partyId === data?.partyid
              )
            const localUploadobj: DownloadFile = {
              file: data?.path?.trim(),
              containerName: data?.path?.trim()?.split('/')[0],
              docid: data?.docid,
              wipdocreqid: data?.wipdocreqid,
              createdtimestamp: data?.createdtimestamp,
              createdby: data?.createdby,
              isacknowledged: data?.isacknowledged,
              isdisclosure: data?.isdisclosure,
              relationshipid: getRelationMap?.relationshipTypeId ?? 1,
            }

            localUploadedFils.push(localUploadobj)
          })
          setUploadedFiles(localUploadedFils)
          const primaryFiles = localUploadedFils.filter(
            (file) => file.relationshipid == 1 && file.isdisclosure
          )
          const secondaryFile = localUploadedFils.filter(
            (file) => file.relationshipid == 2 && file.isdisclosure
          )
          setPrimaryUploadedFiles(primaryFiles)
          setCoApplicantUploadedFiles(secondaryFile)
        } else {
          setUploadedFiles(localUploadedFils)
          setPrimaryUploadedFiles(localUploadedFils)
          setCoApplicantUploadedFiles(localUploadedFils)
        }
      })
  }, [formDocsContext, newModelData, wipId])

  //API calls
  // const uploadDisclosure = (
  //   disclosures: IDisclosureForm[],
  //   isAcknowledged: boolean
  // ) => {
  //   const selectedDisc = disclosureDocs.filter(
  //     (disc) => disc.value == disclosures[0].discId
  //   )
  //   const disclosureID = getResponse.filter(
  //     (disclosure) => disclosure.disclosurelabel == selectedDisc[0].label
  //   )?.[0]?.disclosureId
  //   const payload: EnableDisclosureRequest = {
  //     wipid: parseInt(wipId),
  //     partyid: disclosures[0].partyid,
  //     disclosureid: disclosureID,
  //     ischecked: isAcknowledged ? true : false,
  //     updatedby: loggedUser,
  //   }
  //   setSpinner(true)
  //   enableDisclosure(payload)
  //     .then(() => {
  //       setToast('success', 'Disclosure acknowledged successfully')
  //       getDisclosure({
  //         wipid: parseInt(wipId),
  //         partyid:
  //           newModelData?.wipAccountDetailslist?.[0]?.parties?.[0]?.partyId,
  //         lobid: newModelData?.wipDetails?.lobid,
  //       })
  //       getCoAppDisclosures({
  //         wipid: parseInt(wipId),
  //         partyid:
  //           newModelData?.wipAccountDetailslist?.[0]?.parties?.[0]?.partyId,
  //         lobid: newModelData?.wipDetails?.lobid,
  //       })

  //       setSpinner(false)
  //     })
  //     .catch(() => {
  //       setToast('error', 'Error in acknowledging disclosure,please try again')
  //       setSpinner(false)
  //     })
  // }

  const getClientName = (selectedDocId: any) => {
    if (newModelData && newModelData.wipAccountDetailslist?.[0]) {
      const clientDetails = newModelData.wipAccountDetailslist[0].parties.find(
        (obj) => selectedDocId.partyid === obj.partyId
      )
      return clientDetails?.partyname
    }
  }
  const validate = (disclosure: File) => {
    if (disclosures.length == 0) {
      showMessage('There are no documents to upload.', toastType.WARNING)
      return false
    }
    let checkEmptyDocid = false
    if (
      !selectedDocumentIds.find((file) => file?.name == disclosure?.name)?.docid
    ) {
      checkEmptyDocid = true
    }
    if (checkEmptyDocid) {
      setFormErrors({
        ...formErrors,
        selctDoctypeError: 'Please select document type for all the documents.',
      })
      return false
    }
    if (newModelData?.wipAccountDetailslist?.[0]?.parties.length > 1) {
      const checkExistName =
        disclosure.name ===
        uploadedFiles
          .find(
            (obj) =>
              !obj.isdisclosure &&
              obj.relationshipid === Number(selectedApplicant)
          )
          ?.file.split('/')
          .pop()
      if (checkExistName) {
        showMessage(
          `You have already uploaded files with name: ${selectedFiles[0].name}`,
          toastType.WARNING
        )
        return false
      }
    }
    return true
  }

  const uploadAndAcknowledge = (disclosure: File) => {
    if (disclosure && validate(disclosure)) {
      const getSelectedParty =
        newModelData?.wipAccountDetailslist?.[0]?.parties.find(
          (obj) => obj.relationshipTypeId === Number(selectedApplicant)
        )

      const formData = new FormData()
      //need to check
      const fileName = disclosure.name
      const selectedDocId = selectedDocumentIds.filter(
        (selectedDetails) => selectedDetails.name == fileName
      )[0]
      formData.append(
        'createdby',
        (newModelData &&
          newModelData.wipDetails &&
          getClientName(selectedDocId)) ||
          ''
      )
      formData.append(
        'updatedby',
        (newModelData &&
          newModelData.wipDetails &&
          getClientName(selectedDocId)) ||
          ''
      )
      formData.append(`FormFile[0].docid`, selectedDocId.docid)
      formData.append(`FormFile[${0}].relationshipid`, selectedApplicant)
      formData.append(`FormFile[0].formFile`, disclosure)
      formData.append(`FormFile[0].uploadedby`, loggedUser)
      formData.append(`FormFile[${0}].createdby`, loggedUser)
      // formData.append(`FormFile[0].wipid`, wipId)
      formData.append(
        `FormFile[0].wipacctid`,
        newModelData?.wipAccountDetailslist?.[0]?.wipacctid?.toString()
      )
      formData.append(`FormFile[0].comments`, 'comments')
      formData.append(
        `FormFile[0].partyid`,
        getSelectedParty ? getSelectedParty?.partyId.toString() : ''
      )
      formData.append(`FormFile[0].isdisclosure`, 'true')

      setSpinner(true)
      formDocsContext
        .UploadDocs(formData)
        .then((res) => {
          if (res.status == 200) {
            setToast('success', 'Uploaded')
            setDisclosures(
              disclosures.filter((doc) => doc.name !== disclosure.name)
            )

            getUploadedDocs()
            setSpinner(false)
          } else {
            setSpinner(false)
            setToast('error', 'Error in uploading document,please try again')
          }
        })
        .catch(() => {
          setSpinner(false)
        })
    }
  }

  const deleteAcknowledgement = () => {
    if (fileToDelete?.file) {
      const updatedItem = uploadedFiles.filter((file) => {
        return file.file == fileToDelete?.file
      })
      // const updatedDisclosureIds: IDisclosureForm[] = [
      //   {
      //     name: fileToDelete?.file?.split('/').pop() ?? '',
      //     discId: fileToDelete?.docid.toString() ?? '',
      //     partyid: fileToDelete?.iscoapplicant
      //       ? coAppDiscs.filter((disc) => disc.partyid)[0].partyid
      //       : getResponse.filter((disc) => disc.partyid)[0].partyid,
      //   },
      // ]
      const name = fileToDelete.file.split('/').pop()
      const getFullPath = fileToDelete.file.split('/')
      getFullPath.pop()
      const payload: DelteFile = {
        fileName: name ? name : '',
        folderPath: getFullPath.join('/'),
        containerName: getFullPath[0],
        wipdocreqid: fileToDelete.wipdocreqid,
      }
      setLoader(true)
      formDocsContext.DeleteDoc(payload).then((res) => {
        if (res.status == 200) {
          setLoader(false)
          setSpinner(false)
          setToast('success', 'Document deleted successfully')
          // uploadDisclosure(updatedDisclosureIds, false)
          setUploadedFiles(updatedItem)
          const primaryFiles = updatedItem.filter(
            (file) => !file.iscoapplicant && file.isdisclosure
          )
          const secondaryFile = updatedItem.filter(
            (file) => file.iscoapplicant && file.isdisclosure
          )
          setPrimaryUploadedFiles(primaryFiles)
          setCoApplicantUploadedFiles(secondaryFile)
          getUploadedDocs()
        } else {
          setLoader(false)
          setSpinner(false)
          setToast('error', 'Error in deleting document,please try again')
        }
      })
    }
  }

  const downloadDoc = (fileDetails: any) => {
    //show  downloaded doc in new window
    formDocsContext.DownloadDoc(fileDetails).then((res) => {
      if (res.data) {
        const fileWindow = window.open('')
        if (fileWindow) {
          if (fileDetails.file.includes('pdf')) {
            const base64ToBlob = (
              base64: any,
              type = 'application/octet-stream'
            ) => {
              const binStr = window.atob(base64)
              const len = binStr.length
              const arr = new Uint8Array(len)
              for (let i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i)
              }
              return new Blob([arr], { type: type })
            }

            const blob = base64ToBlob(res.data, 'application/pdf')
            fileWindow.document.write(
              '<iframe src="' +
                URL.createObjectURL(blob) +
                '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
            )
          } else {
            const base64Flag = `data:application/jpg/png/jpeg;base64,${res.data}`
            fileWindow?.document.write(`<img src=${base64Flag} />`)
          }
          fileWindow.document.title = fileDetails.file.split('/').pop()
        }
      }
    })
  }

  //useEffect calls
  useEffect(() => {
    setLoaderList(true)
    const disclosuresList: any[] = []
    if (
      newModelData?.wipAccountDetailslist &&
      newModelData?.wipAccountDetailslist?.[0] &&
      newModelData?.wipDetails
    ) {
      formDocsContext
        .FetchReqDocs({
          wipid: parseInt(wipId),
          lobid: newModelData.wipDetails.lobid,
          accttypeid: newModelData.wipAccountDetailslist[0]?.accounttypeid,
          acctsubtypeid:
            newModelData?.wipAccountDetailslist[0]?.subaccounttypeid,
        })
        .then((res: any) => {
          setLoaderList(false)
          if (res.data && res.data != null && res.data.length > 0) {
            // const uplodedDocIds = uploadedFiles.map((file) => file.docid)
            const requiredDocsList = res.data?.filter(
              (doc: any) => doc.isdisclosure
            )
            if (requiredDocsList && requiredDocsList.length > 0) {
              for (let i = 0; i < requiredDocsList.length; i++) {
                const doc: any = {
                  value: requiredDocsList[i].docid.toString(),
                  label: requiredDocsList[i].docname,
                  relationshipid: requiredDocsList[i].relationshipid,
                }
                disclosuresList.push(doc)
              }
            }
            uploadedFiles.length > 0 &&
              uploadedFiles.find((file) => file.docid == 27)
            setDisclosureDocs(disclosuresList)
            setRequiredDocsList(requiredDocsList)
            setSelectedApplicant(
              disclosuresList?.[0]?.relationshipid?.toString()
            )
          }
        })
    }
  }, [newModelData, wipId])

  useEffect(() => {
    if (newModelData?.wipDetails) {
      if (uploadedFiles) {
        getUploadedDocs()
      }
      // const payload: GetDisclosureRequestInterface = {
      //   wipid: parseInt(wipId),
      //   partyid: newModelData?.wipAccountDetailslist?.[0]?.parties?.filter(
      //     (party) => party.relationshipTypeId === 1
      //   )[0]?.partyId,
      //   lobid: newModelData?.wipDetails?.lobid,
      // }
      // getDisclosure(payload)
      // if (getResponse) {
      //   setGetResponse(getResponse)
      //   setLoaderList(false)
      // }
      // if (
      //   newModelData &&
      //   newModelData.wipAccountDetailslist &&
      //   newModelData.wipAccountDetailslist[0].accttitle == 'Joint'
      // ) {
      //   const payload: GetDisclosureRequestInterface = {
      //     wipid: parseInt(wipId),
      //     partyid: newModelData.wipAccountDetailslist[0]?.parties.filter(
      //       (party) => party.relationshipTypeId === 2
      //     )[0]?.partyId,
      //     lobid: newModelData?.wipDetails?.lobid,
      //   }
      //   getCoAppDisclosures(payload)
      // }
      // if (coAppDiscs) {
      //   setCoAppDiscs(coAppDiscs)
      //   setLoaderList(false)
      // }
    }
  }, [newModelData])

  const previewDocument = (file: DownloadFile) => {
    setPreview(true)
    setPreviewFile(file)
  }

  const handleFileSelect = (files: File[]) => {
    blankErrors()
    const updatedFileList = [...selectedFiles, ...files]
    setSelectedFiles((prevFiles) => [...prevFiles, ...files])

    //Intialize the docids
    const updteDocids = updatedFileList.map((file) => {
      return { name: file.name, docid: '', iscoapplicant: undefined }
    })

    setSelectedDocumentIds(updteDocids)
  }

  return (
    <ResponsiveWrapper id="common">
      <DocParentWrapper>
        <JointWrapper>
          {newModelData &&
            newModelData.wipAccountDetailslist?.[0] &&
            newModelData.wipAccountDetailslist[0]?.parties &&
            newModelData.wipAccountDetailslist[0].parties?.map(
              (applicant, index) => {
                if (applicant.relationshipTypeId === 1) {
                  return (
                    <React.Fragment key={index}>
                      <ListofDocuments
                        listTitle={'List of Agreements'}
                        loaderList={loaderList}
                        documentList={disclosureDocs.filter(
                          (doc: any) =>
                            doc.relationshipid == applicant.relationshipTypeId
                        )}
                        uploadedDocs={uploadedFiles}
                        coApplicant={applicant}
                        isDisclosure={true}
                      />
                    </React.Fragment>
                  )
                }
              }
            )}
        </JointWrapper>
        <Container>
          <StyledDocumentPanel>
            {confirmDelete && (
              <DeleteConfirmationModal
                deleteDoc={deleteAcknowledgement}
                setShowPopup={setConfirmDelete}
                message={
                  'The selected document will be deleted, click Delete to proceed'
                }
              />
            )}
            <FormDocsWrapper props={props}>
              <ContentWrapper>
                <TitleGrouping>
                  <TitleDoc id="List-doc" className="header_responsive">
                    {'Attach Document'}
                  </TitleDoc>
                </TitleGrouping>
                <SecondParagraph>
                  <FileUploadWrapper>
                    <DocumentContainer>
                      <AttachDocument
                        files={selectedFiles}
                        uploadedFiles={uploadedFiles}
                        onFileSelect={handleFileSelect}
                        EXTENSIONS={['pdf']}
                        maxfileuploadsize={5}
                        showAlertMessage={showMessage}
                        isdisable={disableCondition()}
                        showPopup={showPopup}
                        onDisclosureSelect={handleDisclosureSelect}
                        isDisclosure={true}
                      />
                    </DocumentContainer>
                    <Wrapper>
                      <File>
                        {formErrors.selctDoctypeError.length > 0 && (
                          <WDLabelError className="label_responsive">
                            {formErrors.selctDoctypeError}
                          </WDLabelError>
                        )}
                      </File>
                    </Wrapper>
                  </FileUploadWrapper>
                </SecondParagraph>
                <SecondParagraph>
                  {disclosures.length >= 1 &&
                    disclosures.map((disclosure, index) => (
                      <DocumentContainer key={index.toString()}>
                        <PreviewDisclosure
                          // key={disclosures[0].name}
                          disclosureIndex={index}
                          disclosure={disclosure}
                          disclosures={disclosures}
                          setDisclosures={setDisclosures}
                          documentDropdown={
                            uploadedFiles.map((file) => file.docid).includes(27)
                              ? []
                              : !uploadedFiles
                                  .map((file) => file.docid)
                                  .includes(27) && uploadedFiles.length > 0
                              ? disclosureDocs.filter(
                                  (doc: any) =>
                                    doc.value !== '27' &&
                                    !uploadedFiles
                                      .map((file) => file.docid)
                                      .includes(Number(doc.value))
                                )
                              : disclosureDocs.filter(
                                  (doc: any) =>
                                    doc.relationshipid == 1 &&
                                    !uploadedFiles
                                      .map((file) => file.docid)
                                      .includes(Number(doc.value))
                                )
                          }
                          spinner={spinner}
                          acknowledgeDisclosure={uploadAndAcknowledge}
                          onDisclosureIdChange={handleDisclosureIdChange}
                          onDocumentIdChange={handleDocumentIdChange}
                          selectedDisclosureIds={selectedDisclosureIds}
                          setSelectedDisclosureIds={setSelectedDisclosureIds}
                          uploadedFiles={uploadedFiles}
                          selectedDocumentIds={selectedDocumentIds}
                          setSelectedDocumentIds={setSelectedDocumentIds}
                          blankError={blankErrors}
                          disableCondition={disableCondition}
                        />
                      </DocumentContainer>
                    ))}
                  <UploadedFilesContainer>
                    {uploadedFiles.length > 0 &&
                      newModelData &&
                      newModelData.wipAccountDetailslist?.[0] &&
                      newModelData.wipAccountDetailslist[0] &&
                      newModelData.wipAccountDetailslist[0]?.parties?.map(
                        (coApplicant, index) => {
                          return (
                            <React.Fragment key={index}>
                              {coApplicant.relationshipTypeId == 1 &&
                              primaryUploadedFiles?.length ? (
                                <UploadedFilesWrapper>
                                  {newModelData &&
                                  newModelData?.wipAccountDetailslist &&
                                  newModelData?.wipAccountDetailslist[0]
                                    ?.accttitle === 'Joint' ? (
                                    <TitleDoc className="label_responsive">
                                      Primary Applicant
                                    </TitleDoc>
                                  ) : null}
                                  <UploadedFiles
                                    loader={loader}
                                    fileToDelete={fileToDelete}
                                    downloadDoc={downloadDoc}
                                    previewDocument={previewDocument}
                                    handleDelUploadedDoc={deleteDisclosure}
                                    isCoApplicant={false}
                                    isDisclosure={true}
                                    uploadedFiles={uploadedFiles.filter(
                                      (file) =>
                                        file.isdisclosure &&
                                        file.relationshipid == 1
                                    )}
                                    requiredDocsList={requiredDocsList}
                                  />
                                </UploadedFilesWrapper>
                              ) : // : coApplicantUploadedFiles?.length &&
                              //   coApplicant.relationshipTypeId == 2 ? (
                              //   <>
                              //     {primaryUploadedFiles?.length ? (
                              //       <Divider
                              //         thickness={'2px'}
                              //         height="1rem"
                              //         bgColor={COLORS.Background.Neutral}
                              //         horizontal={true}
                              //       />
                              //     ) : null}
                              //     <UploadedFilesWrapper key={index}>
                              //       {newModelData &&
                              //       newModelData?.wipAccountDetailslist &&
                              //       newModelData?.wipAccountDetailslist[0]
                              //         ?.accttitle === 'Joint' ? (
                              //         <TitleDoc className="label_responsive">
                              //           Co-Applicant
                              //         </TitleDoc>
                              //       ) : null}
                              //       <UploadedFiles
                              //         loader={loader}
                              //         fileToDelete={fileToDelete}
                              //         downloadDoc={downloadDoc}
                              //         previewDocument={previewDocument}
                              //         handleDelUploadedDoc={deleteDisclosure}
                              //         isCoApplicant={true}
                              //         isDisclosure={true}
                              //         uploadedFiles={uploadedFiles.filter(
                              //           (file) =>
                              //             file.isdisclosure &&
                              //             file.relationshipid == 2
                              //         )}
                              //       />
                              //     </UploadedFilesWrapper>
                              //   </>
                              // )
                              null}
                            </React.Fragment>
                          )
                        }
                      )}
                  </UploadedFilesContainer>
                </SecondParagraph>
              </ContentWrapper>
              {apiPreviewStatus.status !== '' && (
                <ToastWrapper>
                  <Toast
                    text={apiPreviewStatus.text}
                    type={apiPreviewStatus.status}
                    openStatusCallback={(status: boolean) =>
                      setAPIPreviewStatus({
                        status: '',
                        text: '',
                      })
                    }
                  />
                </ToastWrapper>
              )}
            </FormDocsWrapper>
          </StyledDocumentPanel>

          {preview && (
            <ViewDocument setModalCallBack={setPreview} rowData={previewFile} />
          )}
        </Container>
      </DocParentWrapper>
    </ResponsiveWrapper>
  )
}

export default ManualDisclosures
