import { AxiosResponse } from 'axios'
import { Dispatch, createContext, useContext, useMemo, useState } from 'react'
import { OptionProps } from '../../components/selectDropdown/ISelectDropdown'
import {
  DynamicFormResponseInterface,
  FormErrorInterface,
} from '../../composites/dynamicForm/IDynamicFormControls'
import { Section } from '../../composites/forms/accountForms/formIndividual/tabs/ProductFeatures/IProductFeatures'
import { AddContactResponseInterface } from '../../composites/forms/contactInfo/IContactInfo'
import ElectronicPreferences from '../../composites/forms/electronicPrefs/ElectronicPreferences'
import {
  BestOfInterestType,
  CreateWipRequestInterface,
  GetWipRequestInterface,
  NewWipResponseModel,
  WipResponseModel,
} from '../../composites/forms/formWip/IFormWip'
import {
  RadioProps,
  SocProps,
} from '../../composites/forms/retirementForm/SourceOfContribution/ISourceOfContribution'
import { FundingErrorMessage, InvProfileMsg } from '../../constant/Messages'
import { SuccessCode } from '../../constant/StatusCode'
import { PartyDetailsProps } from '../../pages/accountApplicationPage/iNewGrid'
import { numericType } from '../../shared/config/enums'
import { DownloadFile, RequiredDocs } from '../../shared/models/IFormDocuments'
import { addfundrequestdto } from '../../shared/models/IFunding'
import { getSessionItem } from '../../shared/utils/utils'
import FormFundingContext from '../../store/FormFundingContext'
import { updateWIPAccount } from '../AccountFeature/AccountFeatureAPI'
import AccountFeatureContext, {
  IAccountFeatureStore,
} from '../AccountFeature/AccountFeatureContext'
import { ApprovalContext } from '../approvalScreenContext/ApprovalContext'
import { UpdateCardsRequestInterface } from '../approvalScreenContext/IApproval'
import { AuthContext } from '../AuthContext'
import {
  addDisclosureDashboardAPI,
  deleteBeneficiaryDetails,
  getClientAddressAPI,
  getClientDetailsDataAPI,
  updateAppStatusAPI,
  updateBeneficiaryDetails,
  updateCommentAPI,
  updateTrustedContactDetails,
} from '../clientAndAccountSearch/ClientAndAccountSearchAPI'
import {
  AddDisclosureDashboard,
  ClientAddressResponseInterface,
  ClientByIdPayloadInterface,
  ClientIdPayloadInterface,
  ClientSearchByNameResponseInterface,
  IGovtIdsInterface,
  IndividualClientResponseInterface,
  InvestmentDetailsInterface,
  Party,
  PartyData,
  PartyDemographicInterface,
  PartyInvestmentDetailsData,
  UpdateAppStatusRequestInterface,
  UpdateAppStatusResponseInterface,
  UpdateCommentSection,
  UpdateTrustedContactInterface,
  mockAddressData,
} from '../clientAndAccountSearch/IClientAndAccountSearchContext'
import { getWipDetails } from '../formRequest/FormRequestAPI'
import {
  Beneficiary,
  BeneficiaryPayloadInterface,
  deleteBeneficiaryPayloadInterface,
} from '../Retirement/IRetirementContext'
import RetirementDetailsContext from '../Retirement/RetirementContext'
import ToastContext from '../toast/ToastContext'
import {
  GetSubAcctType,
  addLOBAPI,
  createAccountRequestAPI,
  getAllLOB,
  getStateist,
  submitApplicationAPI,
} from './ApplicationAPI'
import {
  AccountTypesResponseType,
  AddDisclosureFn,
  AddLOBRequestInterface,
  CreateAccountRequestFn,
  CreateAccountRequestInterface,
  GetClientAddressDataFn,
  GetSubAcctTypeFn,
  IDropdown,
  IGetAcctTypeResponse,
  IGetSubAcctType,
  StageNameInterface,
  SubProductModel,
  UpdateAppStatusFn,
  UpdateCommentSectionAPIFn,
  UpdateWIPRequestInterface,
} from './IApplicationPageContext'
import { response } from '../../composites/forms/retirementForm/PersonalInformation/PersonalInfoData'

export interface ApplicationInfoContextInterface {
  wipId: string
  wipAccountId: string
  setWipId: Dispatch<React.SetStateAction<string>>
  setWipAccountId: Dispatch<React.SetStateAction<string>>
  setSelectedClient: Dispatch<
    React.SetStateAction<ClientSearchByNameResponseInterface>
  >
  selectedClient: ClientSearchByNameResponseInterface
  getIndividualPartyData: any
  individualPartyData: IndividualClientResponseInterface
  setIndividualPartyData: Dispatch<
    React.SetStateAction<IndividualClientResponseInterface>
  >
  secondaryPartyData: IndividualClientResponseInterface
  setSecondaryPartyData: Dispatch<
    React.SetStateAction<IndividualClientResponseInterface>
  >
  primaryClient: IndividualClientResponseInterface
  setPrimaryClient: Dispatch<
    React.SetStateAction<IndividualClientResponseInterface>
  >
  invProfileDetails: InvestmentDetailsInterface
  setInvProfileDetails: Dispatch<
    React.SetStateAction<InvestmentDetailsInterface>
  >
  primaryInvProfileDetails: InvestmentDetailsInterface
  setPrimaryInvProfileDetails: Dispatch<
    React.SetStateAction<InvestmentDetailsInterface>
  >
  secondaryInvProfileDetails: InvestmentDetailsInterface
  setSecondaryInvProfileDetails: Dispatch<
    React.SetStateAction<InvestmentDetailsInterface>
  >
  secondaryGovtIds: IGovtIdsInterface
  setSecondaryGovtIds: Dispatch<IGovtIdsInterface>
  primaryGovtIds: IGovtIdsInterface
  setPrimaryGovtIds: Dispatch<IGovtIdsInterface>
  secondaryClient: IndividualClientResponseInterface
  setSecondaryClient: Dispatch<
    React.SetStateAction<IndividualClientResponseInterface>
  >
  wipFormData: CreateWipRequestInterface
  setWipFormData: Dispatch<React.SetStateAction<CreateWipRequestInterface>>
  clientAddress: ClientAddressResponseInterface[] | null
  setClientAddress: Dispatch<
    React.SetStateAction<ClientAddressResponseInterface[]>
  >
  getClientAddress: GetClientAddressDataFn
  submitAccountApplication: any
  getLOBAndEntityCodeDetails: any
  addLOBAndEntityCodeDetails: any
  readOnly: boolean
  setReadOnly: Dispatch<React.SetStateAction<boolean>>
  progressVerify: any
  setProgressVerify: Dispatch<React.SetStateAction<any>>
  exportPdf: boolean
  setExportPdf: Dispatch<React.SetStateAction<boolean>>
  requiredDocs: RequiredDocs[]
  setRequiredDocs: Dispatch<React.SetStateAction<RequiredDocs[]>>
  getWipResponse: WipResponseModel
  bestOfInterest: BestOfInterestType
  setBestOfInterest: Dispatch<React.SetStateAction<BestOfInterestType>>
  setWipResponse: Dispatch<React.SetStateAction<WipResponseModel>>
  selectedAccount: SubProductModel
  setSelectedAccount: Dispatch<React.SetStateAction<SubProductModel>>
  selectedSubAccount: SubProductModel
  setSelectedSubAccount: Dispatch<React.SetStateAction<SubProductModel>>

  updateAppStatus: UpdateAppStatusFn
  addDisclosureDashboard: AddDisclosureFn
  updateCommentSection: UpdateCommentSectionAPIFn
  createAccountRequest: CreateAccountRequestFn
  trustedContact: number
  setTrustedContact: Dispatch<React.SetStateAction<number>>
  createWipFlag: boolean
  setCreateWipFlag: Dispatch<React.SetStateAction<boolean>>
  counter: boolean
  setCounter: Dispatch<React.SetStateAction<boolean>>
  applicationResponse: string
  setApplicationResponse: Dispatch<React.SetStateAction<string>>
  loader: boolean
  setLoader: Dispatch<React.SetStateAction<boolean>>
  contactCardLoader: boolean
  setContactCardLoader: Dispatch<React.SetStateAction<boolean>>
  LOBSubAcctType: IGetAcctTypeResponse[]
  setLOBSubAcctType: Dispatch<React.SetStateAction<IGetAcctTypeResponse[]>>
  GetLOBSubAcctType: GetSubAcctTypeFn
  toggleErrorToast: boolean
  setToggleErrorToast: Dispatch<React.SetStateAction<boolean>>
  accountTypes: AccountTypesResponseType
  setAccountTypes: Dispatch<React.SetStateAction<AccountTypesResponseType>>
  isDashboardNavigate: boolean
  setIsDashboardNavigate: Dispatch<React.SetStateAction<boolean>>
  allClientData: any
  setAllClientData: any
  stageNames: any
  setStageNames: any
  questionValue: DynamicFormResponseInterface
  setQuestionValue: Dispatch<React.SetStateAction<DynamicFormResponseInterface>>
  setResNo: Dispatch<React.SetStateAction<string>>
  resNo: string
  loaderProductIcon: boolean
  setLoaderProductIcon: Dispatch<React.SetStateAction<boolean>>
  buttonTextContact: string
  setButtonTextContact: Dispatch<React.SetStateAction<string>>
  mailingAddress: OptionProps
  setMailingAddress: Dispatch<React.SetStateAction<OptionProps>>
  legalAddress: OptionProps
  setLegalAddress: Dispatch<React.SetStateAction<OptionProps>>
  mobilePhone: OptionProps
  setMobilePhone: Dispatch<React.SetStateAction<OptionProps>>
  otherPhone: OptionProps
  setOtherPhone: Dispatch<React.SetStateAction<OptionProps>>
  businessPhone: OptionProps
  setBusinessPhone: Dispatch<React.SetStateAction<OptionProps>>
  addAccountResponse: AddContactResponseInterface
  setAddAccountResponse: Dispatch<
    React.SetStateAction<AddContactResponseInterface>
  >
  homePhone: OptionProps
  setHomePhone: Dispatch<React.SetStateAction<OptionProps>>
  fax: OptionProps
  setFax: Dispatch<React.SetStateAction<OptionProps>>
  emailAddress: OptionProps
  setEmailAddress: Dispatch<React.SetStateAction<OptionProps>>
  electronicPrefs: ElectronicPreferences
  setElectronicPrefs: Dispatch<React.SetStateAction<ElectronicPreferences>>
  featureaddFunding: addfundrequestdto
  setfeatureaddFunding: Dispatch<React.SetStateAction<addfundrequestdto>>
  banknumber: string
  setbanknumber: Dispatch<React.SetStateAction<string>>
  transferamount: string
  settransferamount: Dispatch<React.SetStateAction<string>>
  depositaccountnumber: string
  setdepositaccountnumber: Dispatch<React.SetStateAction<string>>
  setFormErrors: Dispatch<React.SetStateAction<any>>
  formErrors: any
  risktoleranceValue: OptionProps
  setRisktoleranceValue: Dispatch<React.SetStateAction<OptionProps>>
  LiquidNeedsValue: OptionProps
  setLiquidityNeedsValue: Dispatch<React.SetStateAction<OptionProps>>
  sourceOfFundValues: any[]
  setSourceOfFundValues: Dispatch<React.SetStateAction<any[]>>
  investObjectiveValue: any
  setInvestObjectiveValue: Dispatch<React.SetStateAction<any>>
  timeHorizonValue: OptionProps
  setTimeHorizonValue: Dispatch<React.SetStateAction<OptionProps>>
  liquidityPercentage: string
  setLiquidityPercentage: Dispatch<React.SetStateAction<string>>
  autoSaveFunction: () => Promise<any>
  relation: string
  setRelation: Dispatch<React.SetStateAction<string>>
  trustedParty: any
  setTrustedParty: Dispatch<React.SetStateAction<any>>
  updateTrustedContact: () => Promise<unknown>
  updatePrimaryBeneficiary: (
    relTypeId?: number,
    beneficiary?: Beneficiary[]
  ) => Promise<unknown>
  deleteBeneficiary: (
    relTypeId?: number,
    partyid?: number,
    wipacctid?: number,
    relationshiptypeid?: number
  ) => Promise<unknown>
  saveAll: () => void
  activeStage: number
  setActiveStage: Dispatch<React.SetStateAction<number>>
  stage: number
  setStage: Dispatch<React.SetStateAction<number>>
  setAccountInfoLoader: Dispatch<React.SetStateAction<boolean>>
  accountInfoLoader: boolean
  buttonTextFund: string
  setButtonTextFund: Dispatch<React.SetStateAction<string>>
  buttonTextEp: string
  setButtonTextEp: Dispatch<React.SetStateAction<string>>
  detectChangeProduct: boolean
  setDetectChangeProduct: Dispatch<React.SetStateAction<boolean>>
  detectChangeContact: boolean
  setDetectChangeContact: Dispatch<React.SetStateAction<boolean>>
  detectChangeFund: boolean
  setDetectChangeFund: Dispatch<React.SetStateAction<boolean>>
  detectChangeEp: boolean
  setDetectChangeEp: Dispatch<React.SetStateAction<boolean>>
  detectChangeIp: boolean
  setDetectChangeIp: Dispatch<React.SetStateAction<boolean>>
  detectChangeTc: boolean
  setDetectChangeTc: Dispatch<React.SetStateAction<boolean>>
  selectedCheck: boolean
  setSelectedCheck: Dispatch<React.SetStateAction<boolean>>
  reqFlag: boolean
  setReqFlag: Dispatch<React.SetStateAction<boolean>>
  xrefDetails: any
  setXrefDetails: any
  showError: any
  setShowError: Dispatch<React.SetStateAction<any>>
  accountNumber: string
  setAccountNumber: Dispatch<React.SetStateAction<string>>
  reserveError: boolean
  setReserveError: Dispatch<React.SetStateAction<boolean>>
  contactError: any
  setContactError: Dispatch<React.SetStateAction<any>>
  Validate: () => boolean
  trustedError: any
  setTrustedError: Dispatch<React.SetStateAction<any>>
  formErrorProdFeature: any
  setFormErrorProdFeature: Dispatch<React.SetStateAction<any>>
  updateWIPResponse: UpdateAppStatusResponseInterface
  setUpdateWIPResponse: Dispatch<
    React.SetStateAction<UpdateAppStatusResponseInterface>
  >
  productInfoFieldCheck: any
  setProductInfoFieldCheck: Dispatch<React.SetStateAction<any>>
  sectionConfig: Section[]
  setSectionConfig: Dispatch<React.SetStateAction<Section[]>>
  uploadedFiles: DownloadFile[]
  setUploadedFiles: Dispatch<React.SetStateAction<DownloadFile[]>>
  primaryUploadedFiles: DownloadFile[]
  setPrimaryUploadedFiles: Dispatch<React.SetStateAction<DownloadFile[]>>
  coApplicantUploadedFiles: DownloadFile[]
  setCoApplicantUploadedFiles: Dispatch<React.SetStateAction<DownloadFile[]>>
  uploadError: string
  setUploadError: Dispatch<React.SetStateAction<string>>
  stateList: any
  setStateList: Dispatch<React.SetStateAction<any>>
  GetStateList: () => Promise<AxiosResponse<any>>
  newModelData: NewWipResponseModel
  setNewModelData: Dispatch<React.SetStateAction<NewWipResponseModel>>
  apiPreviewStatus: {
    status: string
    text: string
  }
  setAPIPreviewStatus: Dispatch<
    React.SetStateAction<{
      status: string
      text: string
    }>
  >
  sourceData: RadioProps
  setSourceData: Dispatch<React.SetStateAction<RadioProps>>
  amtData: SocProps[]
  setAmtData: Dispatch<React.SetStateAction<SocProps[]>>
  relationList: any
  setRelationList: Dispatch<React.SetStateAction<any>>
  retirementPersonalInfo: any
  setRetirementPersonalInfo: Dispatch<React.SetStateAction<any>>
  accountType: OptionProps
  setAccountType: Dispatch<React.SetStateAction<OptionProps>>
  tabLoader: boolean
  setTabLoader: Dispatch<React.SetStateAction<boolean>>
  saveFlag: boolean
  setSaveFlag: Dispatch<React.SetStateAction<boolean>>
  assEmpDropDown: IDropdown
  setAssEmpDropDown: Dispatch<React.SetStateAction<IDropdown>>
  regBrokerDropDown: IDropdown
  setRegBrokerDropDown: Dispatch<React.SetStateAction<IDropdown>>
  ownedCompDropDown: IDropdown
  setOwnedCompDropDown: Dispatch<React.SetStateAction<IDropdown>>
  indusAffrelation: string
  setIndusAffRelation: Dispatch<React.SetStateAction<string>>
  entity: string
  setEntity: Dispatch<React.SetStateAction<string>>
  symbolCusip: string[]
  setSymbolCusip: Dispatch<React.SetStateAction<string[]>>
  companyDetails: any
  setCompanyDetails: Dispatch<React.SetStateAction<any>>
}
export const ApplicationInfoContext =
  createContext<ApplicationInfoContextInterface>(
    {} as ApplicationInfoContextInterface
  )
interface ApplicationCtxProviderProps {
  children: React.ReactNode | React.ReactNode[]
}
export const ApplicationPageContextProvider = ({
  children,
}: ApplicationCtxProviderProps) => {
  const { cardsStatus, setCardsStatus, updateCardsStatus } =
    useContext(ApprovalContext)
  const [tabLoader, setTabLoader] = useState(false)
  const [saveFlag, setSaveFlag] = useState(false)

  const [wipId, setWipId] = useState(getSessionItem('wipId'))
  const [wipAccountId, setWipAccountId] = useState(
    getSessionItem('wipAccountId')
  )
  const [uploadError, setUploadError] = useState('')
  // const [wipId, setWipId] = useState(getSessionItem('1853'))
  const [xrefDetails, setXrefDetails] = useState<any[]>([])
  const [loader, setLoader] = useState(false)
  const [contactCardLoader, setContactCardLoader] = useState(false)
  const [accountTypes, setAccountTypes] = useState<AccountTypesResponseType>({
    accountTypeDetails: [],
    lookupDetails: [],
  })
  const [sourceData, setSourceData] = useState<RadioProps>({
    value: '',
    id: '',
  })
  const [amtData, setAmtData] = useState<SocProps[]>([{ year: '', amount: '' }])
  const [selectedCheck, setSelectedCheck] = useState(true)
  const [updateWIPResponse, setUpdateWIPResponse] =
    useState<UpdateAppStatusResponseInterface>(
      {} as UpdateAppStatusResponseInterface
    )
  const [showError, setShowError] = useState({
    repCode: false,
    productType: false,
    subaccounttype: false,
    nickname: false,
    client: false,
  })
  const [investObjectiveValue, setInvestObjectiveValue] = useState<any>({
    label: '',
    value: '',
  })
  const [risktoleranceValue, setRisktoleranceValue] = useState<OptionProps>({
    label: '',
    value: '',
  })
  const [LiquidNeedsValue, setLiquidityNeedsValue] = useState<OptionProps>({
    label: '',
    value: '',
  })
  const [sourceOfFundValues, setSourceOfFundValues] = useState<any[]>([])

  const [timeHorizonValue, setTimeHorizonValue] = useState<OptionProps>({
    label: '',
    value: '',
  })
  //Industry Affiliation state starts
  const [assEmpDropDown, setAssEmpDropDown] = useState<OptionProps>({
    label: 'Select',
    value: '--',
  })
  const [regBrokerDropDown, setRegBrokerDropDown] = useState<OptionProps>({
    label: 'Select',
    value: '--',
  })
  const [ownedCompDropDown, setOwnedCompDropDown] = useState<OptionProps>({
    label: 'Select',
    value: '--',
  })
  const [indusAffrelation, setIndusAffRelation] = useState('')
  const [entity, setEntity] = useState('')
  const [symbolCusip, setSymbolCusip] = useState<string[]>([''])
  const [companyDetails, setCompanyDetails] = useState([
    { company: '', symbol: '' },
  ])
  //Industry Affiliation state ends
  const {
    selectedPrimaryBeneficiary,
    setSelectedPrimaryBeneficiary,
    selectedContingentBeneficiary,
    setSelectedContingentBeneficiary,
    setSelectedAddiParticipant,
    setSelectedBeneOwner,
    selectedAddiParticipant,
  } = useContext(RetirementDetailsContext)
  const [liquidityPercentage, setLiquidityPercentage] = useState<string>('')
  const [productInfoFieldCheck, setProductInfoFieldCheck] = useState({
    repCode: true,
    productType: true,
    subaccounttype: true,
    nickname: true,
  })
  const [contactError, setContactError] = useState({
    legalAddr: false,
    mailingAddr: false,
    mobile: false,
    email: false,
  })

  const [uploadedFiles, setUploadedFiles] = useState<DownloadFile[]>([])
  const [relationList, setRelationList] = useState<any[]>([])
  const [retirementPersonalInfo, setRetirementPersonalInfo] = useState<any[]>(
    response[0]?.objQuestions
  )
  const [primaryUploadedFiles, setPrimaryUploadedFiles] = useState<
    DownloadFile[]
  >([])
  const [coApplicantUploadedFiles, setCoApplicantUploadedFiles] = useState<
    DownloadFile[]
  >([])
  const [accountNumber, setAccountNumber] = useState('')
  const [reserveError, setReserveError] = useState(false)
  const [reqFlag, setReqFlag] = useState<boolean>(false)
  const [selectedClient, setSelectedClient] =
    useState<ClientSearchByNameResponseInterface>(
      {} as ClientSearchByNameResponseInterface
    )
  const [individualPartyData, setIndividualPartyData] = useState(PartyData)
  const [secondaryPartyData, setSecondaryPartyData] = useState(PartyData)
  const [primaryClient, setPrimaryClient] = useState(PartyData)
  const [invProfileDetails, setInvProfileDetails] = useState(
    PartyInvestmentDetailsData
  )
  const [primaryInvProfileDetails, setPrimaryInvProfileDetails] = useState(
    PartyInvestmentDetailsData
  )
  const [secondaryInvProfileDetails, setSecondaryInvProfileDetails] = useState(
    PartyInvestmentDetailsData
  )
  const [primaryGovtIds, setPrimaryGovtIds] = useState<any>({})
  const [secondaryGovtIds, setSecondaryGovtIds] = useState<any>({})

  const [secondaryClient, setSecondaryClient] = useState(PartyData)
  const [clientAddress, setClientAddress] = useState<
    ClientAddressResponseInterface[]
  >([])
  const [LOBSubAcctType, setLOBSubAcctType] = useState<IGetAcctTypeResponse[]>(
    []
  )
  const [trustedError, setTrustedError] = useState({
    relation: false,
  })
  const [formErrorProdFeature, setFormErrorProdFeature] =
    useState<FormErrorInterface>({})

  const [apiPreviewStatus, setAPIPreviewStatus] = useState<{
    status: string
    text: string
  }>({
    status: '',
    text: '',
  })

  const [wipFormData, setWipFormData] = useState<CreateWipRequestInterface>({
    createdby: '',
    updatedby: '',
    t_WIP: {
      wipid: 0,
      faid: '',
      repcode: '',
      noofprods: 0,
      advisor: '',
      applicationnickname: '',
      iraaccountownertypeid: 0,
      lobid: 0,
      wipstatusid: 0,
    },
    t_WIP_Account: [
      {
        wipacctid: 0,
        wipid: 0,
        accounttypeid: 0,
        subaccounttypeid: 0,
        accttitle: '',
        contact: '',
        t_Party_Role: [
          {
            partyid: 0,
            firstname: '',
            lastname: '',
            relationshipTypeId: 0,
            issubmitted: true,
            partydetails: '',
            benedesignation: '',
            benepercentage: 0,
            relationshipToPrimaryOwner: '',
          },
        ],
      },
    ],
    appId: '1',
  })

  // ({
  //   createdby: '',
  //   updatedby: '',
  //   t_WIP: {
  //     faid:'',
  //     repcode: '',
  //     noofprods: 0,
  //     advisor: '',
  //     applicationnickname: '',
  //     lobid: '',
  //     iraaccountownertypeid: '',
  //   },
  //   t_WIP_Account: [
  //     {
  //       accounttype: 0,
  //       accttitle: '',
  //       subaccounttype: 0,
  //     },
  //   ],
  //   appId: 1,
  // })
  const [readOnly, setReadOnly] = useState(false)
  const [exportPdf, setExportPdf] = useState(false)
  const [counter, setCounter] = useState(false)
  const [requiredDocs, setRequiredDocs] = useState<RequiredDocs[]>([])
  const [getWipResponse, setWipResponse] = useState({} as WipResponseModel)
  const [selectedAccount, setSelectedAccount] = useState<SubProductModel>({
    label: '',
    accountTypeId: '',
    value: '',
  })
  const [newModelData, setNewModelData] = useState({} as NewWipResponseModel)
  const [bestOfInterest, setBestOfInterest] = useState({} as BestOfInterestType)
  const [selectedSubAccount, setSelectedSubAccount] = useState<SubProductModel>(
    {
      label: '',
      accountTypeId: '',
      value: '',
    }
  )
  const [trustedContact, setTrustedContact] = useState<number>(0)
  const [applicationResponse, setApplicationResponse] = useState<string>('')
  const [createWipFlag, setCreateWipFlag] = useState<boolean>(false)
  const [toggleErrorToast, setToggleErrorToast] = useState<boolean>(false)
  const [isDashboardNavigate, setIsDashboardNavigate] = useState<boolean>(true)
  const [allClientData, setAllClientData] = useState<any>()

  const [progressVerify, setProgressVerify] = useState<any>([
    {
      stageName: 'account info',
      validateCards: [
        {
          product_feature: false,
        },
        {
          contact: false,
        },
        {
          investment: false,
        },
        {
          electronic: false,
        },
        {
          funding: false,
        },
        {
          trustedContact: false,
        },
      ],
    },
    {
      stageName: 'documnets',
      validateCards: [
        {
          docs: false,
        },
      ],
    },
    {
      stageName: 'reviewscr',
      validateCards: [
        {
          review: false,
        },
      ],
    },
  ])
  const [stageNames, setStageNames] = useState<StageNameInterface[]>([
    {
      name: 'Account Information',
      stage: 0,
    },
    {
      name: 'Client Documents',
      stage: 1,
    },
    {
      name: 'Review',
      stage: 2,
    },
    {
      name: 'Client Review',
      stage: 3,
    },
    {
      name: 'Final Review',
      stage: 4,
    },
  ])
  const [stateList, setStateList] = useState([])
  const getIndividualPartyData = async (
    payload: ClientByIdPayloadInterface
  ) => {
    try {
      const response = await getClientDetailsDataAPI(payload)
      // setIndividualPartyData(response.data)
      return response.data
    } catch (error) {
      // setIndividualPartyData(IndividualClientDetails)
      return null
    }
  }

  const getClientAddress = async (payload: ClientIdPayloadInterface) => {
    try {
      const response = await getClientAddressAPI(payload)
      setClientAddress(response.data)
      return response.data
    } catch (error) {
      setClientAddress(mockAddressData)
      return mockAddressData
    }
  }

  const updateAppStatus = async (payload: UpdateAppStatusRequestInterface) => {
    try {
      const response = await updateAppStatusAPI(payload)
      setUpdateWIPResponse(response.data)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
  const addDisclosureDashboard = async (payload: AddDisclosureDashboard) => {
    try {
      const response = await addDisclosureDashboardAPI(payload)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
  const updateCommentSection = async (payload: UpdateCommentSection) => {
    try {
      const response = await updateCommentAPI(payload)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  const createAccountRequest = async (
    payload: CreateAccountRequestInterface
  ) => {
    try {
      const response = await createAccountRequestAPI(payload)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  const submitAccountApplication = async (payload: number) => {
    try {
      const response = await submitApplicationAPI(payload)
      return response.data
    } catch (error) {
      return undefined
    }
  }
  const GetLOBSubAcctType = async (payload: IGetSubAcctType) => {
    try {
      const response = await GetSubAcctType(payload)
      setLOBSubAcctType(response.data)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  const GetStateList = async () => {
    try {
      const response = await getStateist()
      setStateList(response.data)
      if (response.data?.length) {
        const states = response.data.map((item: any) => ({
          value: item.stateCode,
          label: item.stateName,
        }))
        setStateList(states)
      }
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  const getLOBAndEntityCodeDetails = async () => {
    try {
      const response = await getAllLOB()
      console.log(response.data)
    } catch (error) {
      return undefined
    }
  }

  const addLOBAndEntityCodeDetails = async (
    payload: AddLOBRequestInterface
  ) => {
    try {
      const response = await addLOBAPI(payload)
      console.log(response.data)
    } catch (error) {
      return undefined
    }
  }

  //Auto Save flow
  const { loggedUser } = useContext(AuthContext)

  const { setToast } = useContext(ToastContext)

  const { createNewAccountFeature, setProductFeatures, productFeatures } =
    useContext<IAccountFeatureStore>(AccountFeatureContext)

  //save & update product features
  const [detectChangeProduct, setDetectChangeProduct] = useState(false)
  const [detectChangeContact, setDetectChangeContact] = useState(false)
  const [detectChangeFund, setDetectChangeFund] = useState(false)
  const [detectChangeEp, setDetectChangeEp] = useState(false)
  const [detectChangeIp, setDetectChangeIp] = useState(false)
  const [detectChangeTc, setDetectChangeTc] = useState(false)
  const [sectionConfig, setSectionConfig] = useState<Section[]>([])
  const [accountType, setAccountType] = useState<OptionProps>({
    label: 'Select',
    value: '--',
  })
  const [questionValue, setQuestionValue] =
    useState<DynamicFormResponseInterface>({} as DynamicFormResponseInterface)
  const [resNo, setResNo] = useState<string>('')
  const [loaderProductIcon, setLoaderProductIcon] = useState(false)

  const updateCardsSaved = (cardsStatus: string[]) => {
    const value = { unsavedCards: cardsStatus }
    const payload: UpdateCardsRequestInterface = {
      wipid: parseInt(wipId),
      value: JSON.stringify(value),
      updatedby: loggedUser,
    }
    updateCardsStatus(payload)
      .then(() => {
        setLoader(false)
      })
      .catch(() => {
        setLoader(false)
        setToast('error', 'Error in updating status')
      })
  }
  const areFormErrorsEmpty = (data: any) => {
    return Object.values(data).every((error) => error === '')
  }
  const [buttonTextContact, setButtonTextContact] = useState('save')
  const [mailingAddress, setMailingAddress] = useState<OptionProps>({
    label: 'Select',
    value: '--',
    selectedValue: 'Select',
    selectedValueFormate: '',
  })
  const [legalAddress, setLegalAddress] = useState<OptionProps>({
    label: 'Select',
    value: '--',
    selectedValue: 'Select',
    selectedValueFormate: '',
  })
  const [mobilePhone, setMobilePhone] = useState<OptionProps>({
    label: 'Select',
    value: '--',
  })
  const [otherPhone, setOtherPhone] = useState<OptionProps>({
    label: 'Select',
    value: '--',
  })
  const [businessPhone, setBusinessPhone] = useState<OptionProps>({
    label: 'Select',
    value: 'Select',
  })
  const [homePhone, setHomePhone] = useState<OptionProps>({
    label: 'Select',
    value: 'Select',
  })
  const [fax, setFax] = useState<OptionProps>({
    label: 'Select',
    value: 'Select',
  })
  const [emailAddress, setEmailAddress] = useState<OptionProps>({
    label: 'Select',
    value: '--',
  })
  const [addAccountResponse, setAddAccountResponse] = useState(
    {} as AddContactResponseInterface
  )
  const tempAddPart1 = () => {
    return clientAddress
      ?.filter((addr) => addr.addressType === 'Legal Address')
      .map((address) => ({
        partyAddressLegal1: address?.streetAddress1,
        partyAddressLegal2: address?.streetAddress2,
        partyAddressLegalCity: address?.city,
        partyAddressLegalState: address?.stateCode,
        partyAddressLegalZip: address?.zipCode,
        partyAddressLegalCountry: address?.country,
      }))
  }

  const tempAddPart2 = () => {
    return clientAddress
      ?.filter((addr) => addr.addressType === 'MailingAddress')
      .map((address) => ({
        partyAddressMailing1: address?.streetAddress1,
        partyAddressMailing2: address?.streetAddress2,
        partyAddressMailingCity: address?.city,
        partyAddressMailingState: address?.stateCode,
        partyAddressMailingZip: address?.zipCode,
        partyAddressMailingCountry: address?.country,
      }))
  }

  const [electronicPrefs, setElectronicPrefs] = useState<ElectronicPreferences>(
    {
      monthlyStatements: 'Electronic Delivery',
      tradeAndOpenOrderConfirmations: 'Electronic Delivery',
      shareholderPostSaleProspectus: 'Electronic Delivery',
      shareholderProxyAndRelatedMaterial: 'Electronic Delivery',
    }
  )
  const [buttonTextEp, setButtonTextEp] = useState('save')

  // const onSaveUpdateElectronicPreference = () => {
  //   // const addElecPrefRequestDTO: ElectronicPreferencesRequestInterface = {
  //   //   wipid: Number(wipId),
  //   //   prospectelecdelivery:
  //   //     electronicPrefs.shareholderPostSaleProspectus === 'Electronic Delivery',
  //   //   proxyelecdelivery:
  //   //     electronicPrefs.shareholderProxyAndRelatedMaterial ===
  //   //     'Electronic Delivery',
  //   //   margintrading:
  //   //     electronicPrefs.shareholderPostSaleProspectus === 'Electronic Delivery',
  //   //   tradeconfirm:
  //   //     electronicPrefs.tradeAndOpenOrderConfirmations ===
  //   //     'Electronic Delivery',
  //   //   statement: electronicPrefs.monthlyStatements === 'Electronic Delivery',
  //   //   createdby: loggedUser,
  //   //   createdtimestamp: new Date(),
  //   //   updatedby: loggedUser,
  //   //   updatedtimestamp: new Date(),
  //   // }
  //   const payload: UpdateWIPRequestInterface = {
  //     updateWipAccountRequest: {
  //       wipacctid: newModelData.wipAccountDetailslist[0].wipacctid,
  //       partyid: newModelData.wipAccountDetailslist[0].parties[0].partyId,
  //       mailingaddrid: newModelData.wipAccountDetailslist[0].mailingaddrid,
  //       legaladdrid: newModelData.wipAccountDetailslist[0].legaladdrid,
  //       mobilephoneid: newModelData.wipAccountDetailslist[0].mobilephoneid,
  //       otherphonetypeid:
  //         newModelData.wipAccountDetailslist[0].otherphonetypeid,
  //       otherphoneid: newModelData.wipAccountDetailslist[0].otherphoneid,
  //       emailid: newModelData.wipAccountDetailslist[0].emailid,
  //       prospectelecdelivery:
  //         electronicPrefs.shareholderPostSaleProspectus ===
  //         'Electronic Delivery',
  //       proxyelecdelivery:
  //         electronicPrefs.shareholderProxyAndRelatedMaterial ===
  //         'Electronic Delivery',
  //       tradeconfirm:
  //         electronicPrefs.tradeAndOpenOrderConfirmations ===
  //         'Electronic Delivery',
  //       statement: electronicPrefs.monthlyStatements === 'Electronic Delivery',
  //       depositaccountnumber:
  //         newModelData.wipAccountDetailslist[0].depositaccountnumber,
  //       banknumber: newModelData.wipAccountDetailslist[0].banknumber,
  //       transferamount: newModelData.wipAccountDetailslist[0].transferamount,
  //       transfertypeid: newModelData.wipAccountDetailslist[0].transfertypeid,
  //       recurring: newModelData.wipAccountDetailslist[0].recurring,
  //       recurringfrequencyid:
  //         newModelData.wipAccountDetailslist[0].recurringfrequencyid,
  //       accountfeatures: newModelData.wipAccountDetailslist[0].accountfeatures,
  //       contactdetails: null,
  //       investmentobjectiveid: parseInt(investObjectiveValue?.value)
  //         ? parseInt(investObjectiveValue?.value)
  //         : newModelData.wipAccountDetailslist[0]?.investmentobjectiveid,
  //       risktoleranceid: parseInt(risktoleranceValue?.value)
  //         ? parseInt(risktoleranceValue?.value)
  //         : newModelData.wipAccountDetailslist[0]?.risktoleranceid,
  //       sourceoffunds: JSON.stringify(sourceOfFundValues)
  //         ? JSON.stringify(sourceOfFundValues)
  //         : newModelData.wipAccountDetailslist[0]?.sourceoffunds,
  //       investmenttimehorizonid: parseInt(timeHorizonValue?.value)
  //         ? parseInt(timeHorizonValue?.value)
  //         : newModelData.wipAccountDetailslist[0]?.investmenttimehorizonid,
  //       liquidityneedsid: parseInt(LiquidNeedsValue?.value)
  //         ? parseInt(LiquidNeedsValue?.value)
  //         : newModelData.wipAccountDetailslist[0]?.liquidityneedsid,
  //       liquidityneedspercentage:
  //         newModelData.wipAccountDetailslist[0]?.liquidityneedspercentage,
  //       sourceofcontributions: '',
  //       createdby: newModelData.wipAccountDetailslist[0].createdby,
  //       createdtimestamp:
  //         newModelData.wipAccountDetailslist[0].createdtimestamp,
  //       updatedby: loggedUser,
  //       updatedtimestamp: new Date().toISOString(),
  //     },
  //   }
  //   return new Promise<number>((resolve, reject) => {
  //     const apiCall = updateWIPAccount(payload)
  //       .then((data: any) => {
  //         setButtonTextEp('update')
  //         if (data.status == SuccessCode.SUCCESS) {
  //           setDetectChangeEp(false)
  //           setToast('success', 'Electronic Preferences saved')
  //           const CardStatusValue = cardsStatus.filter(
  //             (card) => card !== 'electronic'
  //           )
  //           setCardsStatus(CardStatusValue)
  //           updateCardsSaved(CardStatusValue)
  //           resolve(200)
  //         } else {
  //           reject(400)
  //         }
  //       })
  //       .catch(() => {
  //         reject(400)
  //       })
  //     return apiCall
  //   })
  // }

  // const onUpdateElectronicPreference = () => {
  //   const addElecPrefRequestDTO: any = {
  //     wipid: wipId,
  //     prospectelecdelivery:
  //       electronicPrefs.shareholderPostSaleProspectus === 'Electronic Delivery',
  //     proxyelecdelivery:
  //       electronicPrefs.shareholderProxyAndRelatedMaterial ===
  //       'Electronic Delivery',
  //     margintrading: false,
  //     tradeconfirm:
  //       electronicPrefs.tradeAndOpenOrderConfirmations ===
  //       'Electronic Delivery',
  //     statement: electronicPrefs.monthlyStatements === 'Electronic Delivery',
  //     createdby: loggedUser,
  //     createdtimestamp: new Date(),
  //     updatedby: loggedUser,
  //     updatedtimestamp: new Date(),
  //   }
  //   return new Promise<number>((resolve, reject) => {
  //     getElectronicPreferenceContextDetails
  //       .UpdateElectronicPreference(addElecPrefRequestDTO)
  //       .then((data: any) => {
  //         if (data.status == SuccessCode.SUCCESS) {
  //           setToast('success', 'Electronic Preferences updated')
  //           setDetectChangeEp(false)
  //           setButtonTextEp('update')
  //           resolve(200)
  //         } else {
  //           reject(400)
  //         }
  //       })
  //       .catch(() => {
  //         reject(400)
  //       })
  //   })
  // }

  //save & update Funding
  const getFundingContextDetails = useContext(FormFundingContext)

  const [featureaddFunding, setfeatureaddFunding] = useState<addfundrequestdto>(
    {
      fundid: 0,
      wipacctid: 0,
      depositaccountnumber: '',
      banknumber: '',
      transferamount: 0.0,
      transfertype: '',
      recurring: '',
      recurringyn: '',
      // createdby: '',
      // createdtimestamp: null,
      // updatedby: '',
      // updatedtimestamp: null,
    }
  )

  const [banknumber, setbanknumber] = useState('')
  const [transferamount, settransferamount] = useState('')
  const [depositaccountnumber, setdepositaccountnumber] = useState('')

  const [formErrors, setFormErrors] = useState({
    depositaccountnumber: '',
    banknumber: '',
    transferamount: '',
    transfertype: '',
    recurring: '',
    recurringyn: '',
  })
  const [saveFunding, setSaveFunding] = useState(false)
  const Validate = (data?: boolean) => {
    let isValid = true
    const localError = {
      depositaccountnumber: '',
      banknumber: '',
      transferamount: '',
      transfertype: '',
      recurring: '',
      recurringyn: '',
    }
    if (featureaddFunding.transfertype != numericType.FOUR) {
      if (!transferamount || parseInt(transferamount) === 0) {
        localError.transferamount = FundingErrorMessage.transferamount
        isValid = false
      }
      if (
        (!depositaccountnumber || depositaccountnumber == '') &&
        (featureaddFunding.transfertype != numericType.THREE ||
          !featureaddFunding.transfertype)
      ) {
        localError.depositaccountnumber =
          FundingErrorMessage.depositaccountnumber
        isValid = false
      }
      if (
        (!banknumber || banknumber == '') &&
        (featureaddFunding.transfertype != numericType.THREE ||
          !featureaddFunding.transfertype)
      ) {
        localError.banknumber = FundingErrorMessage.banknumber
        isValid = false
      }
      if (
        !featureaddFunding.transfertype ||
        featureaddFunding.transfertype == '' ||
        featureaddFunding.transfertype == numericType.NEGONE ||
        featureaddFunding.transfertype == '0'
      ) {
        localError.transfertype = FundingErrorMessage.transfertype
        isValid = false
      }

      // if (featureaddFunding.transfertype == numericType.ZEROTWO) {
      //   if (
      //     !featureaddFunding.recurring ||
      //     featureaddFunding.recurring == '' ||
      //     featureaddFunding.recurring == numericType.NEGONE ||
      //     featureaddFunding.recurring == '0'
      //   ) {
      //     localError.recurring = FundingErrorMessage.recurring
      //     isValid = false
      //   }
      // }

      if (!featureaddFunding.recurringyn?.toString()) {
        localError.recurringyn = FundingErrorMessage.recurringyn
        isValid = false
      }
      if (
        featureaddFunding.recurringyn == numericType.ONE &&
        !featureaddFunding.recurring
      ) {
        localError.recurring = FundingErrorMessage.recurring
        isValid = false
      }
    }
    // if (transferamount !== '') {
    //   if (!RegularExpression.isTwodigitNumafterDecimal.test(transferamount)) {
    //     localError.transferamount = FundingErrorMessage.isTwoNumafterDecimal
    //     isValid = false
    //   }
    // }
    setFormErrors(localError)

    if (data) {
      setTimeout(() => {
        setFormErrors({
          depositaccountnumber: '',
          banknumber: '',
          transferamount: '',
          transfertype: '',
          recurring: '',
          recurringyn: '',
        })
      }, 5000)
    }

    if (!isValid) {
      // setshowSavePopup(true)
      // showMessage(GeneralValidation, toastType.DANGER)
      return false
    } else {
      return true
    }
  }

  const [buttonTextFund, setButtonTextFund] = useState('Save')

  const getObjectiveText = (label: string) => {
    let returnText = ''
    if (label === 'Income') {
      returnText = InvProfileMsg.Income
    } else if (label === 'Trading') {
      returnText = InvProfileMsg.Trading
    } else if (label === 'Speculation') {
      returnText = InvProfileMsg.Speculation
    } else if (label === 'Growth') {
      returnText = InvProfileMsg.Growth
    }
    return returnText
  }

  const accountFeaturePayload = (type: string) => {
    const getSavedValues: any = Object.entries(questionValue).map(
      ([key, value]) => {
        const qsntext =
          key === 'bank_list'
            ? ''
            : sectionConfig[0].objQuestions.find(
              (obj) => obj.sectionqsnid === Number(key)
            )?.qsntext
        const domncode =
          key === 'bank_list'
            ? 'DEPOBANK'
            : sectionConfig[0].objQuestions.find(
              (obj) => obj.sectionqsnid === Number(key)
            )?.domaincode
        const getQsnId = sectionConfig[0].objQuestions.find(
          (item: any) => item.controlerseqno === 9
        )
        const selectedFDICId: any = getQsnId?.sectionqsnid
        const tempQuestVal: any = questionValue
        const selectedVal = selectedFDICId ? tempQuestVal[selectedFDICId] : null
        const selectedType = key === 'bank_list' ? { type: 'bank_list' } : value
        if (key === 'bank_list' && selectedVal && selectedVal.label === 'No') {
          return {
            sectionqsnid: undefined,
            domncode: null,
            qsntext: null,
            value: '',
            type: 'bank_list',
          }
        } else
          return {
            sectionqsnid: key,
            domncode: domncode ?? null,
            qsntext: qsntext ?? null,
            value,
            type: selectedType?.type ?? '',
          }
      }
    )
    switch (type) {
      case 'primaryAccNum': {
        return (
          getSavedValues.find((obj: any) => obj.type === 'primaryAccNum')?.value
            ?.label ?? ''
        )
      }
      case 'accountType': {
        return (
          getSavedValues.find((obj: any) => obj.type === 'accountType')?.value
            ?.label ?? ''
        )
      }
      case 'accountTypeId': {
        return (
          getSavedValues.find((obj: any) => obj.type === 'accountType')?.value
            ?.value ?? ''
        )
      }
      case 'creditPlusAccount': {
        return (
          getSavedValues.find((obj: any) => obj.type === 'creditPlusAccount')
            ?.value?.label ?? ''
        )
      }
      case 'creditPlusAccountId': {
        return (
          getSavedValues.find((obj: any) => obj.type === 'creditPlusAccount')
            ?.value?.value ?? ''
        )
      }
      case 'optionsAccount': {
        return (
          getSavedValues.find((obj: any) => obj.type === 'optionsAccount')
            ?.value?.label ?? ''
        )
      }
      case 'optionsAccountId': {
        return (
          getSavedValues.find((obj: any) => obj.type === 'optionsAccount')
            ?.value?.value ?? ''
        )
      }
      case 'jointType': {
        return (
          getSavedValues.find((obj: any) => obj.type === 'jointType')?.value
            ?.label ?? ''
        )
      }
      case 'jointTypeid': {
        return (
          getSavedValues.find((obj: any) => obj.type === 'jointType')?.value
            ?.value ?? ''
        )
      }
      case 'stategift': {
        return (
          getSavedValues.find((obj: any) => obj.type === 'stategift')?.value
            ?.label ?? ''
        )
      }
      case 'stategiftid': {
        return (
          getSavedValues.find((obj: any) => obj.type === 'stategift')?.value
            ?.value ?? ''
        )
      }
      case 'fdicSweep': {
        return (
          getSavedValues.find((obj: any) => obj.type === 'fdicSweep')?.value
            ?.label ?? ''
        )
      }
      case 'fdicSweepId': {
        return (
          getSavedValues.find((obj: any) => obj.type === 'fdicSweep')?.value
            ?.value ?? ''
        )
      }
      case 'nonObjBenefOwner': {
        return (
          getSavedValues.find((obj: any) => obj.type === 'nonObjBenefOwner')
            ?.value?.label ?? ''
        )
      }
      case 'requestCheckTitle': {
        return (
          getSavedValues.find((obj: any) => obj.type === 'requestCheckTitle')
            ?.value?.label ?? ''
        )
      }
      case 'reserveAcctNo': {
        return (
          getSavedValues.find((obj: any) => obj.type === 'reserveAcctNo')?.value
            ?.label ?? ''
        )
      }
      case 'Dividend&Interest': {
        return (
          getSavedValues.find((obj: any) => obj.type === 'Dividend&Interest')
            ?.value?.label ?? ''
        )
      }
      case 'Dividend&InterestId': {
        return (
          getSavedValues.find((obj: any) => obj.type === 'Dividend&Interest')
            ?.value?.value ?? ''
        )
      }
      case 'houseHold': {
        return (
          getSavedValues.find((obj: any) => obj.type === 'houseHold')?.value
            ?.label ?? ''
        )
      }
      case 'houseHoldId': {
        return (
          getSavedValues.find((obj: any) => obj.type === 'houseHold')?.value
            ?.value ?? ''
        )
      }
      case 'bank_list': {
        return getSavedValues.find((obj: any) => obj.type === 'bank_list')
          ?.value
          ? getSavedValues
            .find((obj: any) => obj.type === 'bank_list')
            ?.value.filter((obj: any) => obj.ischecked)
            .map((obj: any) => obj.label)
          : []
      }
      case 'irs_control': {
        return sectionConfig[0]?.objQuestions?.find(
          (obj) => obj.controlid === 'irsControl'
        )
          ? sectionConfig[0]?.objQuestions?.find(
            (obj) => obj.controlid === 'irsControl'
          )?.defaultvalue
          : ''
      }
      case 'w9code': {
        return sectionConfig[0]?.objQuestions?.find(
          (obj) => obj.controlid === 'w9code'
        )
          ? sectionConfig[0]?.objQuestions?.find(
            (obj) => obj.controlid === 'w9code'
          )?.defaultvalue
          : ''
      }
      case 'accountName':
      case 'shortName': {
        return (
          (individualPartyData?.firstName
            ? individualPartyData?.firstName
            : '') +
          ' ' +
          (individualPartyData?.middleName
            ? individualPartyData?.middleName
            : '') +
          ' ' +
          (individualPartyData?.lastName ? individualPartyData?.lastName : '')
        )
      }
      default: {
        return ''
      }
    }
  }

  // Update wip & auto save function
  const autoSaveFunction = () => {
    setTabLoader(true)
    const finalValuesObj = {
      primaryAccNum: accountFeaturePayload('primaryAccNum'),
      accountType: accountFeaturePayload('accountType'),
      accountTypeId: accountFeaturePayload('accountTypeId'),
      creditPlusAccount: accountFeaturePayload('creditPlusAccount'),
      creditPlusAccountId: accountFeaturePayload('creditPlusAccountId'),
      optionsAccount: accountFeaturePayload('optionsAccount'),
      optionsAccountId: accountFeaturePayload('optionsAccountId'),
      fdicSweep: accountFeaturePayload('fdicSweep'),
      fdicSweepid: accountFeaturePayload('fdicSweepId'),
      nonObjBenefOwner: accountFeaturePayload('nonObjBenefOwner'),
      irsControl: accountFeaturePayload('irs_control'),
      accountName: accountFeaturePayload('accountName'),
      shortName: accountFeaturePayload('shortName'),
      requestCheckTitle: accountFeaturePayload('requestCheckTitle'),
      reserveAcctNo: accountFeaturePayload('reserveAcctNo'),
      w9code: accountFeaturePayload('w9code'),
      'Dividend&Interest': accountFeaturePayload('Dividend&Interest'),
      'Dividend&InterestId': accountFeaturePayload('Dividend&InterestId'),
      houseHold: accountFeaturePayload('houseHold'),
      houseHoldId: accountFeaturePayload('houseHoldId'),
      banklist: accountFeaturePayload('bank_list'),
      jointType: accountFeaturePayload('jointType'),
      jointTypeid: accountFeaturePayload('jointTypeid'),
      stategift: accountFeaturePayload('stategift'),
      stategiftid: accountFeaturePayload('stategiftid'),
    }
    let fundingValidate
    if (detectChangeFund) {
      fundingValidate = Validate(true)
    }

    const tempData: any = sourceData
    const filteredAmt = amtData?.filter(
      (item) => (item.year || item.amount) !== ''
    )
    if (tempData.id === '1') {
      tempData.amountdetails = filteredAmt
    } else {
      delete tempData.amountdetails
    }
    const retSourcePayload = tempData
    const getParsedContactDetails = JSON?.parse(
      newModelData?.wipAccountDetailslist?.[0]?.contactdetails
    )
    const contactDetailsPayload = {
      mailingaddrid: parseInt(mailingAddress.value)
        ? parseInt(mailingAddress.value)
        : getParsedContactDetails?.mailingaddrid
          ? getParsedContactDetails?.mailingaddrid
          : 0,
      MailingStreetAddress1: mailingAddress?.selectedValueFormate?.[0]
        ?.partyAddressMailing1
        ? mailingAddress?.selectedValueFormate?.[0]?.partyAddressMailing1
        : getParsedContactDetails?.MailingStreetAddress1
          ? getParsedContactDetails?.MailingStreetAddress1
          : '',
      MailingStreetAddress2: mailingAddress?.selectedValueFormate?.[0]
        ?.partyAddressMailing2
        ? mailingAddress?.selectedValueFormate?.[0]?.partyAddressMailing2
        : getParsedContactDetails?.MailingStreetAddress2
          ? getParsedContactDetails?.MailingStreetAddress2
          : '',
      MailingZipcode: mailingAddress?.selectedValueFormate?.[0]
        ?.partyAddressMailingZip
        ? mailingAddress?.selectedValueFormate?.[0]?.partyAddressMailingZip
        : getParsedContactDetails?.MailingZipcode
          ? getParsedContactDetails?.MailingZipcode
          : '',
      MailingCity: mailingAddress?.selectedValueFormate?.[0]
        ?.partyAddressMailingCity
        ? mailingAddress?.selectedValueFormate?.[0]?.partyAddressMailingCity
        : getParsedContactDetails?.MailingCity
          ? getParsedContactDetails?.MailingCity
          : '',
      MailingState: mailingAddress?.selectedValueFormate?.[0]
        ?.partyAddressMailingState
        ? mailingAddress?.selectedValueFormate?.[0]?.partyAddressMailingState
        : getParsedContactDetails?.MailingState
          ? getParsedContactDetails?.MailingState
          : '',
      MailingStatecode: mailingAddress?.selectedValueFormate?.[0]
        ?.partyAddressMailingStateCode
        ? mailingAddress?.selectedValueFormate?.[0]
          ?.partyAddressMailingStateCode
        : getParsedContactDetails?.MailingStatecode
          ? getParsedContactDetails?.MailingStatecode
          : '',
      MailingCountry: mailingAddress?.selectedValueFormate?.[0]
        ?.partyAddressMailingCountry
        ? mailingAddress?.selectedValueFormate?.[0]?.partyAddressMailingCountry
        : getParsedContactDetails?.MailingCountry
          ? getParsedContactDetails?.MailingCountry
          : '',
      MailingCountryCode: mailingAddress?.selectedValueFormate?.[0]
        ?.partyAddressMailingCountryCode
        ? mailingAddress?.selectedValueFormate?.[0]
          ?.partyAddressMailingCountryCode
        : getParsedContactDetails?.MailingCountryCode
          ? getParsedContactDetails?.MailingCountryCode
          : '',
      legaladdrid: parseInt(legalAddress.value)
        ? parseInt(legalAddress.value)
        : getParsedContactDetails?.legaladdrid
          ? getParsedContactDetails?.legaladdrid
          : 0,
      LegalStreetAddress1: legalAddress?.selectedValueFormate?.[0]
        ?.partyAddressLegal1
        ? legalAddress?.selectedValueFormate?.[0]?.partyAddressLegal1
        : getParsedContactDetails?.LegalStreetAddress1
          ? getParsedContactDetails?.LegalStreetAddress1
          : '',
      LegalStreetAddress2: legalAddress?.selectedValueFormate?.[0]
        ?.partyAddressLegal2
        ? legalAddress?.selectedValueFormate?.[0]?.partyAddressLegal2
        : getParsedContactDetails?.LegalStreetAddress2
          ? getParsedContactDetails?.LegalStreetAddress2
          : '',
      LegalZipcode: legalAddress?.selectedValueFormate?.[0]
        ?.partyAddressLegalZip
        ? legalAddress?.selectedValueFormate?.[0]?.partyAddressLegalZip
        : getParsedContactDetails?.LegalZipcode
          ? getParsedContactDetails?.LegalZipcode
          : '',
      LegalCity: legalAddress?.selectedValueFormate?.[0]?.partyAddressLegalCity
        ? legalAddress?.selectedValueFormate?.[0]?.partyAddressLegalCity
        : getParsedContactDetails?.LegalCity
          ? getParsedContactDetails?.LegalCity
          : '',
      LegalState: legalAddress?.selectedValueFormate?.[0]
        ?.partyAddressLegalState
        ? legalAddress?.selectedValueFormate?.[0]?.partyAddressLegalState
        : getParsedContactDetails?.LegalState
          ? getParsedContactDetails?.LegalState
          : '',
      LegalStatecode: legalAddress?.selectedValueFormate?.[0]
        ?.partyAddressLegalStateCode
        ? legalAddress?.selectedValueFormate?.[0]?.partyAddressLegalStateCode
        : getParsedContactDetails?.LegalStatecode
          ? getParsedContactDetails?.LegalStatecode
          : '',
      LegalCountry: legalAddress?.selectedValueFormate?.[0]
        ?.partyAddressLegalCountry
        ? legalAddress?.selectedValueFormate?.[0]?.partyAddressLegalCountry
        : getParsedContactDetails?.LegalCountry
          ? getParsedContactDetails?.LegalCountry
          : '',
      LegalCountryCode: legalAddress?.selectedValueFormate?.[0]
        ?.partyAddressLegalCountryCode
        ? legalAddress?.selectedValueFormate?.[0]?.partyAddressLegalCountryCode
        : getParsedContactDetails?.LegalCountryCode
          ? getParsedContactDetails?.LegalCountryCode
          : '',
      mobilephoneid: parseInt(mobilePhone.value)
        ? parseInt(mobilePhone.value)
        : getParsedContactDetails?.mobilephoneid
          ? getParsedContactDetails?.mobilephoneid
          : 0,
      MobilePhone:
        mobilePhone.label !== 'Select' || !mobilePhone.label
          ? mobilePhone.label
          : getParsedContactDetails?.MobilePhone
            ? getParsedContactDetails?.MobilePhone
            : '',
      OtherPhone: otherPhone.label
        ? otherPhone.label === 'Business'
          ? businessPhone.label
          : otherPhone.label === 'Home'
            ? homePhone.label
            : fax.label
        : getParsedContactDetails?.OtherPhone
          ? getParsedContactDetails?.OtherPhone
          : '',
      otherphonetypeid: Number(otherPhone.value)
        ? Number(otherPhone.value)
        : getParsedContactDetails?.otherphonetypeid
          ? getParsedContactDetails?.otherphonetypeid
          : 0,
      otherphoneid: otherPhone.label
        ? otherPhone.label === 'Business'
          ? parseInt(businessPhone.value)
          : otherPhone.label === 'Home'
            ? parseInt(homePhone.value)
            : parseInt(fax.value)
        : getParsedContactDetails?.otherphoneid
          ? getParsedContactDetails?.otherphoneid
          : 0,
      emailid: parseInt(emailAddress.value)
        ? parseInt(emailAddress.value)
        : getParsedContactDetails?.emailid
          ? getParsedContactDetails?.emailid
          : 0,
      EmailAddress:
        emailAddress.label !== 'Select' || !emailAddress.label
          ? emailAddress.label
          : getParsedContactDetails?.EmailAddress
            ? getParsedContactDetails?.EmailAddress
            : '',
      EmailAddressTypeId: parseInt(emailAddress.value)
        ? parseInt(emailAddress.value)
        : getParsedContactDetails?.EmailAddressTypeId
          ? getParsedContactDetails?.EmailAddressTypeId
          : 0,
    }
    const industryCardPayload = {
      isprimempwthwedbush: assEmpDropDown.value ?? '',
      relation: indusAffrelation ?? '',
      isprimempwthregwedbush: regBrokerDropDown.value ?? '',
      entityname: entity ?? '',
      symbol: companyDetails.map(({ symbol }) => symbol) ?? '',
      isshareholder: ownedCompDropDown.value ?? '',
      companyname: companyDetails.map(({ company }) => company) ?? '',
    }
    const payload: UpdateWIPRequestInterface = {
      updateWipAccountRequest: {
        wipacctid: newModelData.wipAccountDetailslist[0].wipacctid,
        partyid: newModelData.wipAccountDetailslist[0].parties[0].partyId,
        // mailingaddrid: parseInt(mailingAddress.value)
        //   ? parseInt(mailingAddress.value)
        //   : newModelData.wipAccountDetailslist[0].mailingaddrid,
        // legaladdrid: parseInt(legalAddress.value)
        //   ? parseInt(legalAddress.value)
        //   : newModelData.wipAccountDetailslist[0].legaladdrid,
        // mobilephoneid: parseInt(mobilePhone.value)
        //   ? parseInt(mobilePhone.value)
        //   : newModelData.wipAccountDetailslist[0].mobilephoneid,
        // otherphonetypeid: Number(otherPhone.value)
        //   ? Number(otherPhone.value)
        //   : newModelData.wipAccountDetailslist[0].otherphonetypeid,
        // otherphoneid: otherPhone.label
        //   ? otherPhone.label === 'Business'
        //     ? parseInt(businessPhone.value)
        //     : otherPhone.label === 'Home'
        //     ? parseInt(homePhone.value)
        //     : parseInt(fax.value)
        //   : newModelData.wipAccountDetailslist[0].otherphoneid,
        mailingaddrid: 0,
        legaladdrid: 0,
        mobilephoneid: 0,
        otherphonetypeid: 0,
        otherphoneid: 0,
        emailid: 0,
        // emailid: parseInt(emailAddress.value)
        //   ? parseInt(emailAddress.value)
        //   : newModelData.wipAccountDetailslist[0].emailid,
        contactdetails: JSON.stringify(contactDetailsPayload)
          ? JSON.stringify(contactDetailsPayload)
          : newModelData.wipAccountDetailslist[0].contactdetails,
        prospectelecdelivery:
          electronicPrefs.shareholderPostSaleProspectus ===
          'Electronic Delivery',
        proxyelecdelivery:
          electronicPrefs.shareholderProxyAndRelatedMaterial ===
          'Electronic Delivery',
        tradeconfirm:
          electronicPrefs.tradeAndOpenOrderConfirmations ===
          'Electronic Delivery',
        statement: electronicPrefs.monthlyStatements === 'Electronic Delivery',
        depositaccountnumber:
          featureaddFunding.transfertype == numericType.FOUR
            ? null
            : depositaccountnumber && fundingValidate
              ? depositaccountnumber
              : newModelData.wipAccountDetailslist[0].depositaccountnumber,
        banknumber:
          featureaddFunding.transfertype == numericType.FOUR
            ? null
            : banknumber && fundingValidate
              ? banknumber
              : newModelData.wipAccountDetailslist[0].banknumber,
        transferamount:
          featureaddFunding.transfertype == numericType.FOUR
            ? null
            : Number(transferamount) && fundingValidate
              ? Number(transferamount)
              : newModelData.wipAccountDetailslist[0].transferamount,
        transfertypeid:
          parseInt(featureaddFunding.transfertype) && fundingValidate
            ? parseInt(featureaddFunding.transfertype)
            : newModelData.wipAccountDetailslist[0].transfertypeid,
        recurring:
          featureaddFunding.transfertype == numericType.FOUR
            ? null
            : featureaddFunding.recurringyn && fundingValidate
              ? featureaddFunding.recurringyn === '1'
                ? true
                : featureaddFunding.recurringyn === '2' && fundingValidate
                  ? false
                  : null
              : newModelData.wipAccountDetailslist[0].recurring,
        recurringfrequencyid:
          featureaddFunding.transfertype == numericType.FOUR
            ? null
            : Number(featureaddFunding.recurring) && fundingValidate
              ? Number(featureaddFunding.recurring)
              : newModelData.wipAccountDetailslist[0].recurringfrequencyid,
        accountfeatures: JSON.stringify(finalValuesObj)
          ? JSON.stringify(finalValuesObj)
          : newModelData.wipAccountDetailslist[0].accountfeatures,
        investmentobjectiveid: parseInt(investObjectiveValue?.value)
          ? parseInt(investObjectiveValue?.value)
          : newModelData.wipAccountDetailslist[0]?.investmentobjectiveid,
        risktoleranceid: parseInt(risktoleranceValue?.value)
          ? parseInt(risktoleranceValue?.value)
          : newModelData.wipAccountDetailslist[0]?.risktoleranceid,
        sourceoffunds: JSON.stringify(sourceOfFundValues)
          ? JSON.stringify(sourceOfFundValues)
          : newModelData.wipAccountDetailslist[0]?.sourceoffunds,
        investmenttimehorizonid: parseInt(timeHorizonValue?.value)
          ? parseInt(timeHorizonValue?.value)
          : newModelData.wipAccountDetailslist[0]?.investmenttimehorizonid,
        liquidityneedsid: parseInt(LiquidNeedsValue?.value)
          ? parseInt(LiquidNeedsValue?.value)
          : newModelData.wipAccountDetailslist[0]?.liquidityneedsid,
        liquidityneedspercentage: parseInt(liquidityPercentage)
          ? parseInt(liquidityPercentage)
          : 0,
        sourceofcontributions: JSON.stringify(retSourcePayload)
          ? JSON.stringify(retSourcePayload)
          : newModelData.wipAccountDetailslist[0]?.sourceofcontributions,
        createdby: loggedUser,
        createdtimestamp: new Date().toISOString(),
        updatedby: loggedUser,
        updatedtimestamp: new Date().toISOString(),
        inheritedfirstname:
          newModelData?.wipAccountDetailslist[0]?.inheritedfirstname,
        inheritedlastname:
          newModelData?.wipAccountDetailslist[0]?.inheritedlastname,
        inheritedrelationshipid:
          newModelData?.wipAccountDetailslist[0]?.inheritedrelationshipid,
        deceaseddate: newModelData?.wipAccountDetailslist[0]?.deceaseddate,
        iraaccountnumber:
          newModelData?.wipAccountDetailslist[0]?.iraaccountnumber,
        iraaccounttypeid:
          newModelData?.wipAccountDetailslist[0]?.iraaccounttypeid,
        irafeescheduleid:
          newModelData?.wipAccountDetailslist[0]?.irafeescheduleid,
        bestofinterest: JSON.stringify(bestOfInterest),
        industryaffiliations: JSON.stringify(industryCardPayload),
      },
    }
    return new Promise((resolve, reject) => {
      const apiCall = updateWIPAccount(payload)
        .then((data: any) => {
          if (data.status == SuccessCode.SUCCESS) {
            setToast('success', 'Details saved')
            setTabLoader(false)
            if (data) {
              setButtonTextFund('update')
              setToast('success', 'Details saved')
              if (areFormErrorsEmpty(formErrors)) {
                let CardStatusValue = cardsStatus
                if (detectChangeFund && Validate()) {
                  CardStatusValue = cardsStatus.filter(
                    (card) => card !== 'funding'
                  )
                }
                if (detectChangeContact) {
                  CardStatusValue = cardsStatus.filter(
                    (card) => card !== 'contact'
                  )
                }
                if (detectChangeEp) {
                  CardStatusValue = cardsStatus.filter(
                    (card) => card !== 'electronic'
                  )
                }
                if (detectChangeIp) {
                  CardStatusValue = cardsStatus.filter(
                    (card) => card !== 'investment'
                  )
                }
                if (detectChangeProduct) {
                  CardStatusValue = cardsStatus.filter(
                    (card) => card !== 'productFeatures'
                  )
                }
                setCardsStatus(CardStatusValue)
                updateCardsSaved(CardStatusValue)
              }
              setDetectChangeProduct(false)
              setDetectChangeContact(false)
              setDetectChangeFund(false)
              setDetectChangeEp(false)
              setDetectChangeIp(false)
              resolve(200)
            }
          } else {
            reject(400)
            setTabLoader(false)
          }
        })
        .catch(() => {
          reject(400)
          setTabLoader(false)
        })
      return apiCall
    })
  }
  //savre & update from Trusted Contact Card

  const [relation, setRelation] = useState('')
  const [trustedParty, setTrustedParty] = useState(PartyData)

  const updateTrustedContact = () => {
    const existingTrustedData =
      newModelData.wipAccountDetailslist[0].parties.find(
        (party: any) => party.relationshipTypeId === 3
      )
    let party = {} as Party
    if (selectedCheck && existingTrustedData) {
      party = {
        partyId: existingTrustedData.partyId,
        name: existingTrustedData.partyname,
        percentageOfBenefits: 0,
        relationship: '',
      }
    } else {
      party = {
        partyId: trustedContact,
        name: trustedParty.firstName + ' ' + trustedParty.lastName,
        percentageOfBenefits: 0,
        relationship: '',
      }
    }
    const payload: UpdateTrustedContactInterface = {
      parties: [party],
      relationshipTypeId: 3,
      wipacctid: newModelData.wipAccountDetailslist[0].wipacctid,
      istrustedcontact: selectedCheck,
      trustedcontactrelationship: !selectedCheck ? relation : '',
      partydetails: null,
      createdBy: '',
      updateBy: '',
      status: '',
      issubmitted: true,
    }
    setCounter(true)
    return new Promise((resolve, reject) => {
      const apiCall = updateTrustedContactDetails(payload)
        .then((res) => {
          if (res.status == 200) {
            resolve(200)
            setAPIPreviewStatus({
              status: 'success',
              text: 'Details updated successfully',
            })
            const payload: GetWipRequestInterface = {
              appId: '1',
              wipId: parseInt(wipId),
            }
            getWipDetails(payload)
              .then((res: any) => {
                const getTrustData =
                  res.data.wipAccountDetailslist[0].parties.find(
                    (party: any) => party.relationshipTypeId === 3
                  )

                if (Object.keys(getTrustData).length >= 0) {
                  setSelectedCheck(false)
                  const obj: PartyDetailsProps = JSON.parse(
                    getTrustData.partydetails
                  )
                  //
                  const partyDemoGraphic: PartyDemographicInterface =
                    obj.IndividualDetails.partyDemographic
                  const partyData: IndividualClientResponseInterface = {
                    firstName: obj.IndividualDetails.firstName,
                    lastName: obj.IndividualDetails.lastName,
                    partyId: getTrustData.partyId,
                    middleName: obj.IndividualDetails.middleName,
                    partyTypeId: 0,
                    prefix: obj.IndividualDetails.prefix,
                    suffix: obj.IndividualDetails.suffix,
                    fullName: obj.IndividualDetails.fullName,
                    salutation: '',
                    socialSecurityNumber:
                      obj.IndividualDetails.socialSecurityNumber,
                    updateBy: null,
                    partyDemographic: partyDemoGraphic,
                    clientIds: [],
                  }
                  setRelation(getTrustData.trustedcontactrelationship)
                  setTrustedParty(partyData)
                } else {
                  setSelectedCheck(true)
                }
                const CardStatusValue = cardsStatus.filter(
                  (card) => card !== 'trusted'
                )
                setCardsStatus(CardStatusValue)
                updateCardsSaved(CardStatusValue)
              })
              .catch((error) => {
                reject(500)
                console.log('error', error)
                setToast('error', 'Something went wrong..')
              })
            // progressVerify[0].validateCards[5] = {
            //   trustedContact: true,
            // }
            // setProgressVerify([...progressVerify])
            setDetectChangeTc(false)
          }
        })
        .catch(() => {
          setAPIPreviewStatus({
            status: 'error',
            text: 'Something went wrong, please try again',
          })
        })
      return apiCall
      // }
    })
  }
  let d = ''
  let relId = 0
  selectedAddiParticipant?.forEach((element: any) => {
    d = element.relationship
    if (d == 'Authorized Individual(Agent)') {
      relId = 6
    } else if (d == 'Individual W/Control Info') {
      relId = 8
    } else if (d == 'President/Vice-President') {
      relId = 12
    }
  })

  const deleteBeneficiary = (
    relTypeId?: number,
    partyid?: number,
    wipacctid?: number,
    relationshiptypeid?: number
  ) => {
    const payload: deleteBeneficiaryPayloadInterface = {
      partyid: partyid,
      wipacctid: wipacctid,
      relationshiptypeid: relTypeId === relId ? relId : relationshiptypeid,
    }

    return new Promise((resolve, reject) => {
      const apiCall = deleteBeneficiaryDetails(payload)
        .then(() => {
          const payload: GetWipRequestInterface = {
            appId: '1',
            wipId: parseInt(wipId),
          }
          getWipDetails(payload)
            .then((res: any) => {
              const primaryBenef: Beneficiary =
                res.data.wipAccountDetailslist[0].parties.filter(
                  (party: any) => party.relationshipTypeId === relTypeId
                )

              if (relTypeId === 4) {
                setSelectedPrimaryBeneficiary?.(primaryBenef)
              } else if (relTypeId === 5) {
                setSelectedContingentBeneficiary?.(primaryBenef)
              } else if (relTypeId === 7) {
                setSelectedBeneOwner?.(primaryBenef)
              } else if (relTypeId === relId) {
                setSelectedAddiParticipant?.(primaryBenef)
              }
            })
            .catch((error) => {
              reject(500)
              console.log('error', error)
              setToast('error', 'Something went wrong..')
            })

          setDetectChangeTc(false)
          resolve(200)
        })
        .catch(() => {
          reject(500)
        })
      return apiCall
    })
  }

  const updatePrimaryBeneficiary = (
    relTypeId?: number,
    beneficiary?: Beneficiary[]
  ) => {
    const payload: BeneficiaryPayloadInterface = {
      parties: beneficiary ?? [],
      relationshipTypeId: relTypeId ?? 0,
      wipacctid: newModelData.wipAccountDetailslist?.[0].wipacctid,
      istrustedcontact: false,
      trustedcontactrelationship: '',
      partydetails: null,
      createdBy: '',
      updateBy: '',
      status: '',
      issubmitted: true,
    }

    setCounter(true)
    return new Promise((resolve, reject) => {
      const apiCall = updateBeneficiaryDetails(payload)
        .then(() => {
          const payload: GetWipRequestInterface = {
            appId: '1',
            wipId: parseInt(wipId),
          }
          getWipDetails(payload)
            .then((res: any) => {
              const primaryBenef: Beneficiary[] =
                res.data.wipAccountDetailslist[0].parties.filter(
                  (party: any) => party.relationshipTypeId === relTypeId
                )

              if (relTypeId === 4) {
                setSelectedPrimaryBeneficiary?.(primaryBenef)
              } else if (relTypeId === 5) {
                setSelectedContingentBeneficiary?.(primaryBenef)
              } else if (relTypeId === 7) {
                const tempBeneArr = primaryBenef?.map((item: any) => {
                  return { ...item, percentageOfBenefits: item.benepercentage }
                })
                setSelectedBeneOwner?.(tempBeneArr)
              } else if (relTypeId === relId) {
                setSelectedAddiParticipant?.(primaryBenef)
              }
            })
            .catch((error) => {
              reject(500)
              console.log('error', error)
              setToast('error', 'Something went wrong..')
            })

          setDetectChangeTc(false)
          resolve(200)
        })
        .catch(() => {
          reject(500)
        })
      return apiCall
    })
  }

  //save all function
  const [activeStage, setActiveStage] = useState(0)
  const [stage, setStage] = useState(0)
  const [accountInfoLoader, setAccountInfoLoader] = useState(false)
  const saveAll = () => {
    setAccountInfoLoader(true)
    const array = []
    // if (detectChangeProduct) {
    //   array.push(onSaveUpdateProductFeature())
    // }
    // if (detectChangeEp) {
    //   array.push(onSaveUpdateElectronicPreference())
    // }
    // if (detectChangeFund) {
    //   array.push(onSaveUpdateFunding())
    // }
    // if (detectChangeContact) {
    //   array.push(onSaveUpdateContact())
    // }
    if (detectChangeTc) {
      array.push(updateTrustedContact())
    }
    // if (detectChangeIp) {
    //   array.push(onSaveInvestmentCard())
    // }
    if (
      detectChangeProduct ||
      detectChangeEp ||
      detectChangeFund ||
      detectChangeContact ||
      detectChangeIp
    ) {
      array.push(autoSaveFunction())
    }

    Promise.all(array)
      .then((response: any) => {
        setAccountInfoLoader(false)
        const payload: GetWipRequestInterface = {
          appId: '1',
          wipId: parseInt(wipId),
        }
        getWipDetails(payload)
          .then((getWipRecord) => {
            setNewModelData(getWipRecord.data)
            setActiveStage(stage + 1)
            setStage(stage + 1)
          })
          .catch((error) => {
            console.log('error', error)
          })
      })
      .catch((error) => {
        console.log('error', error)
        setAccountInfoLoader(false)
        setActiveStage(stage + 1)
        setStage(stage + 1)
        setToast('error', 'Failed to update the record.')
      })
  }

  const values = useMemo(
    () => ({
      wipId,
      wipAccountId,
      saveAll,
      xrefDetails,
      setXrefDetails,
      activeStage,
      buttonTextFund,
      setButtonTextFund,
      stage,
      setStage,
      setActiveStage,
      accountInfoLoader,
      setAccountInfoLoader,
      setWipId,
      setWipAccountId,
      setSelectedClient,
      selectedClient,
      getIndividualPartyData,
      individualPartyData,
      setIndividualPartyData,
      secondaryPartyData,
      setSecondaryPartyData,
      secondaryClient,
      setSecondaryClient,
      primaryClient,
      setPrimaryClient,
      invProfileDetails,
      setInvProfileDetails,
      wipFormData,
      setWipFormData,
      clientAddress,
      setClientAddress,
      getClientAddress,
      readOnly,
      setReadOnly,
      exportPdf,
      setExportPdf,
      requiredDocs,
      setRequiredDocs,
      getWipResponse,
      setWipResponse,
      bestOfInterest,
      setBestOfInterest,
      selectedAccount,
      setSelectedAccount,
      selectedSubAccount,
      setSelectedSubAccount,
      updateAppStatus,
      addDisclosureDashboard,
      updateCommentSection,
      createAccountRequest,
      trustedContact,
      setTrustedContact,
      createWipFlag,
      setCreateWipFlag,
      applicationResponse,
      setApplicationResponse,
      submitAccountApplication,
      getLOBAndEntityCodeDetails,
      addLOBAndEntityCodeDetails,
      counter,
      setCounter,
      loader,
      setLoader,
      contactCardLoader,
      setContactCardLoader,
      LOBSubAcctType,
      setLOBSubAcctType,
      GetLOBSubAcctType,
      toggleErrorToast,
      setToggleErrorToast,
      accountTypes,
      setAccountTypes,
      isDashboardNavigate,
      setIsDashboardNavigate,
      allClientData,
      setAllClientData,
      progressVerify,
      setProgressVerify,
      stageNames,
      setStageNames,
      questionValue,
      setQuestionValue,
      setResNo,
      resNo,
      loaderProductIcon,
      setLoaderProductIcon,
      buttonTextContact,
      setButtonTextContact,
      mailingAddress,
      setMailingAddress,
      legalAddress,
      setLegalAddress,
      mobilePhone,
      setMobilePhone,
      otherPhone,
      setOtherPhone,
      businessPhone,
      setBusinessPhone,
      addAccountResponse,
      setAddAccountResponse,
      homePhone,
      setHomePhone,
      fax,
      setFax,
      emailAddress,
      setEmailAddress,
      electronicPrefs,
      setElectronicPrefs,
      featureaddFunding,
      setfeatureaddFunding,
      risktoleranceValue,
      setRisktoleranceValue,
      LiquidNeedsValue,
      setLiquidityNeedsValue,
      sourceOfFundValues,
      setSourceOfFundValues,
      investObjectiveValue,
      setInvestObjectiveValue,
      timeHorizonValue,
      setTimeHorizonValue,
      liquidityPercentage,
      setLiquidityPercentage,
      autoSaveFunction,
      banknumber,
      setbanknumber,
      transferamount,
      settransferamount,
      depositaccountnumber,
      setdepositaccountnumber,
      formErrors,
      setFormErrors,
      relation,
      setRelation,
      trustedParty,
      setTrustedParty,
      updateTrustedContact,
      deleteBeneficiary,
      updatePrimaryBeneficiary,
      buttonTextEp,
      setButtonTextEp,
      detectChangeProduct,
      setDetectChangeProduct,
      detectChangeContact,
      setDetectChangeContact,
      detectChangeFund,
      setDetectChangeFund,
      detectChangeEp,
      setDetectChangeEp,
      detectChangeIp,
      setDetectChangeIp,
      detectChangeTc,
      setDetectChangeTc,
      selectedCheck,
      setSelectedCheck,
      reqFlag,
      setReqFlag,
      showError,
      setShowError,
      accountNumber,
      setAccountNumber,
      reserveError,
      setReserveError,
      contactError,
      setContactError,
      Validate,
      trustedError,
      setTrustedError,
      updateWIPResponse,
      setUpdateWIPResponse,
      productInfoFieldCheck,
      setProductInfoFieldCheck,
      formErrorProdFeature,
      setFormErrorProdFeature,
      sectionConfig,
      setSectionConfig,
      uploadedFiles,
      setUploadedFiles,
      primaryUploadedFiles,
      setPrimaryUploadedFiles,
      coApplicantUploadedFiles,
      setCoApplicantUploadedFiles,
      uploadError,
      setUploadError,
      stateList,
      setStateList,
      GetStateList,
      newModelData,
      setNewModelData,
      apiPreviewStatus,
      setAPIPreviewStatus,
      setSourceData,
      sourceData,
      setAmtData,
      amtData,
      relationList,
      setRelationList,
      retirementPersonalInfo,
      setRetirementPersonalInfo,
      accountType,
      setAccountType,
      secondaryInvProfileDetails,
      setSecondaryInvProfileDetails,
      primaryInvProfileDetails,
      setPrimaryInvProfileDetails,
      primaryGovtIds,
      setPrimaryGovtIds,
      secondaryGovtIds,
      setSecondaryGovtIds,
      tabLoader,
      setTabLoader,
      saveFlag,
      setSaveFlag,
      assEmpDropDown,
      setAssEmpDropDown,
      regBrokerDropDown,
      setRegBrokerDropDown,
      ownedCompDropDown,
      setOwnedCompDropDown,
      indusAffrelation,
      setIndusAffRelation,
      entity,
      setEntity,
      symbolCusip,
      setSymbolCusip,
      companyDetails,
      setCompanyDetails,
    }),
    [
      wipId,
      wipAccountId,
      xrefDetails,
      detectChangeProduct,
      detectChangeContact,
      detectChangeFund,
      detectChangeEp,
      detectChangeIp,
      detectChangeTc,
      buttonTextEp,
      formErrors,
      accountInfoLoader,
      buttonTextFund,
      stage,
      relation,
      trustedParty,
      selectedClient,
      activeStage,
      individualPartyData,
      secondaryPartyData,
      secondaryClient,
      primaryClient,
      wipFormData,
      clientAddress,
      readOnly,
      exportPdf,
      requiredDocs,
      getWipResponse,
      bestOfInterest,
      setBestOfInterest,
      selectedAccount,
      trustedContact,
      createWipFlag,
      applicationResponse,
      counter,
      loader,
      contactCardLoader,
      LOBSubAcctType,
      toggleErrorToast,
      accountTypes,
      isDashboardNavigate,
      allClientData,
      progressVerify,
      stageNames,
      questionValue,
      resNo,
      loaderProductIcon,
      buttonTextContact,
      mailingAddress,
      legalAddress,
      mobilePhone,
      otherPhone,
      businessPhone,
      addAccountResponse,
      homePhone,
      fax,
      emailAddress,
      electronicPrefs,
      featureaddFunding,
      risktoleranceValue,
      LiquidNeedsValue,
      sourceOfFundValues,
      investObjectiveValue,
      timeHorizonValue,
      liquidityPercentage,
      banknumber,
      transferamount,
      depositaccountnumber,
      selectedCheck,
      setSelectedCheck,
      reqFlag,
      setReqFlag,
      showError,
      setShowError,
      retirementPersonalInfo,
      contactError,
      setContactError,
      trustedError,
      setTrustedError,
      productInfoFieldCheck,
      setProductInfoFieldCheck,
      formErrorProdFeature,
      setFormErrorProdFeature,
      sectionConfig,
      setSectionConfig,
      uploadedFiles,
      setUploadedFiles,
      uploadError,
      primaryUploadedFiles,
      coApplicantUploadedFiles,
      selectedSubAccount,
      setSelectedSubAccount,
      stateList,
      newModelData,
      setNewModelData,
      setSourceData,
      sourceData,
      setAmtData,
      amtData,
      accountType,
      setAccountType,
      relationList,
      setRelationList,
      setRetirementPersonalInfo,
      tabLoader,
      setTabLoader,
      assEmpDropDown,
      setAssEmpDropDown,
      regBrokerDropDown,
      setRegBrokerDropDown,
      ownedCompDropDown,
      setOwnedCompDropDown,
      indusAffrelation,
      setIndusAffRelation,
      entity,
      setEntity,
      symbolCusip,
      setSymbolCusip,
      companyDetails,
      setCompanyDetails,
    ]
  )
  return (
    <ApplicationInfoContext.Provider value={values}>
      {children}
    </ApplicationInfoContext.Provider>
  )
}
