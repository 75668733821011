import React, { useContext, useEffect, useState } from 'react'
import { ApplicationInfoContext } from '../../../services/applicationContext/ApplicationPageContext'
import { ResponsiveWrapper } from '../../../pages/accountApplicationPage/styles'
import { StyledDocumentPanel } from '../accountInformation/styles'
import { TitleGrouping, WipHeadingWrapper } from '../formWip/styles'
import {
  SvgChevronDownMedium,
  SvgChevronUpMedium,
} from '../../../components/svg/SvgChevron'
import { COLORS } from '../../../theme/Colors'
import {
  CloseBtnWrapper,
  ColumnWrapper,
  DocumentRowContainer,
  FormContainer,
  FundWrapper,
  FundingWrapper,
  ImageWrapper,
  RowParentWrapper,
  TextboxWithButtonWrapper,
} from './styles'
import { DividerWrapper } from '../accountForms/formIndividual/tabs/ApplicantInfo/styles'
import Divider from '../../../components/divider/Divider'
import { SpinnerWrapper } from '../accountForms/formIndividual/tabs/ProductFeatures/style'
import { LottieWrapper } from '../../interceptor/styles'
import Lottie from 'lottie-react'
import { ToastWrapper } from '../../../components/toast/styles'
import { Toast } from '../../../components/toast/Toast'
import {
  WDLabel,
  WDLabelClient,
  WDLabelDetails,
  WDLabelStyledCardHeader,
} from '../../../components/ui/WDLabel'
import { SvgProductType } from '../../../components/svg/SvgHeaders'
import { Row } from '../accountForms/formIndividual/tabs/AccountFeature/styles'
import Label from '../../../components/label/Label'
import SelectDropdown from '../../selectDropdown/SelectDropdown'
import { Textbox } from '../../../components/extra/textbox/Textbox'
import {
  WDButtonOutLine,
  WDButtonTransparent,
} from '../../../components/ui/WDButton'
import Button from '../../../components/button/Button'
import SvgClose from '../../../components/svg/SvgClose'
import { SvgAdd } from '../../../components/svg/SvgAddIcon'
import { OptionProps } from '../../selectDropdown/ISelectDropDown'
import {
  FeatureInfoColumn,
  FeatureInfoRow,
} from '../accountForms/formIndividual/tabs/InvestmentInfo/styles'

const IndustryAffiliation = () => {
  const {
    readOnly,
    assEmpDropDown,
    setAssEmpDropDown,
    regBrokerDropDown,
    setRegBrokerDropDown,
    ownedCompDropDown,
    setOwnedCompDropDown,
    indusAffrelation,
    setIndusAffRelation,
    entity,
    setEntity,
    companyDetails,
    setCompanyDetails,
    newModelData,
  } = useContext(ApplicationInfoContext)

  const [toggleHeader, setToggleHeader] = useState(true)
  const [apiPreviewStatus, setAPIPreviewStatus] = useState<{
    status: string
    text: string
  }>({
    status: '',
    text: '',
  })
  const [assEmp, setAssEmp] = useState(false)
  const [assEmpCheck, setAssEmpCheck] = useState(false)
  const [regBroker, setRegBroker] = useState(false)
  const [regBrokerCheck, setRegBrokerCheck] = useState(false)
  const [ownedComp, setOwnedComp] = useState(false)
  const [ownedCompCheck, setOwnedCompCheck] = useState(false)
  const [options] = useState<OptionProps[]>([
    {
      label: 'Yes',
      value: 'Yes',
    },
    {
      label: 'No',
      value: 'No',
    },
  ])
  useEffect(() => {
    if (newModelData?.wipAccountDetailslist?.[0]) {
      const parseData = JSON.parse(
        newModelData?.wipAccountDetailslist?.[0]?.industryaffiliations
      )
      if (parseData) {
        setAssEmpDropDown(
          parseData?.isprimempwthwedbush
            ? {
                label: parseData?.isprimempwthwedbush,
                value: parseData?.isprimempwthwedbush,
              }
            : { label: '', value: '' }
        )
        setRegBrokerDropDown(
          parseData?.isprimempwthregwedbush
            ? {
                label: parseData?.isprimempwthregwedbush,
                value: parseData?.isprimempwthregwedbush,
              }
            : { label: '', value: '' }
        )
        setOwnedCompDropDown(
          parseData?.isshareholder
            ? {
                label: parseData?.isshareholder,
                value: parseData?.isshareholder,
              }
            : { label: '', value: '' }
        )
        if (parseData?.isprimempwthwedbush === 'Yes') {
          setAssEmp(true)
        } else {
          setAssEmp(false)
        }
        if (parseData?.isprimempwthregwedbush === 'Yes') {
          setRegBroker(true)
        } else {
          setRegBroker(false)
        }
        if (parseData?.isshareholder === 'Yes') {
          setOwnedComp(true)
        } else {
          setOwnedComp(false)
        }
        const combinedArray = parseData?.companyname?.map(
          (item: string, index: number) => {
            return { company: item, symbol: parseData?.symbol[index] }
          }
        )
        setCompanyDetails(combinedArray)
      }
    }
  }, [newModelData])

  const handle = (
    e: { value: string } | OptionProps | undefined,
    t: string
  ) => {
    switch (t) {
      case 'EMP':
        setAssEmpDropDown(e as OptionProps)
        if (e?.value === 'Yes') {
          setAssEmp(true)
          setAssEmpCheck(true)
        } else if (e?.value === 'No') {
          setAssEmp(false)
          setIndusAffRelation('')
          setAssEmpCheck(true)
        } else {
          setAssEmpCheck(false)
        }
        return
      case 'BROKER':
        setRegBrokerDropDown(e as OptionProps)
        if (e?.value === 'Yes') {
          setRegBroker(true)
          setRegBrokerCheck(true)
        } else if (e?.value === 'No') {
          setRegBroker(false)
          setEntity('')
          // setFormError({ ...formError })
          setRegBrokerCheck(true)
        } else {
          setRegBrokerCheck(false)
        }
        return
      case 'OWNED':
        setOwnedCompDropDown(e as OptionProps)
        if (e?.value === 'Yes') {
          setOwnedComp(true)
          setOwnedCompCheck(true)
        } else if (e?.value === 'No') {
          setOwnedComp(false)
          setCompanyDetails([{ company: '', symbol: '' }])
          setOwnedCompCheck(true)
          // setFormError({ ...formError })
        } else {
          setOwnedCompCheck(false)
        }
    }
  }
  const handleCompany = (e: string, index: number, name: string) => {
    const alphaNumeric = /^[a-zA-Z0-9\s]*$/
    if (name === 'symbol' && (!alphaNumeric.test(e) || e.length > 9)) return
    const newCompanies = [...companyDetails]
    newCompanies[index][name] = e
    setCompanyDetails(newCompanies)
  }

  const handleAddTextBox = () => {
    if (
      companyDetails.every(
        (item: any) => item.company !== '' && item.symbol !== ''
      )
    ) {
      setCompanyDetails([...companyDetails, { company: '', symbol: '' }])
    }
  }
  const handleDeleteSymbol = (index: number) => {
    const updatedSymbolCusip = [...companyDetails]
    updatedSymbolCusip.splice(index, 1)
    setCompanyDetails(updatedSymbolCusip)
  }
  return (
    <ResponsiveWrapper id="common">
      <StyledDocumentPanel>
        <WipHeadingWrapper>
          <TitleGrouping
            onClick={() => {
              setToggleHeader(!toggleHeader)
              // setIsEdit(false)
            }}
          >
            <SvgProductType />
            <WDLabelStyledCardHeader className="header_responsive">
              {'Industry Affiliation'}
            </WDLabelStyledCardHeader>
          </TitleGrouping>

          <ImageWrapper
            onClick={() => {
              setToggleHeader(!toggleHeader)
              // setIsEdit(false)
            }}
          >
            {!toggleHeader && (
              <SvgChevronDownMedium fillColor={`${COLORS.Text.Gray}`} />
            )}
            {toggleHeader && (
              <SvgChevronUpMedium fillColor={`${COLORS.Text.Gray}`} />
            )}
          </ImageWrapper>
        </WipHeadingWrapper>
        {toggleHeader && (
          <DividerWrapper id="product_feature">
            <Divider
              horizontal={true}
              bgColor={`${COLORS.Background.Neutral}`}
              thickness={'0.1rem'}
            />
          </DividerWrapper>
        )}
        {toggleHeader && (
          <FundWrapper>
            <FundingWrapper>
              <FormContainer id="FormContainer">
                <WDLabelDetails>
                  {
                    'Are you, your spouse, or any other immediate family members, including parents, in-laws, siblings and dependents:'
                  }
                </WDLabelDetails>
                <>
                  <DocumentRowContainer>
                    <FeatureInfoRow className={'fullWidth'}>
                      <FeatureInfoColumn>
                        <Row>
                          <WDLabel className="label_responsive">
                            <Label>
                              {
                                'Employed by or associated with Wedbush Securities?'
                              }
                            </Label>
                          </WDLabel>
                        </Row>
                      </FeatureInfoColumn>

                      <FeatureInfoColumn>
                        {readOnly ? (
                          <WDLabelClient className="label_responsive">
                            {assEmpDropDown.value ? assEmpDropDown.label : '--'}
                          </WDLabelClient>
                        ) : (
                          <SelectDropdown
                            width={'100%'}
                            options={options}
                            placeholder="please select"
                            name={'question1'}
                            onChange={(e) => handle(e, 'EMP')}
                            value={assEmpDropDown}
                          />
                        )}
                      </FeatureInfoColumn>
                    </FeatureInfoRow>
                    <FeatureInfoRow>
                      <FeatureInfoColumn>
                        <Row>
                          <WDLabel className="label_responsive">
                            <Label>
                              {'If yes, please specify the relation below'}
                            </Label>
                          </WDLabel>
                        </Row>
                      </FeatureInfoColumn>

                      <FeatureInfoColumn>
                        {readOnly ? (
                          <WDLabelClient className="label_responsive">
                            {indusAffrelation ? indusAffrelation : '--'}
                          </WDLabelClient>
                        ) : (
                          <Textbox
                            onChange={(e) => setIndusAffRelation(e)}
                            disabled={!assEmp}
                            value={indusAffrelation}
                          />
                        )}
                      </FeatureInfoColumn>
                    </FeatureInfoRow>
                  </DocumentRowContainer>
                  {/* </>
                    <> */}
                  <DocumentRowContainer>
                    <FeatureInfoRow className={'fullWidth'}>
                      <FeatureInfoColumn>
                        <Row>
                          <WDLabel className="label_responsive">
                            <Label>
                              {
                                'Employed by or associated with a registered broker/dealer (other than Wedbush) or a financial regulatory agency?'
                              }
                            </Label>
                          </WDLabel>
                        </Row>
                      </FeatureInfoColumn>

                      <FeatureInfoColumn>
                        {readOnly ? (
                          <WDLabelClient className="label_responsive">
                            {regBrokerDropDown.value}
                          </WDLabelClient>
                        ) : (
                          <SelectDropdown
                            width={'100%'}
                            options={options}
                            placeholder="Select"
                            name={'question2'}
                            onChange={(e) => handle(e, 'BROKER')}
                            value={regBrokerDropDown}
                          />
                        )}
                      </FeatureInfoColumn>
                    </FeatureInfoRow>
                    <FeatureInfoRow>
                      <FeatureInfoColumn>
                        <Row>
                          <WDLabel className="label_responsive">
                            <Label>
                              {'If yes, please specify the entity below'}
                            </Label>
                          </WDLabel>
                        </Row>
                      </FeatureInfoColumn>

                      <FeatureInfoColumn>
                        {readOnly ? (
                          <WDLabelClient className="label_responsive">
                            {entity ? entity : '--'}
                          </WDLabelClient>
                        ) : (
                          <Textbox
                            onChange={(e) => setEntity(e)}
                            value={entity}
                            disabled={!regBroker}
                          />
                        )}
                      </FeatureInfoColumn>
                    </FeatureInfoRow>
                  </DocumentRowContainer>
                  {/* </>
                    <> */}
                  <DocumentRowContainer>
                    <FeatureInfoRow id="halfWidth">
                      <FeatureInfoColumn>
                        <Row>
                          <WDLabel className="label_responsive">
                            <Label>
                              {
                                'An officer, director or 10% (or more) shareholder in a publicly owned company.'
                              }
                            </Label>
                          </WDLabel>
                        </Row>
                      </FeatureInfoColumn>

                      <FeatureInfoColumn>
                        {readOnly ? (
                          <WDLabelClient className="label_responsive">
                            {ownedCompDropDown.value}
                          </WDLabelClient>
                        ) : (
                          <SelectDropdown
                            width={'100%'}
                            options={options}
                            placeholder="Select"
                            name={'question3'}
                            onChange={(e) => handle(e, 'OWNED')}
                            value={ownedCompDropDown}
                          />
                        )}
                      </FeatureInfoColumn>
                    </FeatureInfoRow>
                  </DocumentRowContainer>
                  {ownedComp && (
                    <DocumentRowContainer>
                      <FeatureInfoRow id="halfWidth">
                        <FeatureInfoColumn>
                          <Row>
                            <WDLabel className="label_responsive">
                              <Label>
                                {'If yes, name of Company(ies) and Symbol(s).'}
                              </Label>
                            </WDLabel>
                          </Row>
                        </FeatureInfoColumn>
                        <FeatureInfoRow id="cusip-space">
                          {readOnly ? (
                            <ColumnWrapper>
                              {companyDetails.map((item: any, index: any) => {
                                return (
                                  <ColumnWrapper key={index}>
                                    <RowParentWrapper>
                                      <FeatureInfoRow id="halfWidth">
                                        <WDLabelClient key={index} className="label_responsive">
                                          <Label>{item.company ?? '--'}</Label>
                                        </WDLabelClient>
                                      </FeatureInfoRow>
                                      <FeatureInfoRow id="halfWidth">
                                        <WDLabelClient key={index} className="label_responsive">
                                          <Label>{item.symbol ?? '--'}</Label>
                                        </WDLabelClient>
                                      </FeatureInfoRow>
                                    </RowParentWrapper>
                                  </ColumnWrapper>
                                )
                              })}
                            </ColumnWrapper>
                          ) : (
                            <>
                              {companyDetails.map((item: any, index: any) => {
                                return (
                                  <ColumnWrapper key={index}>
                                    <TextboxWithButtonWrapper>
                                      <Textbox
                                        onChange={(e) =>
                                          handleCompany(e, index, 'company')
                                        }
                                        name="company"
                                        placeholder={`Company ${index + 1}`}
                                        disabled={!ownedComp}
                                        value={item.company}
                                      />
                                      <Textbox
                                        onChange={(e) =>
                                          handleCompany(e, index, 'symbol')
                                        }
                                        name="symbol"
                                        placeholder={`Symbol/CUSIP ${
                                          index + 1
                                        }`}
                                        disabled={!ownedComp}
                                        value={item.symbol}
                                      />
                                      {companyDetails.length > 0 && (
                                        <CloseBtnWrapper>
                                          <WDButtonOutLine>
                                            <Button
                                              type={'button'}
                                              onClick={() =>
                                                handleDeleteSymbol(index)
                                              }
                                              disabled={
                                                companyDetails.length === 1
                                                  ? true
                                                  : false
                                              }
                                            >
                                              <SvgClose
                                                fillColor={COLORS.Icons.Primary}
                                              />
                                            </Button>
                                          </WDButtonOutLine>
                                        </CloseBtnWrapper>
                                      )}
                                    </TextboxWithButtonWrapper>
                                    {!readOnly &&
                                      index === companyDetails.length - 1 && (
                                        <WDButtonTransparent>
                                          <Button
                                            type={'button'}
                                            onClick={handleAddTextBox}
                                            prefixedIcon={
                                              <SvgAdd
                                                fillColor={COLORS.Icons.Primary}
                                              />
                                            }
                                          >
                                            {'Add Symbol/Cusip'}
                                          </Button>
                                        </WDButtonTransparent>
                                      )}
                                  </ColumnWrapper>
                                )
                              })}
                            </>
                          )}
                        </FeatureInfoRow>
                      </FeatureInfoRow>
                    </DocumentRowContainer>
                  )}
                </>
                {apiPreviewStatus.status !== '' && (
                  <ToastWrapper>
                    <Toast
                      text={apiPreviewStatus.text}
                      type={apiPreviewStatus.status}
                      openStatusCallback={(status: boolean) =>
                        setAPIPreviewStatus({
                          status: '',
                          text: '',
                        })
                      }
                    />
                  </ToastWrapper>
                )}
              </FormContainer>
            </FundingWrapper>
          </FundWrapper>
        )}
      </StyledDocumentPanel>
    </ResponsiveWrapper>
  )
}
export default IndustryAffiliation
