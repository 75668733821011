import {
  ChevronWrapper,
  CollapseButtonWrapper,
  DocNameWrapper,
  DocWrapper,
  FileNameWrapper,
  FileViewWrapper,
  LottieButtonWrapper,
  MainWrapper,
  TitleDoc,
  UploadedFilesContainer,
  UploadedFilesWrapper,
} from './styles'
import { SpinnerButtonWrapper } from '../electronicPrefs/styles'
import Lottie from 'lottie-react'
import Loader from '../../../assets/lottiefiles/loader.json'
import {
  WDButtonGrayTransparent,
  WDButtonTransparent,
} from '../../../components/ui/WDButton'
import Button from '../../../components/button/Button'
import { WDLabelClient, WDLabelSmall } from '../../../components/ui/WDLabel'
import SvgClose from '../../../components/svg/SvgClose'
import { TextLabel } from '../../attachDocument/styles'
import { COLORS } from '../../../theme/Colors'
import SvgEye from '../../../components/svg/SvgEye'
import { GetDocumentsListResponseInterface } from '../../../shared/models/IFormDocuments'
interface UploadDocInterface {
  uploadedFiles: any
  isCoApplicant: boolean
  isDisclosure: boolean
  fileToDelete: any
  downloadDoc: (file: any) => void
  previewDocument: (file: any) => void
  handleDelUploadedDoc: (file: any) => void
  loader: boolean
  requiredDocsList?: GetDocumentsListResponseInterface[]
}

const UploadedFiles = ({
  uploadedFiles,
  isCoApplicant,
  isDisclosure,
  fileToDelete,
  downloadDoc,
  previewDocument,
  handleDelUploadedDoc,
  loader,
  requiredDocsList,
}: UploadDocInterface) => {
  return (
    <FileViewWrapper>
      {uploadedFiles &&
        uploadedFiles.map((file: any, index: number) => {
          const dateTime = file.createdtimestamp
          const dateObj = dateTime ? new Date(dateTime) : new Date()
          const formattedDate = dateObj.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })
          const formattedTime = dateObj.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          })
          const getUploadedDocName = requiredDocsList?.find(
            (obj) =>
              obj.relationshipid === file.relationshipid &&
              file.docid === obj.docid
          )
          if (fileToDelete?.wipdocreqid === file.wipdocreqid && loader) {
            return (
              <SpinnerButtonWrapper key={file.wipdocreqid}>
                <LottieButtonWrapper>
                  <Lottie animationData={Loader} loop={true} />
                </LottieButtonWrapper>
              </SpinnerButtonWrapper>
            )
          }
          return (
            <MainWrapper key={index}>
              <FileNameWrapper>
                <DocNameWrapper>
                  <DocWrapper>
                    <WDButtonTransparent>
                      <Button type={'button'} onClick={() => downloadDoc(file)}>
                        <WDLabelClient className="label_responsive">
                          {file.file.split('/').pop()}
                        </WDLabelClient>
                      </Button>
                    </WDButtonTransparent>
                  </DocWrapper>
                  <DocWrapper id="doc_type">
                    <WDLabelClient className="label_responsive">
                      {getUploadedDocName ? getUploadedDocName.docname : ''}
                    </WDLabelClient>
                  </DocWrapper>
                  <CollapseButtonWrapper>
                    <ChevronWrapper>
                      <WDButtonTransparent>
                        <Button
                          type="button"
                          onClick={() => {
                            previewDocument(file)
                          }}
                          prefixedIcon={
                            <SvgEye fillColor={COLORS.UI.Gray140} />
                          }
                        />
                      </WDButtonTransparent>
                      <WDButtonGrayTransparent>
                        <Button
                          type={'button'}
                          onClick={() => handleDelUploadedDoc(file)}
                        >
                          <SvgClose fillColor={COLORS.UI.Gray140} />
                        </Button>
                      </WDButtonGrayTransparent>
                    </ChevronWrapper>
                  </CollapseButtonWrapper>
                  <WDLabelSmall className="label_responsive">
                    <TextLabel>
                      {isDisclosure
                        ? `Uploaded by ${file.createdby} on ${formattedDate} at ${formattedTime}`
                        : `${file.createdby} on${' '}
                                                ${formattedDate} at ${' '}
                                                ${formattedTime}`}
                    </TextLabel>
                  </WDLabelSmall>
                </DocNameWrapper>
              </FileNameWrapper>
            </MainWrapper>
          )
        })}
    </FileViewWrapper>
  )
}
export default UploadedFiles
