import { useRowSelect, useTable } from 'react-table'
import {
  RootContainer,
  SelectDropdownCSS,
  TableBody,
  TableHead,
  TableHeaderCell,
  TableRow,
  TableWrapper,
  ThContentWrapper,
  ThWrapper,
} from './styles'
import { useMemo, useState } from 'react'
import { TableProps } from './ITable'
import SelectDropdown from '../extra/selectDropdown/SelectDropdown'
import { COLORS } from '../../theme/Colors'

const ApprovalTable = ({
  tableColumns,
  tableData,
  hasFooter = false,
  approveCheck,
  rejectionCheck,
}: TableProps) => {
  const data = useMemo(() => tableData, [tableData])
  const columns = useMemo(() => tableColumns, [tableColumns])
  const tableInstance = useTable({ columns, data }, useRowSelect)
  const [selectedValue, setSelectedValue] = useState<Array<string>>(
    Array(data.length).fill('submitted')
  )
  const options = [
    { label: 'Approved', value: 'approved' },
    { label: 'Rejected', value: 'rejected' },
  ]
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = tableInstance

  const renderColor = (value: string) => {
    switch (value) {
      case 'approved':
        return COLORS.Text.Success
      case 'rejected':
        return COLORS.Text.Danger
    }
  }

  const handleDropdownChange = (val: string, index: number, row: any): void => {
    const newSelectedValues = [...selectedValue]
    newSelectedValues[index] = val
    setSelectedValue(newSelectedValues)
    if (val === 'rejected' && rejectionCheck) {
      rejectionCheck(row.original.wipid)
    } else if (val == 'approved' && approveCheck) {
      approveCheck(row.original.wipid)
    }
  }
  const formatDate = (dateString: string | Date) => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const formattedDate = `${month}-${day}-${year}`
    return formattedDate
  }
  return (
    <RootContainer id="approvalTable">
      <TableWrapper {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup, index) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, i) => (
                <TableHeaderCell {...column.getHeaderProps()} key={i}>
                  <ThWrapper>
                    <ThContentWrapper className="label_responsive">
                      {column.render('Header')}
                    </ThContentWrapper>
                  </ThWrapper>
                </TableHeaderCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} key={index} className="table-row">
                {row.cells.map((cell, i) => {
                  if (i === 2) {
                    return (
                      <td key={i}>
                        <SelectDropdownCSS key={i}>
                          <SelectDropdown
                            options={options}
                            onChange={(val: string) =>
                              handleDropdownChange(val, index, row)
                            }
                            borderColor={COLORS.Border.White}
                            indicatorColor={COLORS.Text.Gray50}
                            placeholderColor={COLORS.Text.Gray50}
                            isMulti={false}
                            isSearchable={false}
                            singleValueColor={renderColor(selectedValue[index])}
                          />
                        </SelectDropdownCSS>
                      </td>
                    )
                  } else if (i === 3) {
                    return (
                      <td key={i}>
                        {cell?.value ? formatDate(cell?.value) : '-'}
                      </td>
                    )
                  } else {
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={i}
                        className="label_responsive"
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  }
                })}
              </tr>
            )
          })}
        </TableBody>
        {hasFooter ? (
          <tfoot>
            {footerGroups.map((group, index) => (
              <tr {...group.getFooterGroupProps()} key={index}>
                {group.headers.map((column, i) => (
                  <td {...column.getFooterProps()} key={i}>
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        ) : null}
      </TableWrapper>
    </RootContainer>
  )
}

export default ApprovalTable
