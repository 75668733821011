import React, { useContext, useEffect, useState } from 'react'
import Divider from '../../../components/divider/Divider'
import { Toast } from '../../../components/toast/Toast'
import { WDLabelError } from '../../../components/ui/WDLabel'
import { ResponsiveWrapper } from '../../../pages/accountApplicationPage/styles'
import { AuthContext } from '../../../services/AuthContext'
import { LobInfoContext } from '../../../services/LobOfficeCode/LobOfficeContext'
import { ApprovalContext } from '../../../services/approvalScreenContext/ApprovalContext'
import {
  GetApprovalRequest,
  GetCardsResponseInterface,
  UpdateCardsRequestInterface,
} from '../../../services/approvalScreenContext/IApproval'
import { IndividualClientResponseInterface } from '../../../services/clientAndAccountSearch/IClientAndAccountSearchContext'
import ToastContext from '../../../services/toast/ToastContext'
import { toastType } from '../../../shared/config/enums'
import {
  DelteFile,
  DocumentsResponseInterface,
  DownloadFile,
  GetDocumentsListResponseInterface,
} from '../../../shared/models/IFormDocuments'
import { getSessionItem } from '../../../shared/utils/utils'
import FormDocsContext from '../../../store/FormDocsContext'
import { COLORS } from '../../../theme/Colors'
import { AttachDocument } from '../../attachDocument/AttachDocument'
import { File, HintWrapper } from '../../attachDocument/styles'
import PreviewFile from '../../previewFile/PreviewFile'
import { Wrapper } from '../../previewFile/styles'
import { OptionProps } from '../../selectDropdown/ISelectDropDown'
import { ViewDocument } from '../../viewDocument/ViewDocument'
import { Container } from '../accountInformation/styles'
import DeleteConfirmationModal from '../deleteConfirmationModal/DeletePopUp'
import { TitleGrouping, ToastWrapper } from '../formWip/styles'
import ListofDocuments from './DocumentList'
import UploadedFiles from './UploadedFiles'
import {
  AcknowledgementParagraph,
  ContentWrapper,
  DocParentWrapper,
  DocumentContainer,
  FileUploadWrapper,
  FormDocsWrapper,
  JointWrapper,
  SecondParagraph,
  StyledDocumentPanel,
  TitleDoc,
  UploadedFilesContainer,
  UploadedFilesWrapper,
} from './styles'
import { ApplicationInfoContext } from '../../../services/applicationContext/ApplicationPageContext'
import { DisclosureContext } from '../../../services/disclosureContext/DisclosureContext'

export interface IFormDocument {
  isrequired: number
  docid: number
  accounttype: string
  docname: string
  iscoapplicant?: boolean
}
export interface IFormDoc {
  name: string
  docid: string
  iscoapplicant?: boolean
}
export interface IDisclosureForm {
  name: string
  discId: string
  partyid: number
}
export interface IApplicantType {
  name: string
  value: boolean
}
export interface IFormDocId {
  docid: number
}
const FormDocuments = (props: any) => {
  const { loggedUser } = useContext(AuthContext)
  const Curr_wipId = Number(getSessionItem('wipId'))
  const formDocsContext = useContext(FormDocsContext)
  const { lobOfcCode } = useContext(LobInfoContext)
  const {
    disableConditionDocuments,
  } = useContext(DisclosureContext)
  const {
    individualPartyData,
    requiredDocs,
    getWipResponse,
    newModelData,
    setStage,
    setActiveStage,
    wipId,
    uploadedFiles,
    setUploadedFiles,
    setIndividualPartyData,
    getIndividualPartyData,
    selectedClient,
    primaryUploadedFiles,
    setPrimaryUploadedFiles,
    coApplicantUploadedFiles,
    setCoApplicantUploadedFiles,
    setUploadError,
  } = useContext(ApplicationInfoContext)
  const { setToast } = useContext(ToastContext)
  const { cardsStatus, setCardsStatus, updateCardsStatus, getCardsStatus } =
    useContext(ApprovalContext)
  const getClientIdTypes = individualPartyData?.clientIds
    ?.filter((obj: { idType: string }) => obj.idType)
    .map((item: { idType: string }) => {
      if (item.idType?.toLowerCase() === 'passport nbr') {
        return (item.idType = 'Passport')
      }
      return item.idType
    })

  const [requiredDocsList, setRequiredDocsList] = useState<
    GetDocumentsListResponseInterface[]
  >([])
  const [validDocs, setValidDocs] = useState<
    GetDocumentsListResponseInterface[]
  >([])
  const [coApplicantDocs, setCoApplicantDocs] = useState<
    GetDocumentsListResponseInterface[]
  >([])
  const [dropdownDocs, setDropdownDocs] = useState<OptionProps[]>([])

  const [apiPreviewStatus, setAPIPreviewStatus] = useState<{
    status: string
    text: string
  }>({
    status: '',
    text: '',
  })
  const [loader, setLoader] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<IFormDoc[]>([])
  const [applicantType, setApplicantType] = useState<IApplicantType[]>([])
  const [showPopup, setShowPopup] = useState(false)
  const [fileToDelete, setFileToDelete] = useState<DownloadFile>()
  const [preview, setPreview] = useState(false)
  const [loaderList, setLoaderList] = useState(true)
  const [previewFile, setPreviewFile] = useState<DownloadFile>(
    {} as DownloadFile
  )
  const [spinner, setSpinner] = useState<boolean>(false)
  const { setDocumentsDetails } = useContext(FormDocsContext)
  const showMessage = (errorMessage: string, status: string) => {
    if (errorMessage != '') {
      setAPIPreviewStatus({
        status: status,
        text: errorMessage,
      })
    }
  }
  const [applicantOptions, setApplicantOptions] = useState<OptionProps[]>([
    { value: '1', label: 'Primary Applicant' },
    { value: '2', label: 'Co-Applicant' },
  ])

  const [selectedApplicant, setSelectedApplicant] = useState<string>('1')

  useEffect(() => {
    if (newModelData?.wipAccountDetailslist?.[0]?.parties?.length > 0) {
      const getDocumentOptions =
        newModelData?.wipAccountDetailslist?.[0]?.parties?.filter(
          (obj) => obj.relationshipTypeId <= 2
        )
      const applicantSelection = getDocumentOptions?.map((item) => {
        return {
          label: item.relationshipToPrimaryOwner ?? '',
          value: item.relationshipTypeId.toString() ?? '',
        }
      })
      setApplicantOptions(applicantSelection)
      if (applicantSelection.length > 0) {
        setSelectedApplicant(applicantSelection[0].value)
      }
    }
  }, [newModelData.wipAccountDetailslist])

  useEffect(() => {
    if (
      wipId &&
      newModelData &&
      newModelData?.wipAccountDetailslist &&
      newModelData.wipAccountDetailslist[0]?.parties[0]?.partyId
    ) {
      getIndividualPartyData({
        partyId:
          newModelData &&
          newModelData?.wipAccountDetailslist &&
          newModelData.wipAccountDetailslist[0]?.parties[0]?.partyId,
      }).then((data: IndividualClientResponseInterface) => {
        setIndividualPartyData(data)
      })
    }
  }, [wipId, newModelData?.wipAccountDetailslist?.[0]?.parties?.[0]?.partyId])

  const checkMandatoryDocs = () => {
    return requiredDocsList.every((doc) =>
      uploadedFiles.some((file) => file.docid === doc.docid)
    )
  }
  const getUploadedDocs = () => {
    //fetch name and container name for uploaded doc
    if (newModelData?.wipAccountDetailslist) {
      formDocsContext
        .GetDocDetails({
          wipid: Curr_wipId,
          lobid: newModelData?.wipDetails?.lobid,
          accttypeid:
            newModelData?.wipAccountDetailslist?.[0] &&
            newModelData?.wipAccountDetailslist?.[0]?.accounttypeid,
          acctsubtypeid:
            newModelData?.wipAccountDetailslist?.[0] &&
            newModelData?.wipAccountDetailslist?.[0]?.subaccounttypeid,
        })
        .then((res) => {
          const localUploadedFils: DownloadFile[] = []
          if (res.data && res.data != null && res.data.length > 0) {
            res.data.map((data: DocumentsResponseInterface) => {
              const getRelationMap =
                newModelData?.wipAccountDetailslist?.[0]?.parties?.find(
                  (obj) => obj.partyId === data?.partyid
                )
              const localUploadobj: DownloadFile = {
                file: data.path.trim(),
                containerName: data?.container?.trim(),
                docid: data.docid,
                wipdocreqid: data.wipdocreqid,
                createdtimestamp: data.createdtimestamp,
                createdby: data.createdby,
                isacknowledged: data.isacknowledged,
                iscoapplicant: data.iscoapplicant,
                isdisclosure: data.isdisclosure,
                relationshipid: getRelationMap?.relationshipTypeId ?? 1,
              }

              localUploadedFils.push(localUploadobj)
            })
            setUploadedFiles(
              localUploadedFils.filter((file) => !file.isdisclosure)
            )
            const primaryFiles = localUploadedFils.filter(
              (file) => !file.iscoapplicant
            )
            const secondaryFile = localUploadedFils.filter(
              (file) => file.iscoapplicant
            )
            setPrimaryUploadedFiles(primaryFiles)
            setCoApplicantUploadedFiles(secondaryFile)
            formDocsContext.setUploadedFiles(localUploadedFils)
            setDocumentsDetails(localUploadedFils)
            if (localUploadedFils.length === validDocs.length) {
              if (
                getWipResponse?.wipDTO?.appstatus &&
                getWipResponse?.wipDTO?.appstatus.includes('PA-EMAIL')
              ) {
                setStage(3)
                setActiveStage(3)
              }
            }
          } else {
            setUploadedFiles([])
            setPrimaryUploadedFiles([])
            setCoApplicantUploadedFiles([])
          }
        })
    }
  }

  // useEffect(() => {
  //   if (requiredDocsList && selectedApplicant) {
  //     const originListDropDown: OptionProps[] = []
  //     requiredDocsList
  //       .filter((obj) => obj.relationshipid === Number(selectedApplicant))
  //       .forEach((doc: any) => {
  //         originListDropDown.push({
  //           label: doc.docname,
  //           value: doc.docid.toString(),
  //         })
  //       })
  //     setDropdownDocs(originListDropDown)
  //   }
  // }, [selectedApplicant])

  const getRequiredDocs = () => {
    //FetchReqDocs
    if (newModelData?.wipDetails?.wipid) {
      formDocsContext
        .FetchReqDocs({
          wipid: Curr_wipId,
          lobid: newModelData?.wipDetails?.lobid,
          accttypeid:
            newModelData?.wipAccountDetailslist?.[0] &&
            newModelData?.wipAccountDetailslist?.[0]?.accounttypeid,
          acctsubtypeid:
            newModelData?.wipAccountDetailslist?.[0] &&
            newModelData?.wipAccountDetailslist?.[0]?.subaccounttypeid,
        })
        .then((res) => {
          setLoaderList(false)
          if (res.data && res.data != null && res.data.length > 0) {
            const docList = res.data.filter((obj: any) => !obj.isdisclosure)
            let requiredDocsList = docList.filter((doc: any) =>
              getClientIdTypes?.includes(doc.docname)
            )
            if (!requiredDocsList || requiredDocsList.length === 0) {
              requiredDocsList = docList
            }
            setRequiredDocsList(requiredDocsList)
            const primaryDocs = requiredDocsList.filter(
              (doc: any) => doc.relationshipid === Number(selectedApplicant)
            )
            const coApplicantDocs = requiredDocsList.filter(
              (doc: any) => doc.relationshipid === 2
            )
            const originListDropDown: OptionProps[] = []
            primaryDocs.forEach((doc: any) => {
              originListDropDown.push({
                label: doc.docname,
                value: doc.docid.toString(),
              })
            })
            setDropdownDocs(originListDropDown)
            if (newModelData?.wipAccountDetailslist?.[0]?.parties.length > 1) {
              if (primaryDocs.length > 0) {
                setValidDocs(primaryDocs)
                // } else {
                //   const allDocs = requiredDocsList.filter(
                //     (doc: any) => !doc.iscoapplicant
                //   )
                //   setValidDocs(allDocs)
              }
              if (coApplicantDocs.length > 0) {
                setCoApplicantDocs(coApplicantDocs)
                // } else {
                //   const allDocs = requiredDocsList.filter(
                //     (doc: any) => doc.iscoapplicant
                //   )
                //   setCoApplicantDocs(allDocs)
              }
            } else {
              if (primaryDocs.length > 0) {
                setValidDocs(primaryDocs)
                // } else {
                //   const allDocs = requiredDocsList.filter(
                //     (doc: any) => !doc.iscoapplicant
                //   )
                //   setValidDocs(allDocs)
              }
            }
          }
        })
    }
  }

  const [formErrors, setFormErrors] = useState({
    selctDoctypeError: '',
  })
  const blankErrors = () => {
    setFormErrors({
      selctDoctypeError: '',
    })
    setUploadError('')
  }

  const handleFileSelect = (files: File[]) => {
    blankErrors()

    // if (
    //   newModelData.wipAccountDetailslist[0].accttitle == 'Individual' &&
    //   uploadedFiles.length == 0
    // ) {
    //   setSelectedFiles(files)
    //   const updteDocids = files.map((file) => {
    //     return { name: file.name, docid: '', iscoapplicant: undefined }
    //   })

    //   setSelectedDocumentIds(updteDocids)
    // } else if (
    //   newModelData.wipAccountDetailslist[0].accttitle == 'joint' &&
    //   uploadedFiles.length <= 2
    // ) {
    const updatedFileList = [...selectedFiles, ...files]
    setSelectedFiles((prevFiles) => [...prevFiles, ...files])
    //Intialize the docids
    const updteDocids = updatedFileList.map((file) => {
      return { name: file.name, docid: '', iscoapplicant: undefined }
    })

    setSelectedDocumentIds(updteDocids)
    // } else{
    //   showMessage('You have already uploaded files', toastType.WARNING)
    // }
  }

  const handleDocumentIdChange = (updatedDocumentIds: IFormDoc[]) => {
    blankErrors()
    setSelectedDocumentIds(updatedDocumentIds)
  }
  const handleFileDelete = (fileToDelete: File | null | undefined) => {
    blankErrors()
    const newFiles = selectedFiles.filter((file) => file !== fileToDelete)

    const index = selectedDocumentIds.findIndex(
      (docDetails) => docDetails.name == fileToDelete?.name
    )
    if (index >= 0) {
      const updatedIds = [...selectedDocumentIds]
      updatedIds.splice(index, 1)
      setSelectedDocumentIds(updatedIds)
    }
    setSelectedFiles(newFiles)
  }

  const docNamesDropDown: OptionProps[] = []

  const validate = (index: number) => {
    if (selectedFiles.length == 0) {
      showMessage('There are no documents to upload.', toastType.WARNING)
      return false
    }
    let checkEmptyDocid = false
    if (!selectedDocumentIds?.[index].docid) {
      checkEmptyDocid = true
    }
    if (checkEmptyDocid) {
      setFormErrors({
        ...formErrors,
        selctDoctypeError: 'Please select document type for all the documents.',
      })
      return false
    }
    if (newModelData?.wipAccountDetailslist?.[0]?.parties.length > 1) {
      const checkExistName =
        selectedFiles[index].name ===
        uploadedFiles
          .find(
            (obj) =>
              !obj.isdisclosure &&
              obj.relationshipid === Number(selectedApplicant)
          )
          ?.file.split('/')
          .pop()
      if (checkExistName) {
        showMessage(
          `You have already uploaded files with name: ${selectedFiles[0].name}`,
          toastType.WARNING
        )
        return false
      }
    }
    return true
  }

  const handleUpload = (index: number) => {
    if (validate(index)) {
      const getSelectedParty =
        newModelData?.wipAccountDetailslist?.[0]?.parties.find(
          (obj) => obj.relationshipTypeId === Number(selectedApplicant)
        )
      const formData = new FormData()
      formData.append('createdby', loggedUser)
      formData.append('updatedby', loggedUser)
      formData.append(`FormFile[${0}].docid`, selectedDocumentIds[index].docid)
      formData.append(`FormFile[${0}].relationshipid`, selectedApplicant)
      formData.append(`FormFile[${0}].formFile`, selectedFiles[index])
      formData.append(`FormFile[${0}].createdby`, loggedUser)
      // formData.append(`FormFile[${0}].wipid`, Curr_wipId.toString())
      formData.append(
        `FormFile[${0}].wipacctid`,
        newModelData?.wipAccountDetailslist?.[0]?.wipacctid?.toString()
      )
      formData.append(`FormFile[${0}].comments`, 'comments')
      formData.append(
        `FormFile[${0}].partyid`,
        getSelectedParty ? getSelectedParty?.partyId.toString() : ''
      )
      formData.append(`FormFile[${0}].isdisclosure`, 'false')
      formData.append(`FormFile[${0}].isacknowledged`, 'false')
      //api Call
      setSpinner(true)
      formDocsContext.UploadDocs(formData).then((res) => {
        if (res.status == 200) {
          setSpinner(false)
          getRequiredDocs()
          getUploadedDocs()
          setSelectedFiles(
            selectedFiles.filter((_, objIndex) => objIndex !== index)
          )
          setSelectedDocumentIds(
            selectedDocumentIds.filter((_, objIndex) => objIndex !== index)
          )
          setFormErrors({
            ...formErrors,
            selctDoctypeError: '',
          })
          showMessage('Document uploaded successfully', toastType.SUCCESS)
        } else {
          setSpinner(false)
          showMessage(
            'Something went wrong while uploading files ',
            toastType.DANGER
          )
        }
      })
    }
  }

  const updateCardsSaved = (cardsStatus: string[]) => {
    const value = { unsavedCards: cardsStatus }
    const payload: UpdateCardsRequestInterface = {
      wipid: parseInt(wipId),
      value: JSON.stringify(value),
      updatedby: loggedUser,
    }
    updateCardsStatus(payload)
      .then(() => {
        setLoader(false)
      })
      .catch(() => {
        setLoader(false)
        setToast('error', 'Error in updating status')
      })
  }
  const handleSubmit = (index: number) => {
    handleUpload(index)
  }
  useEffect(() => {
    if (checkMandatoryDocs()) {
      const CardStatusValue = cardsStatus.filter((card) => card !== 'docs')
      setCardsStatus(CardStatusValue)
      updateCardsSaved(CardStatusValue)
      if (wipId) {
        const payload: GetApprovalRequest = {
          wipid: parseInt(wipId),
        }
        getCardsStatus(payload)
          .then((res: GetCardsResponseInterface) => {
            setCardsStatus(JSON.parse(res.value).unsavedCards)
          })
          .catch(() => {
            setToast('error', 'Unable to fetch status')
          })
      }
    } else {
      if (!cardsStatus.includes('docs')) {
        updateCardsSaved([...cardsStatus, 'docs'])
        if (wipId) {
          const payload: GetApprovalRequest = {
            wipid: parseInt(wipId),
          }
          getCardsStatus(payload)
            .then((res: GetCardsResponseInterface) => {
              setCardsStatus(JSON.parse(res.value).unsavedCards)
            })
            .catch(() => {
              setToast('error', 'Unable to fetch status')
            })
        }
      }
    }
  }, [checkMandatoryDocs()])

  const downloadDoc = (fileDetails: any) => {
    const filePath = fileDetails?.file.split('/')
    const tempFileDetails = { ...fileDetails, containerName: filePath[0] }
    //show  downloaded doc in new window
    formDocsContext.DownloadDoc(tempFileDetails).then((res) => {
      if (res.data) {
        const fileWindow = window.open('')
        if (fileWindow) {
          if (fileDetails.file.includes('pdf')) {
            const base64ToBlob = (
              base64: any,
              type = 'application/octet-stream'
            ) => {
              const binStr = window.atob(base64)
              const len = binStr.length
              const arr = new Uint8Array(len)
              for (let i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i)
              }
              return new Blob([arr], { type: type })
            }

            const blob = base64ToBlob(res.data, 'application/pdf')
            fileWindow.document.write(
              '<iframe src="' +
                URL.createObjectURL(blob) +
                '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
            )
          } else {
            const base64Flag = `data:application/jpg/png/jpeg;base64,${res.data}`
            fileWindow?.document.write(`<img src=${base64Flag} />`)
          }
          fileWindow.document.title = fileDetails.file.split('/').pop()
        }
      }
    })
  }

  let isCalled = false

  const handleDelUploadedDoc = (delFile: DownloadFile) => {
    setFileToDelete(delFile)
    setShowPopup(true)
  }
  const deleteDoc = () => {
    if (fileToDelete?.file) {
      const updatedItem = uploadedFiles.filter((file) => {
        return file.file != fileToDelete?.file
      })
      const name = fileToDelete.file.split('/').pop()
      const getFullPath = fileToDelete.file.split('/')
      getFullPath.pop()
      const reqObj: DelteFile = {
        fileName: name ? name : '',
        folderPath: getFullPath.join('/'),
        containerName: getFullPath[0],
        wipdocreqid: fileToDelete.wipdocreqid,
      }
      setLoader(true)
      formDocsContext.DeleteDoc(reqObj).then((res) => {
        if (res.status == 200) {
          showMessage('Document deleted successfully', toastType.SUCCESS)
          getRequiredDocs()
          getUploadedDocs()
          // setSelectedFiles([])
          setLoader(false)
          setUploadedFiles(updatedItem)
          const primaryFiles = updatedItem.filter(
            (file) => !file.iscoapplicant && !file.isdisclosure
          )
          const secondaryFile = updatedItem.filter(
            (file) => file.iscoapplicant && !file.isdisclosure
          )
          setPrimaryUploadedFiles(primaryFiles)
          setCoApplicantUploadedFiles(secondaryFile)
          if (!checkMandatoryDocs) {
            updateCardsSaved([...cardsStatus, 'docs'])
          }
        } else {
          showMessage('Something went wrong while deleting', toastType.DANGER)
          setLoader(false)
        }
      })
    }
  }
  useEffect(() => {
    if (!isCalled && newModelData?.wipAccountDetailslist) {
      getRequiredDocs()
      getUploadedDocs()
      isCalled = true
    }
  }, [])
  const docbyAcctype: {
    [key: string]: {
      docnames: string[]
      isrequired: boolean[]
      docid: number[]
    }
  } = {}

  requiredDocs.forEach((doc, index) => {
    docNamesDropDown.push({
      label: doc.name,
      value: doc.docid.toString(),
    })
  })

  requiredDocs.forEach((doc) => {
    if (docbyAcctype[doc.accType]) {
      docbyAcctype[doc.accType].docnames.push(doc.name)
      docbyAcctype[doc.accType].isrequired.push(doc.isrequired)
      docbyAcctype[doc.accType].docid.push(doc.docid)
    } else {
      docbyAcctype[doc.accType] = {
        docnames: [doc.name],
        isrequired: [doc.isrequired],
        docid: [doc.docid],
      }
    }
  })
  const previewDocument = (file: DownloadFile) => {
    const getContainerPath = file?.file?.split('/')
    setPreview(true)
    setPreviewFile({ ...file, containerName: getContainerPath[0] })
  }

  const custodianDetails =
    getWipResponse?.wipaccount?.[0]?.custodianDetails.filter(
      (custodian) => custodian.iscoapplicant
    )

  const filterData =
    uploadedFiles?.length === 0
      ? applicantOptions
      : applicantOptions.filter((item) =>
          uploadedFiles
            .filter((file) => !file.isdisclosure)
            .some((file) => item.value !== file.relationshipid?.toString())
        )
  return (
    <ResponsiveWrapper id="common">
      <DocParentWrapper>
        <JointWrapper>
          {newModelData &&
            newModelData?.wipAccountDetailslist?.[0] &&
            newModelData.wipAccountDetailslist[0]?.parties?.map(
              (coApplicant, index) => {
                if (
                  coApplicant.relationshipTypeId === 2 ||
                  coApplicant.relationshipTypeId === 1
                ) {
                  return (
                    <React.Fragment key={index}>
                      <ListofDocuments
                        listTitle={'List of Valid Documents'}
                        loaderList={loaderList}
                        documentList={
                          coApplicant.relationshipTypeId === 2
                            ? coApplicantDocs
                            : validDocs
                        }
                        uploadedDocs={
                          coApplicant.relationshipTypeId === 2
                            ? uploadedFiles.filter(
                                (doc) => doc.relationshipid === 2
                              )
                            : uploadedFiles.filter(
                                (doc) => doc.relationshipid === 1
                              )
                        }
                        coApplicant={coApplicant}
                        isCustodian={
                          newModelData?.wipAccountDetailslist?.[0]
                            ?.subaccttypetext === 'Custodian'
                        }
                      />
                    </React.Fragment>
                  )
                }
              }
            )}
        </JointWrapper>
        <Container>
          <StyledDocumentPanel>
            <FormDocsWrapper props={props}>
              {showPopup && (
                <DeleteConfirmationModal
                  deleteDoc={deleteDoc}
                  setShowPopup={setShowPopup}
                  message={
                    'The selected document will be deleted, click Delete to proceed'
                  }
                />
              )}
              <ContentWrapper>
                <TitleGrouping>
                  <TitleDoc id="List-doc" className="header_responsive">
                    {'Attach Documents'}
                  </TitleDoc>
                </TitleGrouping>
                <HintWrapper id="doc_header">
                  {`Please upload one of the displayed documents to verify your identity`}
                </HintWrapper>
                <AcknowledgementParagraph>
                  <HintWrapper id="doc_header">
                    {`Verify that the quality of the document is clear and legible,
                you can use a scanner or a clear picture of the document`}
                  </HintWrapper>
                </AcknowledgementParagraph>
                <SecondParagraph>
                  <FileUploadWrapper>
                    <DocumentContainer>
                      <AttachDocument
                        files={selectedFiles}
                        uploadedFiles={uploadedFiles}
                        onFileSelect={handleFileSelect}
                        EXTENSIONS={['pdf', 'jpeg', 'jpg', 'png']}
                        maxfileuploadsize={5}
                        showAlertMessage={showMessage}
                        isdisable={disableConditionDocuments()}
                        showPopup={showPopup}
                        onDisclosureSelect={() => {
                          //do nothing
                        }}
                        isDisclosure={false}
                      />
                    </DocumentContainer>
                    <Wrapper>
                      <File>
                        {formErrors.selctDoctypeError.length > 0 && (
                          <WDLabelError className="label_responsive">
                            {formErrors.selctDoctypeError}
                          </WDLabelError>
                        )}
                      </File>
                    </Wrapper>
                  </FileUploadWrapper>
                </SecondParagraph>
                <SecondParagraph>
                  {selectedFiles.length > 0 && (
                    <DocumentContainer>
                      {
                        selectedFiles.map((file, index) => (
                          <PreviewFile 
                          key={index}
                          file={file}
                          fileIndex={index}
                          documentDropdown={
                            selectedApplicant === '1'
                              ? validDocs.map((item) => {
                                  return {
                                    label: item.docname,
                                    value: item.docid.toString(),
                                  }
                                })
                              : coApplicantDocs.map((item) => {
                                  return {
                                    label: item.docname,
                                    value: item.docid.toString(),
                                  }
                                })
                          }
                          onDocumentIdChange={handleDocumentIdChange}
                          onDelete={handleFileDelete}
                          setSelectedFiles={setSelectedFiles}
                          selectedDocumentIds={selectedDocumentIds}
                          setSelectedDocumentIds={setSelectedDocumentIds}
                          applicantType={applicantType}
                          setApplicantType={setApplicantType}
                          blankError={blankErrors}
                          handleSubmit={(index) => handleSubmit(index)}
                          spinner={spinner}
                          uploadedFiles={uploadedFiles}
                          selectedApplicant={selectedApplicant}
                          setSelectedApplicant={setSelectedApplicant}
                          applicantOptions={filterData}
                          disableCondition={disableConditionDocuments()}
                          />
                        ))
                      }
                      {/* <PreviewFile
                        files={selectedFiles}
                        documentDropdown={
                          selectedApplicant === '1'
                            ? validDocs.map((item) => {
                                return {
                                  label: item.docname,
                                  value: item.docid.toString(),
                                }
                              })
                            : coApplicantDocs.map((item) => {
                                return {
                                  label: item.docname,
                                  value: item.docid.toString(),
                                }
                              })
                        }
                        onDocumentIdChange={handleDocumentIdChange}
                        onDelete={handleFileDelete}
                        setSelectedFiles={setSelectedFiles}
                        selectedDocumentIds={selectedDocumentIds}
                        setSelectedDocumentIds={setSelectedDocumentIds}
                        applicantType={applicantType}
                        setApplicantType={setApplicantType}
                        blankError={blankErrors}
                        handleSubmit={(index) => handleSubmit(index)}
                        spinner={spinner}
                        uploadedFiles={uploadedFiles}
                        // selectedApplicant={selectedApplicant}
                        // setSelectedApplicant={setSelectedApplicant}
                        applicantOptions={filterData}
                      /> */}
                    </DocumentContainer>
                  )}
                  <UploadedFilesContainer>
                    {uploadedFiles.length > 0 &&
                      newModelData?.wipAccountDetailslist?.[0]?.parties
                        ?.filter((obj) => obj.relationshipTypeId <= 2)
                        .map((item, index) => {
                          const filterRelationRecord = uploadedFiles.filter(
                            (obj) =>
                              obj.relationshipid === item.relationshipTypeId &&
                              !obj.isdisclosure
                          )
                          if (filterRelationRecord.length === 0) return
                          let findDividerType = uploadedFiles.map(
                            (obj) => obj.relationshipid
                          )
                          if (
                            findDividerType.length > 0 &&
                            uploadedFiles.length > 0
                          ) {
                            findDividerType = [
                              ...new Set(
                                uploadedFiles.map((obj) => obj.relationshipid)
                              ),
                            ]
                          }
                          return (
                            <UploadedFilesWrapper key={index}>
                              {newModelData?.wipAccountDetailslist?.[0]?.parties?.filter(
                                (obj) => obj.relationshipTypeId <= 2
                              ).length > 1 &&
                                index === 1 &&
                                filterRelationRecord.length > 0 &&
                                findDividerType.length > 1 && (
                                  <Divider
                                    thickness={'2px'}
                                    height="1rem"
                                    bgColor={COLORS.Background.Neutral}
                                    horizontal={true}
                                  />
                                )}
                              {newModelData?.wipAccountDetailslist?.[0]?.parties?.filter(
                                (obj) => obj.relationshipTypeId <= 2
                              ).length > 1 &&
                                filterRelationRecord.length > 0 && (
                                  <TitleDoc className="label_responsive">
                                    {item?.relationshipToPrimaryOwner}
                                  </TitleDoc>
                                )}
                              <UploadedFiles
                                loader={loader}
                                fileToDelete={fileToDelete}
                                downloadDoc={downloadDoc}
                                previewDocument={previewDocument}
                                handleDelUploadedDoc={handleDelUploadedDoc}
                                isCoApplicant={false}
                                isDisclosure={false}
                                uploadedFiles={filterRelationRecord}
                                requiredDocsList={requiredDocsList}
                              />
                            </UploadedFilesWrapper>
                          )
                        })}
                    {/* {uploadedFiles.length > 0 &&
                      getWipResponse &&
                      getWipResponse.wipaccount?.[0] &&
                      getWipResponse.wipaccount[0] &&
                      getWipResponse.wipaccount[0]?.coApplicantDetails?.map(
                        (coApplicant, index) => {
                          return (
                            <>
                              {!coApplicant.iscoapplicant &&
                              primaryUploadedFiles?.length ? (
                                <UploadedFilesWrapper key={index}>
                                  {getWipResponse &&
                                  getWipResponse?.wipaccount &&
                                  getWipResponse?.wipaccount[0]?.accttitle ===
                                    'Joint' ? (
                                    <TitleDoc className="label_responsive">
                                      Primary Applicant
                                    </TitleDoc>
                                  ) : null}
                                  <UploadedFiles
                                    loader={loader}
                                    fileToDelete={fileToDelete}
                                    downloadDoc={downloadDoc}
                                    previewDocument={previewDocument}
                                    handleDelUploadedDoc={handleDelUploadedDoc}
                                    isCoApplicant={coApplicant.iscoapplicant}
                                    isDisclosure={false}
                                    uploadedFiles={uploadedFiles}
                                  />
                                </UploadedFilesWrapper>
                              ) : coApplicantUploadedFiles?.length &&
                                coApplicant.iscoapplicant ? (
                                <>
                                  {primaryUploadedFiles?.length &&
                                  coApplicant.iscoapplicant ? (
                                    <Divider
                                      thickness={'2px'}
                                      height="1rem"
                                      bgColor={COLORS.Background.Neutral}
                                      horizontal={true}
                                    />
                                  ) : null}
                                  <UploadedFilesWrapper key={index}>
                                    {getWipResponse &&
                                    getWipResponse?.wipaccount &&
                                    getWipResponse?.wipaccount[0]?.accttitle ===
                                      'Joint' ? (
                                      <TitleDoc className="label_responsive">
                                        Co-Applicant
                                      </TitleDoc>
                                    ) : null}
                                    <UploadedFiles
                                      loader={loader}
                                      fileToDelete={fileToDelete}
                                      downloadDoc={downloadDoc}
                                      previewDocument={previewDocument}
                                      handleDelUploadedDoc={
                                        handleDelUploadedDoc
                                      }
                                      isCoApplicant={coApplicant.iscoapplicant}
                                      isDisclosure={false}
                                      uploadedFiles={uploadedFiles}
                                    />
                                  </UploadedFilesWrapper>
                                </>
                              ) : null}
                            </>
                          )
                        }
                      )} */}
                  </UploadedFilesContainer>
                </SecondParagraph>
              </ContentWrapper>
              {apiPreviewStatus.status !== '' && (
                <ToastWrapper>
                  <Toast
                    text={apiPreviewStatus.text}
                    type={apiPreviewStatus.status}
                    openStatusCallback={(status: boolean) =>
                      setAPIPreviewStatus({
                        status: '',
                        text: '',
                      })
                    }
                  />
                </ToastWrapper>
              )}
            </FormDocsWrapper>
          </StyledDocumentPanel>

          {preview && (
            <ViewDocument setModalCallBack={setPreview} rowData={previewFile} />
          )}
        </Container>
      </DocParentWrapper>
    </ResponsiveWrapper>
  )
}

export default FormDocuments
