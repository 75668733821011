import './App.css'
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionType,
} from '@azure/msal-browser'
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react'
import AppRoutes from './AppRoutes'
import { loginRequest } from './settings/authConfig'
import { useIdleTimer } from 'react-idle-timer'
function App() {
  const authRequest = {
    ...loginRequest,
  }
  const { instance } = useMsal()
  instance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult
      const account = payload.account
      instance.setActiveAccount(account)
    }
  })
  const onIdle = () => {
    sessionStorage.clear()
    instance.logout()
  }

  useIdleTimer({
    onIdle,
    timeout: 1200000,
    promptTimeout: 0,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false,
  })
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <AppRoutes />
    </MsalAuthenticationTemplate>
  )
}

export default App
