import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  padding: 0.5rem 0.25rem;
  textarea {
    resize: none;
  }
`
export const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
`
export const CommentButton = styled.div`
display: flex;
justify-content: flex-end;
`