import { GlueContext } from '@glue42/react-hooks'
import { Glue42 } from '@glue42/desktop'
import { createContext, useContext, useEffect, useState } from 'react'
import { ApplicationInfoContext } from '../applicationContext/ApplicationPageContext'
import { WipRequestDTO } from '../../composites/forms/formWip/IFormWip'

interface AiwContextProps {
  children: React.ReactNode | React.ReactNode[]
}
interface AiwStateInterface {
  isContextAvailable: boolean
}
export const AiwContext = createContext({} as AiwStateInterface)
export const AiwContextProvider = ({ children }: AiwContextProps) => {
  const glue = useContext(GlueContext) as unknown as Glue42.Glue
  const { wipId, setWipFormData, setSelectedClient, wipFormData } = useContext(
    ApplicationInfoContext
  )
  const [isContextAvailable, setIsContextAvailable] = useState<boolean>(false)
  useEffect(() => {
    const listenToGlue42ContextChange = async () => {
      try {
        const myWorkspace = await glue?.workspaces?.getMyWorkspace()
        myWorkspace?.onContextUpdated((newContext) => {
          setIsContextAvailable(true)
          if (!wipId) {
            const obj: WipRequestDTO = {
              // repcode: '',
              // noofprods: 0,
              // advisor: '',
              // faid: '',
              // applicationnickname: '',
              // lobid:''
              wipid: 0,
              faid: '',
              repcode: '',
              noofprods: 0,
              advisor: '',
              applicationnickname: '',
              iraaccountownertypeid: 0,
              lobid: 0,
              wipstatusid: 0,
            }
            setWipFormData({
              ...wipFormData,
              t_WIP: obj,
            })
            setSelectedClient((prev: any) => {
              return {
                ...prev,
                clientId: newContext?.AIWContext?.clientId,
              }
            })
          }
        })
      } catch (e) {
        console.log(e)
      }
    }
    listenToGlue42ContextChange()
  }, [glue])
  return (
    <AiwContext.Provider
      value={{
        isContextAvailable,
      }}
    >
      {children}
    </AiwContext.Provider>
  )
}

export default AiwContext
