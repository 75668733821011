import Button from "../../../components/button/Button";
import Label from "../../../components/label/Label";
import { SvgYellowWarning } from "../../../components/svg/SvgYellowWarning";
import { WDButton, WDButtonDisabled, WDButtonNeutral } from "../../../components/ui/WDButton";
import { WDLabelBlackSemiBold } from "../../../components/ui/WDLabel";
import { ButtonWrapper, ContentWrapper, MasterWrapperModel, ModalContentWrapper, ModalWrapper, OverlayWrapper } from "../formDocuments/styles";

interface DeleteConfirmationModalProps {
    setShowPopup: (value: boolean) => void;
    deleteDoc: () => void;
    message?: string;
}

const DeleteConfirmationModal = ({ setShowPopup, deleteDoc, message }: DeleteConfirmationModalProps) => {
    return (
        <MasterWrapperModel>
            <OverlayWrapper>
                <ModalWrapper>
                    <ModalContentWrapper>
                        <SvgYellowWarning fillColor={'#F97316'} />
                        <ContentWrapper>
                            <WDLabelBlackSemiBold>
                                <Label>
                                    {message}
                                </Label>
                            </WDLabelBlackSemiBold>
                            <ButtonWrapper>
                                <WDButton>
                                    <Button
                                        type={'button'}
                                        onClick={() => {
                                            setShowPopup(false)
                                            deleteDoc()
                                        }}
                                    >
                                        {'Delete'}
                                    </Button>
                                </WDButton>
                                <WDButtonNeutral>
                                    <Button
                                        type={'button'}
                                        onClick={() => setShowPopup(false)}
                                    >
                                        {'Cancel'}
                                    </Button>
                                </WDButtonNeutral>
                            </ButtonWrapper>
                        </ContentWrapper>
                    </ModalContentWrapper>
                </ModalWrapper>
            </OverlayWrapper>
        </MasterWrapperModel>
    )
}
export default DeleteConfirmationModal;