import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from './settings/authConfig'
import { ApplicationPageContextProvider } from './services/applicationContext/ApplicationPageContext'
import AuthContextProvider from './services/AuthContext'
import { MsalProvider } from '@azure/msal-react'
import platform from 'platform'
import { Glue42DesktopFactory, GlueProvider } from '@glue42/react-hooks'
import Glue from '@glue42/desktop'
import GlueWorkspaces from '@glue42/workspaces-api'
import { AiwContextProvider } from './services/aiwContext/AiwContext'
import { DisclosureContextProvider } from './services/disclosureContext/DisclosureContext'
import { ApprovalContextProvider } from './services/approvalScreenContext/ApprovalContext'
import { ToastContextProvider } from './services/toast/ToastContext'
import { RetirementDetailsContextProvider } from './services/Retirement/RetirementContext'
import { LobInfoContextProvider } from './services/LobOfficeCode/LobOfficeContext'
import { RoleContextProvider } from './services/roleContext/RoleContext'

export const msalInstance = new PublicClientApplication(msalConfig)

const GlueContextProvider = () => (
  <GlueProvider
    fallback={<h1>Loading...</h1>}
    settings={{
      desktop: {
        factory: Glue as Glue42DesktopFactory,
        config: {
          channels: true,
          appManager: 'full',
          layouts: 'full',
          libraries: [GlueWorkspaces],
        },
      },
    }}
  >
    <AiwContextProvider>
      <App />
    </AiwContextProvider>
  </GlueProvider>
)
const AppProvider = () => (
  <MsalProvider instance={msalInstance}>
    <AuthContextProvider>
      <RoleContextProvider>
        <ToastContextProvider>
          <ApprovalContextProvider>
            <LobInfoContextProvider>
              <RetirementDetailsContextProvider>
                <ApplicationPageContextProvider>
                  <DisclosureContextProvider>

                    {platform.name === 'Electron' ? (
                      <GlueContextProvider />
                    ) : (
                      <App />
                    )}

                  </DisclosureContextProvider>
                </ApplicationPageContextProvider>
              </RetirementDetailsContextProvider>
            </LobInfoContextProvider>
          </ApprovalContextProvider>
        </ToastContextProvider>
      </RoleContextProvider>
    </AuthContextProvider>
  </MsalProvider>
)
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppProvider />
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
