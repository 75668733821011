import { useContext, useEffect, useState } from 'react'
import { SvgShield1 } from '../../../components/svg/SvgShield'
import { Textarea } from '../../../components/textArea/Textarea'
import { WDLabelBold } from '../../../components/ui/WDLabel'
import { ApplicationInfoContext } from '../../../services/applicationContext/ApplicationPageContext'
import { updateCommentsAPI } from '../../../services/approvalScreenContext/ApprovalAPI'
import { AuthContext } from '../../../services/AuthContext'
import { UpdateAppStatusRequestInterface } from '../../../services/clientAndAccountSearch/IClientAndAccountSearchContext'
import { getWipDetails } from '../../../services/formRequest/FormRequestAPI'
import ToastContext from '../../../services/toast/ToastContext'
import { StyledDocumentPanel } from '../accountInformation/styles'
import { TitleGrouping } from '../trustedContact/styles'
import { CommentButton, Wrapper } from './styles'
import { UpdateCommentsRequest } from '../../../services/approvalScreenContext/IApproval'

interface FormWipProps {
  saveRecall: boolean
  resetSave: (val: boolean) => void
  setIsUploadSend: (val: boolean) => void
}

function ClientReviewCommentSection({
  saveRecall,
  resetSave,
  setIsUploadSend,
}: FormWipProps) {
  const {
    newModelData,
    setNewModelData,
    updateAppStatus,
    stageNames,
    setStage,
    setActiveStage,
  } = useContext(ApplicationInfoContext)
  const { loggedUser } = useContext(AuthContext)
  const [commentSection, setCommentSection] = useState('')
  const { wipId, setWipResponse, individualPartyData } = useContext(
    ApplicationInfoContext
  )
  const { setToast } = useContext(ToastContext)

  const UpdateCommentsAPIButton = () => {
    const payload: UpdateCommentsRequest = {
      wipid: Number(wipId),
      comments: commentSection,
      user: individualPartyData?.firstName,
      wipstatus: newModelData?.wipDetails.wipstatusid.toString() ?? '',
      createdby: loggedUser,
      updatedby: loggedUser,
      createdtimestamp: new Date(),
      updatedtimestamp: new Date(),
    }
    updateCommentsAPI(payload)
      .then(() => {
        resetSave(false)
        setCommentSection('')
        setToast('success', 'Comments saved')
        const payload: UpdateAppStatusRequestInterface = {
          wipid: parseInt(wipId),
          wipstatus: 6,
        }
        updateAppStatus(payload).then(() => {
          // const navStage = stageNames.find((obj: any) => obj.name === 'Review')
          // if (navStage) {
          //   setStage(navStage.stage)
          //   setActiveStage(navStage.stage)
          // }
          // getWipDetails({ wipId: parseInt(wipId), appId: '1' }).then((res) => {
          //   setNewModelData(res.data)
          // })
          setIsUploadSend(true)
        })
      })
      .catch(() => {
        resetSave(false)
        setToast('success', 'Comments failed')
      })
  }

  useEffect(() => {
    if (saveRecall) {
      UpdateCommentsAPIButton()
    }
  }, [saveRecall])

  return (
    <>
      <StyledDocumentPanel>
        <TitleGrouping>
          <SvgShield1 />
          <WDLabelBold>{'Comments'}</WDLabelBold>
        </TitleGrouping>
        <Wrapper>
          <Textarea
            placeholder={''}
            onChange={(e: any) => setCommentSection(e)}
            value={commentSection}
          ></Textarea>
        </Wrapper>
        <CommentButton>
          {/* <WDButtonOutLine>
            <Button
              type={'button'}f
              onClick={() => {
                handleComments()
              }}
            >
              {loader ? (
                <SpinnerButtonWrapper>
                  <LottieButtonWrapper>
                    <Lottie animationData={Loader} loop={true} />
                  </LottieButtonWrapper>
                </SpinnerButtonWrapper>
              ) : (
                <WDLabelPrimaryText className="label_responsive">
                  {'Save'}
                </WDLabelPrimaryText>
              )}
            </Button>
          </WDButtonOutLine> */}
        </CommentButton>
      </StyledDocumentPanel>
    </>
  )
}

export default ClientReviewCommentSection
