const ENDPOINTS = {
  // Form Requests
  CREATE_WIP: '/aopexperienceapi/WIP/CreateWip',
  GET_ACCOUNT_TYPES: '/aopexperienceapi/Account/GetAccounttypes?lob={lob}',
  GET_WIP_DETAILS: '/aopexperienceapi/WIP/GetWipDetails',
  GET_XREF_DETAILS:
    '/aopexperienceapi/xref/GetXrefDetails?appid={appid}&lobid={lob}',
  GET_PARTY_DETAILS: '/aopexperienceapi/WIP/GetSearchPartyByID?partyid',

  //roles
  GET_ACCOUNT_ROLEDETAILS_BY_WIPID: '/PartyRole/GetAccountRoleDetailsByWipID',
  ADD_ACCOUNT_ROLE: '/PartyRole/AddAccountRole',
  DELETE_ACCOUNT_ROLE: '/PartyRole/DeleteAccountRole',
  UPDATEACCOUNTROLEDETAILS: '/PartyRole/UpdateAccountRoleDetails',
  GET_ROLES_MASTER: '/RoleMaster/GetRoleMasterDetailsByAcctTypeId',

  // account details
  GET_ACCOUNTDETAILS_BY_WIPACCOUNTID:
    '/aopexperienceapi/AccountFeatures/getaccountfeaturedetailsbywipacctid',
  UPDATE_ACCOUNTDETAILS:
    '/aopexperienceapi/AccountFeatures/UpdateNewAccountFeature',
  CREATE_NEW_ACCOUNT:
    '/aopexperienceapi/AccountFeatures/CreateNewAccountFeature',
  GET_ACCOUNT_FEATURE_QSN_CONFIG:
    '/aopexperienceapi/UIConfig/GetQuestionsByaccttpe',
  GET_VALUE_JSON: '/aopexperienceapi/UIConfig/GetQuestionResponse',

  // account dynamic feature
  CREATE_NEW_ACCOUNT_FEATURE: '/aopexperienceapi/UIConfig/CreateUiJson',
  UPDATE_NEW_ACCOUNT_FEATURE: '/aopexperienceapi/UIConfig/UpdateUiJson',

  //Reserved Account Number
  GET_RESERVEDACCTNUM: '/aopexperienceapi/ReservedAcct/GetAcctNumber',
  GET_WIPIDBYRESNO: '/aopexperienceapi/ReservedAcct/GetWipIdByResAcctNumber',
  GET_EXISTINGRESNUMBER:
    '/aopexperienceapi/ReservedAcct/GetResAcctNumberByWipAccId',
  //tabs
  GET_ACCOUNT_TABS: '/aopexperienceapi/UIConfig/GetAccountTabs',

  //funding
  GET_FUNDING: '/aopexperienceapi/Funding/GetFunding?wipacctid',
  ADD_FUNDING: '/aopexperienceapi/Funding/AddFunding',
  UPDATE_FUNDING: '/aopexperienceapi/Funding/UpdateFunding',

  //Investment Profile
  ADD_INV_PROFILE:
    '/aopexperienceapi/InvestmentProfile/CreateInvestmentProfile',
  GET_INV_PROFILE:
    '/aopexperienceapi/InvestmentProfile/GetInvestmentDetailsByWipid',
  UPDATE_INV_PROFILE:
    '/aopexperienceapi/InvestmentProfile/UpdateInvestmentProfile',

  //Documents upload
  UPLOAD_NEW_DOCS: '/aopexperienceapi/DocumentUpload/UploadNewDoc',
  UPDATE_DOCS: '/aopexperienceapi/DocumentUpload/UpdateDoc',
  GET_DOC_DETAILS: '/aopexperienceapi/DocumentUpload/GetUploadedDocumentList',
  DOWNLOAD_DOCUMENT: '/aopexperienceapi/DocumentUpload/Download',
  FETCH_REQ_DOCS: '/aopexperienceapi/DocumentUpload/GetDocumentByAcctType',
  DELTE_DOC: '/aopexperienceapi/DocumentUpload/DeleteBlob',

  // review
  GET_REVIEW_DETAILS: '/aopexperienceapi/Review/GetReview',
  GET_REVIEW_VALIDATION: '/aopexperienceapi/UIConfig/GetWipFormValidation',

  // Disclosure
  GET_DISCLOSURE: '/Disclosure/GetDisclosure',
  ADD_DISCLOSURE: '/Disclosure/AddDisClosure',
  ADD_DISCLOSURE_DASHBOARD: '/aopexperienceapi/Disclosure/AddDisclosure',

  // client search
  GET_CLIENT_LIST_BY_NAME: '/clientapi/Party/GetClientListByName',
  GET_CLIENT_BY_ID: '/clientapi/Party/GetClientListByPartyId',
  GET_ACCOUNTS_BY_NUMBER: '/clientapi/Party/GetClientListByAccountNumber',
  GET_CLIENT_DETAILS_DATA:
    '/clientapi/IndividualParty/GetIndividualDetailsData',
  GET_CLIENT_ADDRESS: '/clientapi/ClientAddress/GetClientAddress',

  //AO Dashboard
  GET_ACCOUNT_DASHBOARD_INFO:
    '/aopexperienceapi/Dashboard/GetAccountDashboardDetails',
  DELETEWIP: '/aopexperienceapi/WIP/DeleteWip',

  //LOB
  GET_SUBACCT_TYPE:
    '/aopexperienceapi/LoBEntityCode/GetActSubActType?accttypeid={accid}&lob={lb}&entitycode={ec}',
  GET_ALL_LOB: '/aopexperienceapi/LoBEntityCode/GetAllLoB',
  ADD_LOB: '/aopexperienceapi/LoBEntityCode/AddLineOfBusiness',

  //app status
  ADD_APP_STATUS: '/aopexperienceapi/WIP/AddAppStatus',
  UPDATE_APP_STATUS: '/aopexperienceapi/WIP/UpdateWip',

  //commentsectio
  UPDATE_COMMENT_SECTION: '/aopexperienceapi/Dashboard/UpdateComments',

  //request detail
  CREATE_REQUEST: '/templateapi/RequestDetail/CreateRequestDetail',

  //Electronic Prefernce
  GET_ELECTRONIC_PREFERNCE:
    '/aopexperienceapi/ElectronicPreferences/GetElecPref/{id}',
  ADD_ELECTRONIC_PREFERNCE:
    '/aopexperienceapi/ElectronicPreferences/AddElecPref',
  UPDATE_ELECTRONIC_PREFERNCE:
    '/aopexperienceapi/ElectronicPreferences/UpdateElecPref',

  //Party Investment
  GET_PARTY_INVESTMENT: '/clientapi/InvestmentProfile/GetInvestmentProfile',

  //Contact info
  GET_CONTACT_INFO: '/aopexperienceapi/ContactInfo/GetContactInfo',
  UPDATE_CONTACT_INFO: '/aopexperienceapi/ContactInfo/UpdateContactInfo',
  ADD_CONTACT_INFO: '/aopexperienceapi/ContactInfo/AddContactInfo',

  //Account Feature
  GET_ACCOUNT_FEATURE: '/aopexperienceapi/AccountInfo/GetContactInfo?wipid=',
  UPDATE_ACCOUNT_FEATURE: '/aopexperienceapi/AccountInfo/UpdateContactInfo',
  ADD_ACCOUNT_FEATURE: '/aopexperienceapi/AccountInfo/AddContactInfo',

  //Advisor list
  GET_ADVISOR_LIST:
    '/advisormasterapi/VisibilityCheck/GetRepAccessLevelsByUserName',
  GET_ENTITY_DETAILS:
    '/advisormasterapi/RegisteredRep/GetAdvisorDatabyADUserName',
  GET_ADVISORDATA_REPCODE: '/advisormasterapi/Advisor/GetAdvisorDataByRepCode',

  //Disclosure -2
  GET_DISCLOSURE_API: '/aopexperienceapi/Disclosure/GetDisclosure',
  UPDATE_DISCLOSURE_API: '/aopexperienceapi/Disclosure/UpdateDisclosure',
  ENABLE_DISCLOSURE: '/aopexperienceapi/Disclosure/EnableDisclosureList',

  //Approval Screen
  GET_APPROVAL: '/aopexperienceapi/Dashboard/GetApprovals/{wipid}',
  GET_AUDIT_TRAILS: '/aopexperienceapi/Dashboard/GetAuditTrails/{wipid}',
  GET_COMMENTS: '/aopexperienceapi/Dashboard/GetComments/{wipid}',
  UPDATE_APPROVAL: '/aopexperienceapi/Dashboard/UpdateApprovals',
  ADD_COMMENTS: '/aopexperienceapi/Dashboard/AddComments',

  //FIS API
  CREATE_ACCOUNT: '/aopexperienceapi/Approval/CreateAccount/{wipid}',
  UPDATE_ACCOUNT: '/aopexperienceapi/Approval/UpdateAccount',
  //BankList
  DEPO_BANKLIST: '/aopexperienceapi/xref/GetxrefDetailsByDomn',

  //Client maintenance - aop API
  GET_CLIENT_DATA:
    '/aopexperienceapi/WIP/GetClientProfile?partyid={partyid}&wipacctid={wipacctid}',
  UPDATE_CLIENT_PROFILE: '/aopexperienceapi/WIP/UpdateClientProfile',

  //TRUSTED CONTACT
  GET_TRUSTED_CONTACT:
    '/aopexperienceapi/WIP/GetTrustedContactDetails?wipid={wipID}',
  UPDATE_TRUSTED_CONTACT: '/aopexperienceapi/PartyRole/AddAccountRole',

  DELETE_BENEFICIARY_CONTACT: '/aopexperienceapi/PartyRole/DeleteAccountRole',


  //Pdf template
  GET_PDF_DATA: '/aopexperienceapi/WIP/GenerateHtmltoPdf',

  //Cards status
  GET_CARDS_STATUS: '/aopexperienceapi/WIP/GetCards/{wipid}',
  UPDATE_CARDS_STATUS: '/aopexperienceapi/WIP/UpdateCards',

  //Industry
  GET_INDUSTRY: '/aopexperienceapi/IndustryAdvisor/GetIndustryAffiliations',

  //GetOfficeCodeLOB
  GET_OFFICE_CODE_LOB: '/aopexperienceapi/LoBEntityCode/GetOfficeCodeLOB',
  GET_STATE_LIST: '/servicerequestapi/GetState',

  //UPDATE_WIP_ACCOUNT
  UPDATE_WIP_ACCOUNT: '/aopexperienceapi/WIP/UpdateWipAccount',
}

export default ENDPOINTS
