import Lottie from 'lottie-react'
import {
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import Loader from '../../assets/lottiefiles/loader.json'
import ErrorToast from '../../components/ErrorToast/ErrorToast'
import Button from '../../components/button/Button'
import Divider from '../../components/divider/Divider'
import Radio from '../../components/extra/radio/Radio'
import { Textarea } from '../../components/extra/textArea/Textarea'
import Label from '../../components/label/Label'
import LandingPageLayout from '../../components/layouts/landingPage/LandingPageLayout'
import {
  SvgChevronLeftSmall,
  SvgChevronRightSmall,
} from '../../components/svg/SvgChevron'
import { SvgYellowWarning } from '../../components/svg/SvgYellowWarning'
import {
  WDButton,
  WDButtonDisabled,
  WDButtonNeutral,
  WDButtonOutLine,
} from '../../components/ui/WDButton'
import {
  WDLabel,
  WDLabelBlackSemiBold,
  WDLabelClient,
  WDLabelPrimaryText,
  WDLabelSemiBold,
} from '../../components/ui/WDLabel'
import {
  ButtonWrapper,
  MasterWrapperModel,
  ModalWrapper,
  OverlayWrapper,
} from '../../composites/AOPHomepage/styles'
import ContentHeader from '../../composites/contentHeader/ContentHeader'
import AccountInformation from '../../composites/forms/accountInformation/AccountInformation'
import ClientReviewInformation from '../../composites/forms/clientReviewSection/ClientReviewInformation'
import EntityCorp from '../../composites/forms/entityCorpForms/EntityCorpForm'
import FinalReviewInformation from '../../composites/forms/finalReviewInformation/FinalReviewInformation'
import FormDocuments from '../../composites/forms/formDocuments/FormDocuments'
import {
  ContentWrapper,
  ModalContentWrapper,
} from '../../composites/forms/formDocuments/styles'
import {
  CreateWipRequestInterface,
  GetWipRequestInterface,
} from '../../composites/forms/formWip/IFormWip'
import Retirement from '../../composites/forms/retirementForm/Retirement'
import ReviewInformation from '../../composites/forms/reviewSection/ReviewInformation'
import ReviewTemplate from '../../composites/forms/reviewSection/ReviewTemplate'
import TrustForm from '../../composites/forms/trustForms/TrustForm'
import ManualDisclosures from '../../composites/manualDisclosures/ManualDisclosures'
import ProgressBar from '../../composites/progressBar/ProgressBar'
import { OptionProps } from '../../composites/selectDropdown/ISelectDropDown'
import { AuthContext } from '../../services/AuthContext'
import { LobInfoContext } from '../../services/LobOfficeCode/LobOfficeContext'
import { ApplicationInfoContext } from '../../services/applicationContext/ApplicationPageContext'
import { CreateAccountRequestInterface } from '../../services/applicationContext/IApplicationPageContext'
import { ApprovalContext } from '../../services/approvalScreenContext/ApprovalContext'
import {
  GetApprovalRequest,
  GetCardsResponseInterface,
  UpdateCardsRequestInterface,
} from '../../services/approvalScreenContext/IApproval'
import ClientAndAccountSearchContext from '../../services/clientAndAccountSearch/ClientAndAccountSearchContext'
import {
  AddDisclosureDashboard,
  ClientSearchByNameResponseInterface,
  IndividualClientResponseInterface,
  PartyDemographicInterface,
  UpdateAppStatusRequestInterface,
} from '../../services/clientAndAccountSearch/IClientAndAccountSearchContext'
import { DisclosureContext } from '../../services/disclosureContext/DisclosureContext'
import {
  addWipDetails,
  getPdfTemplate,
  getWipDetails,
} from '../../services/formRequest/FormRequestAPI'
import { RoleContext } from '../../services/roleContext/RoleContext'
import ToastContext from '../../services/toast/ToastContext'
import { setSessionItem } from '../../shared/utils/utils'
import { COLORS } from '../../theme/Colors'
import { PartyDetailsProps } from './iNewGrid'
import {
  AccountFooterButtonGroup,
  AccountInfoBtnWrapper,
  CommentModalWrapper,
  ContentInfoWrapper,
  CreateWipFooterGroup,
  ErrorToastWrapper,
  FooterWrapper,
  GridAreas,
  GridContainerNew,
  ItemWrapper,
  LabelWrapper,
  LottieWrapper,
  ModalInfoWrapper,
  NavHeaderWrapper,
  RadioInfoWrapper,
  RadioOptionInfoWrapper,
  SpinnerWrapper,
} from './styles'

const AccountApplicationContent = () => {
  const navigate = useNavigate()
  const { setToast } = useContext(ToastContext)
  const { accessList } = useContext(RoleContext)
  // const [stage, setStage] = useState(0)
  const [modal, setModal] = useState(false)
  const [buttonText, setButtonText] = useState('Send')
  const [radioText, setRadioText] = useState('')
  const [download, setDownload] = useState(false)
  const [callUpdate, setCallUpdate] = useState(false)
  const [loader, setLoader] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const { loggedUser } = useContext(AuthContext)
  const {
    wipId,
    updateAppStatus,
    addDisclosureDashboard,
    newModelData,
    setNewModelData,
    createAccountRequest,
    toggleErrorToast,
    stageNames,
    stage,
    setStage,
    saveAll,
    setWipFormData,
    setSelectedClient,
    setActiveStage,
    setStageNames,
    setWipId,
    selectedClient,
    wipFormData,
    setCreateWipFlag,
    setIsDashboardNavigate,
    setShowError,
    setContactError,
    setClientAddress,
    setMailingAddress,
    setButtonTextContact,
    setMobilePhone,
    setEmailAddress,
    setHomePhone,
    setOtherPhone,
    setBusinessPhone,
    setFax,
    setRelation,
    setAccountInfoLoader,
    uploadedFiles,
    setSelectedCheck,
    setTrustedParty,
    autoSaveFunction,
    readOnly,
    setSaveFlag,
    setAssEmpDropDown,
    setRegBrokerDropDown,
    setOwnedCompDropDown,
    setIndusAffRelation,
    setEntity,
    setCompanyDetails,
  } = useContext(ApplicationInfoContext)
  const {
    templateData,
    disableCondition,
    disableConditionDocuments,
  } = useContext(DisclosureContext)
  const { lobOfcCode } = useContext(LobInfoContext)

  const { selectedAccount, contactInfoData } = useContext(
    ClientAndAccountSearchContext
  )
  const {
    showComment,
    setShowComment,
    commentButton,
    isRecalled,
    setIsRecalled,
    setCardsStatus,
    getCardsStatus,
    cardsStatus,
    updateCardsStatus,
  } = useContext(ApprovalContext)
  const pdfRef = useRef() as MutableRefObject<HTMLDivElement>
  const [saveRecall, setSaveRecall] = useState<boolean>(false)
  const [isUploadSend, setIsUploadSend] = useState<boolean>(false)
  const createWip = () => {
    if (wipFormData && wipFormData.t_WIP) {
      const data: CreateWipRequestInterface = {
        createdby: loggedUser,
        updatedby: loggedUser,
        t_WIP: {
          wipid: 0,
          faid: wipFormData.t_WIP.faid,
          repcode: wipFormData.t_WIP.repcode,
          noofprods: 1,
          advisor: wipFormData.t_WIP.advisor,
          applicationnickname: wipFormData.t_WIP.applicationnickname,
          iraaccountownertypeid: wipFormData.t_WIP.iraaccountownertypeid,
          lobid: lobOfcCode[0]?.lobid,
          wipstatusid: 0,
        },
        t_WIP_Account: [
          {
            wipacctid: 0,
            wipid: 0,
            accounttypeid: wipFormData.t_WIP_Account[0].accounttypeid,
            subaccounttypeid: wipFormData.t_WIP_Account[0].subaccounttypeid,
            accttitle: wipFormData.t_WIP_Account[0].accttitle,
            contact: null,
            t_Party_Role: wipFormData?.t_WIP_Account?.[0]?.t_Party_Role,
          },
        ],
        appId: '1',
      }

      if (data.createdby && data.createdby !== '') {
        setCreateWipFlag(true)
        if (
          data.t_WIP.repcode &&
          data.t_WIP.applicationnickname &&
          data.t_WIP_Account[0].accounttypeid &&
          data.t_WIP_Account[0].subaccounttypeid &&
          selectedClient.clientId &&
          wipFormData?.t_WIP_Account?.[0].t_Party_Role?.every(
            (obj) => obj.partyid !== 0
          )
        ) {
          setAccountInfoLoader(true)
          addWipDetails(data)
            .then((resp) => {
              setAccountInfoLoader(false)
              setToast('success', `WIP created with ID:${resp.data.wipid}`)
              setWipId(resp.data.wipid.toString())
              setSessionItem('wipId', resp.data.wipid.toString())
              setCreateWipFlag(false)
              setContactError({
                legalAddr: false,
                mailingAddr: false,
                mobile: false,
                email: false,
              })
              setClientAddress([])
              setMailingAddress({
                label: 'Select',
                value: '--',
                selectedValue: 'Select',
              })
              setButtonTextContact('save')
              setMobilePhone({
                label: 'Select',
                value: '--',
              })
              setEmailAddress({ label: 'Select', value: '--' })
              setOtherPhone({ label: 'Select', value: '--' })
              setBusinessPhone({ label: 'Select', value: 'Select' })
              setHomePhone({ label: 'Select', value: '--' })
              setFax({ label: 'Select', value: 'Select' })
              setRelation('')
              setAssEmpDropDown({ label: 'Select', value: '--' })
              setRegBrokerDropDown({ label: 'Select', value: '--' })
              setOwnedCompDropDown({ label: 'Select', value: '--' })
              setIndusAffRelation('')
              setEntity('')
              setCompanyDetails([{ company: '', symbol: '' }])
              setIsDashboardNavigate(true)
              if (
                data.t_WIP_Account.length > 0 &&
                data.t_WIP_Account[0].accttitle === 'Retirement'
              ) {
                setStageNames([
                  {
                    name: 'Account Information',
                    stage: 0,
                  },
                  {
                    name: 'Retirement',
                    stage: 1,
                  },
                  {
                    name: 'Client Documents',
                    stage: 2,
                  },
                  {
                    name: 'Review',
                    stage: 3,
                  },
                  {
                    name: 'Client Review',
                    stage: 4,
                  },
                  {
                    name: 'Final Review',
                    stage: 5,
                  },
                ])
              } else if (
                data.t_WIP_Account.length > 0 &&
                data.t_WIP_Account[0].accttitle === 'Trust'
              ) {
                setStageNames([
                  {
                    name: 'Account Information',
                    stage: 0,
                  },
                  {
                    name: 'Trust',
                    stage: 1,
                  },
                  {
                    name: 'Client Documents',
                    stage: 2,
                  },
                  {
                    name: 'Review',
                    stage: 3,
                  },
                  {
                    name: 'Client Review',
                    stage: 4,
                  },
                  {
                    name: 'Final Review',
                    stage: 5,
                  },
                ])
              } else {
                setStageNames([
                  {
                    name: 'Account Information',
                    stage: 0,
                  },
                  {
                    name: 'Client Documents',
                    stage: 1,
                  },
                  {
                    name: 'Review',
                    stage: 2,
                  },
                  {
                    name: 'Client Review',
                    stage: 3,
                  },
                  {
                    name: 'Final Review',
                    stage: 4,
                  },
                ])
              }
              const payload: GetWipRequestInterface = {
                appId: '1',
                wipId: resp.data.wipid,
              }
            })
            .catch(() => {
              setToast('error', 'Please try again')
              setCreateWipFlag(false)
              setAccountInfoLoader(false)
            })
        } else {
          setToast('error', 'Please fill all the details')
          const errors = {
            repCode: false,
            productType: false,
            subAccountType: false,
            nickname: false,
            client: false,
          }
          setCreateWipFlag(false)
          if (!data.t_WIP.repcode) {
            errors.repCode = true
            setShowError(errors)
          }
          if (
            data.t_WIP_Account.length > 0 &&
            data.t_WIP_Account[0].accounttypeid == 0
          ) {
            errors.productType = true
            setShowError(errors)
          }
          if (
            data.t_WIP_Account.length > 0 &&
            !data.t_WIP_Account[0].subaccounttypeid
          ) {
            errors.subAccountType = true
            setShowError(errors)
          }
          if (!data.t_WIP.applicationnickname) {
            errors.nickname = true
            setShowError(errors)
          }
          if (!selectedClient.clientId) {
            errors.client = true
            setShowError(errors)
          }
        }
      } else {
        setCreateWipFlag(false)
        setToast('error', 'User not found,please try again')
      }
    }
  }
  const getWipStatus = (value: string) => {
    switch (value) {
      case 'Digital Signature via Client Portal':
        return 7
      case 'Download Application':
        return 9
      case 'Upload Documents':
        return 8
      default:
        return 1
    }
  }

  const getButtonText = (value: string) => {
    switch (value) {
      case 'Digital Signature via Client Portal':
        setButtonText('Send')
        break
      case 'Download Application':
        setButtonText('Download')
        break
      case 'Upload Documents':
        setButtonText('Upload')
        break
      default:
        setButtonText('Send')
    }
  }
  const addDisclosureList = () => {
    const partyDetails: any[] = []
    if (newModelData) {
      if (newModelData.wipAccountDetailslist[0].parties) {
        newModelData.wipAccountDetailslist[0].parties.map((item) => {
          partyDetails.push({
            partyid: item.partyId,
            userid: item.partyname,
          })
        })
      }
    }
    const payload: AddDisclosureDashboard = {
      wipacctid: parseInt(wipId),
      partydetails: newModelData.wipAccountDetailslist[0].parties.map(
        (obj) => ({ partyid: obj.partyId, userid: obj.partyname })
      ),
      ipaddress: ' ',
      deviceid: ' ',
      signtimestamp: new Date().toISOString(),
      createdby: loggedUser,
      accttypeid:
        newModelData &&
        newModelData.wipAccountDetailslist &&
        Number(newModelData.wipAccountDetailslist[0].accounttypeid),
      acctsubtypeid:
        newModelData &&
        newModelData.wipAccountDetailslist &&
        Number(newModelData.wipAccountDetailslist[0].subaccounttypeid),
      lobid: newModelData?.wipDetails?.lobid ?? 0,
    }

    addDisclosureDashboard(payload)
      .then(() => {
        setLoader(false)
      })
      .catch(() => {
        setLoader(false)
        setToast('error', 'Error in sending application')
      })
  }
  const updateCards = () => {
    const value = { unsavedCards: cardsStatus }
    const payload: UpdateCardsRequestInterface = {
      wipid: parseInt(wipId),
      value: JSON.stringify(value),
      updatedby: loggedUser,
    }
    updateCardsStatus(payload)
      .then(() => {
        setLoader(false)
      })
      .catch(() => {
        setLoader(false)
        setToast('error', 'Error in updating status')
      })
  }

  const stageProgress = (stageValue: string, paramStages?: any) => {
    let updatedProgressList = stageNames
    if (paramStages) {
      updatedProgressList = paramStages
    }
    if (stageValue) {
      const navStage = updatedProgressList?.find(
        (obj: any) => obj.name == stageValue
      )
      if (navStage) {
        setStage(navStage.stage)
        setActiveStage(navStage.stage)
      }
    }
  }
  const statusCheck = (status: number) => {
    if (status == 7) {
      stageProgress('Client Review')
    } else if (status == 8 || status == 9) {
      let updatedProgressList = [
        {
          name: 'Account Information',
          stage: 0,
        },
        {
          name: 'Client Documents',
          stage: 1,
        },
        {
          name: 'Account Agreements',
          stage: 2,
        },
        {
          name: 'Review',
          stage: 3,
        },
        {
          name: 'Final Review',
          stage: 4,
        },
      ]
      if (
        newModelData?.wipAccountDetailslist?.length > 0 &&
        newModelData?.wipAccountDetailslist?.[0]?.accttitle === 'Retirement'
      ) {
        updatedProgressList = [
          {
            name: 'Account Information',
            stage: 0,
          },
          {
            name: 'Retirement',
            stage: 1,
          },
          {
            name: 'Client Documents',
            stage: 2,
          },
          {
            name: 'Account Agreements',
            stage: 3,
          },
          {
            name: 'Review',
            stage: 4,
          },
          {
            name: 'Final Review',
            stage: 5,
          },
        ]
      } else if (
        newModelData?.wipAccountDetailslist?.length > 0 &&
        newModelData?.wipAccountDetailslist?.[0]?.accttitle ===
          'Entity/Corporation'
      ) {
        updatedProgressList = [
          {
            name: 'Account Information',
            stage: 0,
          },
          {
            name: 'Entity/Corporation',
            stage: 1,
          },
          {
            name: 'Client Documents',
            stage: 2,
          },
          {
            name: 'Account Agreements',
            stage: 3,
          },
          {
            name: 'Review',
            stage: 4,
          },
          {
            name: 'Final Review',
            stage: 5,
          },
        ]
      } else if (
        newModelData?.wipAccountDetailslist?.length > 0 &&
        newModelData?.wipAccountDetailslist?.[0]?.accttitle === 'Trust'
      ) {
        updatedProgressList = [
          {
            name: 'Account Information',
            stage: 0,
          },
          {
            name: 'Trust',
            stage: 1,
          },
          {
            name: 'Client Documents',
            stage: 2,
          },
          {
            name: 'Account Agreements',
            stage: 3,
          },
          {
            name: 'Review',
            stage: 4,
          },
          {
            name: 'Final Review',
            stage: 5,
          },
        ]
      }
      setStageNames(updatedProgressList)
      stageProgress('Account Agreements', updatedProgressList)
    } else if (status === 6) {
      const updateCurrentStage = [
        {
          name: 'Account Information',
          stage: 0,
        },
        {
          name: 'Client Documents',
          stage: 1,
        },
        {
          name: 'Review',
          stage: 2,
        },
      ]
      setStageNames(updateCurrentStage)
      stageProgress('Review', updateCurrentStage)
    } else if (
      newModelData &&
      newModelData.wipAccountDetailslist &&
      newModelData.wipAccountDetailslist[0].accttitle === 'Retirement' &&
      status === 1
    ) {
      setStageNames([
        {
          name: 'Account Information',
          stage: 0,
        },
        {
          name: 'Retirement',
          stage: 1,
        },
        {
          name: 'Client Documents',
          stage: 2,
        },
        {
          name: 'Review',
          stage: 3,
        },
      ])
    } else if (
      newModelData &&
      newModelData.wipAccountDetailslist &&
      newModelData.wipAccountDetailslist[0].accttitle === 'Trust' &&
      status === 1
    ) {
      setStageNames([
        {
          name: 'Account Information',
          stage: 0,
        },
        {
          name: 'Trust',
          stage: 1,
        },
        {
          name: 'Client Documents',
          stage: 2,
        },
        {
          name: 'Review',
          stage: 3,
        },
      ])
    } else if (
      newModelData &&
      newModelData.wipAccountDetailslist &&
      newModelData.wipAccountDetailslist[0].accttitle ===
        'Entity/Corporation' &&
      status === 1
    ) {
      setStageNames([
        {
          name: 'Account Information',
          stage: 0,
        },
        {
          name: 'Entity/Corporation',
          stage: 1,
        },
        {
          name: 'Client Documents',
          stage: 2,
        },
        {
          name: 'Review',
          stage: 3,
        },
      ])
    }
    // else {
    //   setStage(0)
    //   setActiveStage(0)
    // }
  }

  const updateStatus = (action?: string) => {
    const payload: UpdateAppStatusRequestInterface = {
      wipid: parseInt(wipId),
      wipstatus: radioText ? getWipStatus(radioText) : 10,
    }
    setLoader(true)
    const partyDetails = newModelData.wipAccountDetailslist[0].parties.find(
      (party) => party.relationshipTypeId === 1
    )
    updateAppStatus(payload)
      .then(() => {
        if (modal) {
          if (radioText === 'Digital Signature via Client Portal') {
            const payload: CreateAccountRequestInterface = {
              requestId: parseInt(wipId),
              requestType: 'AOP',
              accountNumber: selectedAccount
                ? parseInt(selectedAccount.accountNumber)
                : 0,
              // signerId:
              //   newModelData?.wipAccountDetailslist[0].parties[0].partyId.toString(),
              // signerName:
              //   newModelData?.wipAccountDetailslist[0].parties[0].partyname,
              // signerEmail: contactInfoData?.partyEmail,
              signerId: partyDetails?.partyId.toString() || '',
              signerName: partyDetails?.partyname || '',
              signerEmail: contactInfoData.partyEmail
                ? contactInfoData.partyEmail
                : '',
              signerPhone: contactInfoData?.partyPhone
                ? contactInfoData.partyPhone
                : '',
              requestURL: '',
              expirtationDate: new Date().toISOString(),
              subject: 'Account Creation Request',
              messageDetails:
                'Verify the details and approve it for further actions.',
              signerType: '',
              createdBy: newModelData?.wipDetails?.advisor
                ? newModelData.wipDetails.advisor
                : loggedUser,
              updatedBy: loggedUser,
              approvalStatus: '7',
              deviceID: '',
              ipAddress: '',
              approvalTimestamp: new Date().toISOString(),
              requestStatus: 'Pending with Client',
              approvedBy: '',
              appId: 2,
            }
            createAccountRequest(payload)
              .then((res) => {
                setLoader(false)
                setCallUpdate(false)
                updateCards()
                if (
                  newModelData &&
                  newModelData.wipAccountDetailslist[0] &&
                  newModelData.wipAccountDetailslist[0].accttitle !== 'Joint'
                ) {
                  addDisclosureList()
                }
                navigate('/pendingPage')
              })
              .catch(() => {
                setLoader(false)
                setToast('error', 'Please try again')
              })
            if (
              newModelData &&
              newModelData.wipAccountDetailslist[0] &&
              newModelData.wipAccountDetailslist[0].accttitle === 'Joint'
            ) {
              const coApplicantDetails =
                newModelData?.wipAccountDetailslist[0]?.parties?.find(
                  (obj) => obj.relationshipTypeId == 2
                )

              const coPayload: CreateAccountRequestInterface = {
                requestId: parseInt(wipId),
                requestType: 'AOP',
                accountNumber: selectedAccount
                  ? parseInt(selectedAccount.accountNumber)
                  : 0,
                signerId: coApplicantDetails
                  ? coApplicantDetails.partyId.toString()
                  : '',
                signerName: coApplicantDetails?.partyname ?? '',
                signerEmail: contactInfoData.partyEmail
                  ? contactInfoData.partyEmail
                  : '',
                signerPhone: contactInfoData.partyPhone
                  ? contactInfoData.partyPhone
                  : '',
                requestURL: '',
                expirtationDate: new Date().toISOString(),
                subject: 'Account Creation Request',
                messageDetails:
                  'Verify the details and approve it for further actions.',
                signerType: '',
                createdBy: newModelData.wipDetails.advisor
                  ? newModelData.wipDetails.advisor
                  : loggedUser,
                updatedBy: loggedUser,
                approvalStatus: '7',
                deviceID: '',
                ipAddress: '',
                approvalTimestamp: new Date().toISOString(),
                requestStatus: 'Pending with Client',
                approvedBy: '',
                appId: 2,
              }
              createAccountRequest(coPayload)
                .then(() => {
                  setLoader(false)
                  setCallUpdate(false)
                  updateCards()
                  addDisclosureList()
                  navigate('/pendingPage')
                })
                .catch(() => {
                  setLoader(false)
                  setToast('error', 'Please try again')
                })
            }
          } else {
            setLoader(false)
            updateCards()
            if (action) {
              setIsUploadSend(true)
            }
          }
        } else {
          setLoader(false)
          navigate('/home')
        }
      })
      .catch(() => {
        setLoader(false)
        setToast('error', 'Please try again')
      })
  }
  const handleStage = () => {
    if (newModelData?.wipDetails?.wipstatusid !== 7 && stage === 4) {
      setStage(stage - 2)
      setActiveStage((prev) => prev - 2)
    } else {
      setStage(stage - 1)
      setActiveStage((prev) => prev - 1)
    }
  }
  const downloadPdf = () => {
    setDownload(true)
  }

  const getScreenWidth = window.innerWidth + 200 + 'px'

  const [radioOptions] = useState<OptionProps[]>([
    {
      label: 'Digital Signature via Client Portal',
      value:
        'Client will receive an email and notification in the client portal to review and approve the new account application.',
    },
    {
      label: 'Download Application',
      value:
        'PDF export of populated account agreement, required/optional forms and disclosures to printed and signed by applicants.Signed documents will need to be scanned and uploaded for account approval.',
    },
    {
      label: 'Upload Documents',
      value: 'Upload signed documents for approval.',
    },
  ])

  const getFooterButtons = (stageName: string, wipStatus: number) => {
    let button = ''
    if (stageName === 'Review' && (wipStatus === 1 || wipStatus === 6)) {
      return (button = 'Send Application')
    } else if (stageName === 'Client Review' && wipStatus === 7) {
      button = ''
      return button
    } else if (stageName === 'Final Review') {
      button = 'Submit for Approval'
      return button
    } else {
      button = stageNames[stage + 1]?.name
      return button
    }
  }
  const checkButtonDisable = () => {
    const isPrimaryFile = uploadedFiles.filter(
      (item) => !item.iscoapplicant && !item.isdisclosure
    )
    const isCoApplicantFile = uploadedFiles.filter(
      (item) => item.iscoapplicant && !item.isdisclosure
    )
    if (newModelData?.wipAccountDetailslist?.[0].accttitle === 'Joint') {
      if (isPrimaryFile.length > 0 && isCoApplicantFile.length > 0) return false
      else return true
    } else if (isPrimaryFile.length > 0) {
      return false
    } else return true
  }

  const handleClick = () => {
    if (
      stageNames[stage].name === 'Review' &&
      (newModelData?.wipDetails?.wipstatusid === 6 ||
        newModelData?.wipDetails?.wipstatusid === 1)
    ) {
      setModal(true)
    } else if (stageNames[stage].name === 'Account Information') {
      saveAll()
    } else if (stageNames[stage].name === 'Final Review') {
      updateStatus()
    }
    // else if (
    //   (stageNames[stage].name === 'Client Documents' ||
    //     stageNames[stage].name === 'Account Agreements') &&
    //   getWipResponse?.wipDTO?.wipstatus === '8' &&
    //   checkButtonDisable()
    // ) {
    //   setToast(
    //     'error',
    //     'Please upload at least one proof of verification documents before proceeding to disclosures'
    //   )
    // }
    else {
      setStage(stage + 1)
      setActiveStage(stage + 1)
    }
  }

  const handleWorkFlow = (buttonText: string) => {
    switch (buttonText) {
      case 'Send':
        {
          if (
            stageNames[stage].name === 'Review' &&
            (newModelData?.wipDetails?.wipstatusid == 6 ||
              newModelData?.wipDetails?.wipstatusid == 1)
          ) {
            updateStatus()
          }
        }
        break
      case 'Download': {
        if (
          stageNames[stage].name === 'Review' &&
          (newModelData?.wipDetails?.wipstatusid == 6 ||
            newModelData?.wipDetails?.wipstatusid == 1)
        ) {
          getPdfTemplate({ wipid: wipId })
            .then(async (res) => {
              const blob = await new Blob([res.data], {
                type: 'application/pdf',
              })
              const url = await URL.createObjectURL(blob)

              const link = await document.createElement('a')
              link.href = await url
              link.download = `${newModelData?.wipAccountDetailslist?.[0]?.wipid}-New Account Agreement.pdf` // Set the desired file name
              link.click()

              // Clean up the URL object after download
              await URL.revokeObjectURL(url)

              updateStatus('download')
            })
            .catch(() => {
              setToast('error', 'Error in downloading pdf')
            })
        }
        break
      }
      case 'Upload': {
        if (
          stageNames[stage].name === 'Review' &&
          (newModelData?.wipDetails?.wipstatusid == 6 ||
            newModelData?.wipDetails?.wipstatusid == 1)
        ) {
          updateStatus('upload')
        }
        break
      }
    }
  }

  const gotoDashboard = () => {
    navigate('/home')
  }

  const [rejMessage, setRejMessage] = useState('')
  const handleMessage = (e: string) => {
    setRejMessage(e)
  }

  useEffect(() => {
    if ((Object.keys(newModelData).length === 0 && wipId) || isUploadSend) {
      const payload: GetWipRequestInterface = {
        appId: '1',
        wipId: parseInt(wipId),
      }
      setIsUploadSend(false)
      setSpinner(true)
      getWipDetails(payload)
        .then((getWipRecord) => {
          setSpinner(false)
          setWipFormData((prevState: CreateWipRequestInterface) => {
            return {
              ...prevState,
              repCode: getWipRecord.data.wipDetails?.repcode,
              applicationNickname:
                getWipRecord.data.wipDetails?.applicationnickname,
              partyId:
                getWipRecord?.data?.wipAccountDetailslist?.[0]?.parties?.filter(
                  (obj: any) => obj?.relationshipTypeId === 1
                )[0]?.partyId,
              advisor: getWipRecord.data.wipDetails?.advisor,
            }
          })

          setNewModelData(getWipRecord.data)
          setSelectedClient((prev: ClientSearchByNameResponseInterface) => {
            return {
              ...prev,
              clientId:
                getWipRecord?.data?.wipAccountDetailslist?.[0]?.parties?.filter(
                  (obj: any) => obj?.relationshipTypeId === 1
                )[0]?.partyId,
            }
          })
          statusCheck(getWipRecord?.data?.wipDetails?.wipstatusid)
          const getTrustData =
            getWipRecord?.data?.wipAccountDetailslist?.[0]?.parties.find(
              (party: any) => party.relationshipTypeId === 3
            )
          if (getTrustData && Object.keys(getTrustData).length >= 0) {
            setSelectedCheck(false)
            const obj: PartyDetailsProps = JSON.parse(getTrustData.partydetails)
            //
            const partyDemoGraphic: PartyDemographicInterface =
              obj.IndividualDetails.partyDemographic
            const partyData: IndividualClientResponseInterface = {
              firstName: obj.IndividualDetails.firstName,
              lastName: obj.IndividualDetails.lastName,
              partyId: getTrustData.partyId,
              middleName: obj.IndividualDetails.middleName,
              partyTypeId: 0,
              prefix: obj.IndividualDetails.prefix,
              suffix: obj.IndividualDetails.suffix,
              fullName: obj.IndividualDetails.fullName,
              salutation: '',
              socialSecurityNumber: obj.IndividualDetails.socialSecurityNumber,
              updateBy: null,
              partyDemographic: partyDemoGraphic,
              clientIds: [],
            }

            setRelation(getTrustData.trustedcontactrelationship)
            setTrustedParty(partyData)
          } else {
            setSelectedCheck(true)
          }
        })
        .catch((error) => {
          console.log('error', error)
        })
    } else {
      statusCheck(newModelData.wipDetails?.wipstatusid)
    }
  }, [newModelData, wipId, isUploadSend])

  useEffect(() => {
    if (wipId) {
      const payload: GetApprovalRequest = {
        wipid: parseInt(wipId),
      }
      getCardsStatus(payload)
        .then((res: GetCardsResponseInterface) => {
          setCardsStatus(JSON.parse(res.value).unsavedCards)
        })
        .catch(() => {
          setToast('error', 'Unable to fetch status')
        })
    }
  }, [wipId])

  // useEffect(() => {
  //   if (cardsStatus) {
  //     if (
  //       cardsStatus.includes('docs')
  //       && cardsStatus.includes('accountInvestment')
  //       && !cardsStatus.includes('contact')
  //       && cardsStatus.includes('industry')
  //       && cardsStatus.includes('employment')
  //       && !cardsStatus.includes('funding')
  //       && !cardsStatus.includes('electronic')
  //       && !cardsStatus.includes('trusted')
  //       && !cardsStatus.includes('investment')
  //       && !cardsStatus.includes('productFeatures')
  //     ) {
  //       const Stage = stageNames.filter((item: any) => item.name === 'Documents')[0].stage
  //       setStage(Stage)
  //       setActiveStage(Stage)
  //     }
  //     else {
  //       const Stage = stageNames.filter((item: any) => item.name === 'Account Information')[0].stage
  //       setStage(Stage)
  //       setActiveStage(Stage)
  //     }

  //   }

  // }, [cardsStatus])
  const validatePendingDocStepper = () => {
    if (
      stageNames[stage].name === 'Client Documents' &&
      newModelData?.wipDetails?.wipstatusid === 8
    ) {
      return false
    }
    return true
  }
  const handleSaveFun = () => {
    autoSaveFunction().then((res) => {
      if (res === 200) {
        const payload: GetWipRequestInterface = {
          appId: '1',
          wipId: parseInt(wipId),
        }
        getWipDetails(payload)
          .then((getWipRecord) => {
            setNewModelData(getWipRecord.data)
            setSaveFlag(true)
          })
          .catch((error) => {
            console.log('error', error)
          })
      }
    })
  }
  const disableConditionAgreements = () => {
    if (
      stageNames[stage].name === 'Account Agreements' &&
      newModelData?.wipDetails?.wipstatusid === 8 &&
      !disableCondition()
    ) {
      return true
    } else if (
      stageNames[stage].name === 'Client Documents' &&
      newModelData?.wipDetails?.wipstatusid === 8 &&
      !disableConditionDocuments()
    ) {
      return true
    }
    return false
  }
  return (
    <>
      {spinner ? (
        <SpinnerWrapper>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
        </SpinnerWrapper>
      ) : (
        <GridAreas id={!wipId ? 'new_page' : 'page'}>
          {modal && (
            <MasterWrapperModel>
              <OverlayWrapper>
                <ModalInfoWrapper>
                  <ModalContentWrapper>
                    <ContentInfoWrapper>
                      <WDLabelSemiBold>
                        <Label color="#3377ff">
                          {'Please choose an option'}
                        </Label>
                      </WDLabelSemiBold>
                      <RadioInfoWrapper>
                        {radioOptions.map((radio, index) => (
                          <RadioOptionInfoWrapper key={radio.label}>
                            <Radio
                              checkBoxId={`radio_${index}`}
                              title={radio.label}
                              name={'Send Application'}
                              value={radioText}
                              checked={radio.label === radioText}
                              onChange={() => {
                                setRadioText(radio.label)
                                getButtonText(radio.label)
                              }}
                              isBoldTitle={true}
                            />
                            <LabelWrapper>
                              <WDLabel>
                                <Label fontSize="12px">{radio.value}</Label>
                              </WDLabel>
                            </LabelWrapper>
                          </RadioOptionInfoWrapper>
                        ))}
                      </RadioInfoWrapper>
                      <ButtonWrapper>
                        <WDButton>
                          <Button
                            type={'button'}
                            onClick={() => {
                              handleWorkFlow(buttonText)
                              setModal(false)
                            }}
                          >
                            {buttonText}
                          </Button>
                        </WDButton>
                        <WDButtonNeutral>
                          <Button
                            type={'button'}
                            onClick={() => {
                              setModal(false)
                            }}
                          >
                            {'Cancel'}
                          </Button>
                        </WDButtonNeutral>
                      </ButtonWrapper>
                    </ContentInfoWrapper>
                  </ModalContentWrapper>
                </ModalInfoWrapper>
              </OverlayWrapper>
            </MasterWrapperModel>
          )}
          {isRecalled && (
            <MasterWrapperModel>
              <OverlayWrapper>
                <ModalWrapper>
                  <ModalContentWrapper>
                    <SvgYellowWarning fillColor={COLORS.Background.Warning} />
                    <ContentWrapper>
                      <WDLabelBlackSemiBold className="label_responsive">
                        <Label>
                          {
                            'Client approvals will be removed and  they will no longer be able to update the WIP.​'
                          }
                        </Label>
                      </WDLabelBlackSemiBold>
                      <ButtonWrapper>
                        <WDButton>
                          <Button
                            type={'button'}
                            onClick={() => {
                              setSaveRecall(true)
                              setIsRecalled(false)
                            }}
                          >
                            {'Recall'}
                          </Button>
                        </WDButton>
                        <WDButtonDisabled>
                          <Button
                            type={'button'}
                            onClick={() => {
                              setIsRecalled(false)
                            }}
                          >
                            {'Cancel'}
                          </Button>
                        </WDButtonDisabled>
                      </ButtonWrapper>
                    </ContentWrapper>
                  </ModalContentWrapper>
                </ModalWrapper>
              </OverlayWrapper>
            </MasterWrapperModel>
          )}
          {showComment && (
            <MasterWrapperModel>
              <OverlayWrapper>
                <CommentModalWrapper>
                  <ModalContentWrapper>
                    <ContentWrapper>
                      <Textarea
                        onChange={handleMessage}
                        value={rejMessage}
                        placeholder={'Add your comments here'}
                        required
                      ></Textarea>
                      <ButtonWrapper>
                        <WDButton>
                          <Button
                            type={'button'}
                            onClick={() => {
                              setShowComment(false)
                            }}
                          >
                            {commentButton}
                          </Button>
                        </WDButton>
                        <WDButtonDisabled>
                          <Button
                            type={'button'}
                            onClick={() => {
                              setShowComment(false)
                            }}
                          >
                            {'Cancel'}
                          </Button>
                        </WDButtonDisabled>
                      </ButtonWrapper>
                    </ContentWrapper>
                  </ModalContentWrapper>
                </CommentModalWrapper>
              </OverlayWrapper>
            </MasterWrapperModel>
          )}
          <NavHeaderWrapper>
            <ContentHeader
              stage={stage}
              downloadPdf={downloadPdf}
              source={'new'}
            />
            {wipId && <ProgressBar stage={stage} stageCallback={setStage} />}
          </NavHeaderWrapper>
          <GridContainerNew
            id={stage === 4 ? 'approvalGrid' : 'gridContainerNew'}
          >
            {toggleErrorToast && (
              <ErrorToastWrapper>
                <ItemWrapper sm={6} md={4} lg={3} id="full_col">
                  <ErrorToast
                    ErrorTitle={
                      'Please fix errors on your Account Information step'
                    }
                  />
                </ItemWrapper>
              </ErrorToastWrapper>
            )}

            {accessList.accountInfo &&
              stageNames[stage].name == 'Account Information' && (
                <AccountInformation stage={stage} setStage={setStage} />
              )}
            {stageNames[stage].name == 'Retirement' && (
              <Retirement stage={stage} setStage={setStage} />
            )}
            {stageNames[stage].name == 'Trust' && (
              <TrustForm stage={stage} setStage={setStage} />
            )}
            {stageNames[stage].name == 'Entity/Corporation' && (
              <EntityCorp stage={stage} setStage={setStage} />
            )}
            {accessList.review && stageNames[stage].name == 'Review' && (
              <ReviewInformation stage={stage} setStage={setStage} />
            )}
            {accessList.review &&
              stageNames[stage].name == 'Review' &&
              download &&
              templateData.length > 0 && (
                <div
                  style={{
                    position: 'absolute',
                    left: getScreenWidth.toString(),
                  }}
                  ref={pdfRef}
                  id={'pdf_title'}
                >
                  <ReviewTemplate
                    downloadPdf={download}
                    downloadCallback={(val) => setDownload(val)}
                    updateStatusCallback={updateStatus}
                    callUpdate={callUpdate}
                    contentData={templateData}
                  />
                </div>
              )}
            {stageNames[stage].name == 'Client Review' && (
              <ClientReviewInformation
                saveRecall={saveRecall}
                resetSave={(val: boolean) => {
                  setSaveRecall(val)
                }}
                setIsUploadSend={(val: boolean) => {
                  setIsUploadSend(val)
                }}
              />
            )}
            {stageNames[stage].name == 'Final Review' && (
              <FinalReviewInformation
                saveRecall={saveRecall}
                resetSave={(val: boolean) => {
                  setSaveRecall(val)
                }}
                setIsUploadSend={(val: boolean) => {
                  setIsUploadSend(val)
                }}
              />
            )}
            {accessList.documents &&
              stageNames[stage].name == 'Client Documents' && (
                <ItemWrapper sm={6} md={4} lg={3} id="doc_scr">
                  <FormDocuments stage={stage} setStage={setStage} />
                </ItemWrapper>
              )}
            {stageNames[stage].name == 'Account Agreements' && (
              <ItemWrapper sm={6} md={4} lg={3} id="doc_scr">
                <ManualDisclosures stage={stage} setStage={setStage} />
              </ItemWrapper>
            )}
          </GridContainerNew>

          <FooterWrapper>
            {wipId ? (
              <AccountFooterButtonGroup className="footer_group">
                <AccountInfoBtnWrapper>
                  {stageNames[stage].name == 'Account Information' &&
                    accessList.productFeatures &&
                    accessList.contactInfo &&
                    accessList.funding &&
                    accessList.invProfile &&
                    accessList.elecPreferences &&
                    accessList.trustedContact &&
                    !readOnly && (
                      // newModelData?.wipDetails?.wipstatusid == 7 &&

                      <WDButtonOutLine>
                        <Button
                          type={'button'}
                          onClick={() => {
                            handleSaveFun()
                          }}
                        >
                          <WDLabelPrimaryText>{'Save'}</WDLabelPrimaryText>
                        </Button>
                      </WDButtonOutLine>
                    )}
                  {stageNames[stage].name == 'Client Review' &&
                    newModelData?.wipDetails?.wipstatusid == 7 &&
                    accessList.recall && (
                      <WDButtonOutLine>
                        <Button
                          type={'button'}
                          onClick={() => {                           
                            setIsRecalled(true)
                          }}
                          prefixedIcon={
                            <SvgChevronLeftSmall
                              fillColor={COLORS.Icons.Primary}
                            />
                          }
                        >
                          <WDLabelPrimaryText>{'Recall'}</WDLabelPrimaryText>
                        </Button>
                      </WDButtonOutLine>
                    )}
                  {stageNames[stage].name == 'Final Review' && (
                    <WDButtonOutLine>
                      <Button
                        type={'button'}
                        onClick={() => {
                          setIsRecalled(true)
                        }}
                        prefixedIcon={
                          <SvgChevronLeftSmall
                            fillColor={COLORS.Icons.Primary}
                          />
                        }
                      >
                        <WDLabelPrimaryText>{'Recall'}</WDLabelPrimaryText>
                      </Button>
                    </WDButtonOutLine>
                  )}
                  {stage !== 0 &&
                    stageNames[stage].name !== 'Client Review' &&
                    stageNames[stage].name !== 'Final Review' &&
                    validatePendingDocStepper() && (
                      <WDButtonOutLine>
                        <Button
                          type={'button'}
                          onClick={handleStage}
                          prefixedIcon={
                            <SvgChevronLeftSmall
                              fillColor={COLORS.Icons.Primary}
                            />
                          }
                        >
                          <WDLabelPrimaryText>
                            {newModelData?.wipDetails?.wipstatusid !== 7
                              ? stageNames[stage - 1].name
                              : stageNames[stage].name}
                          </WDLabelPrimaryText>
                        </Button>
                      </WDButtonOutLine>
                    )}
                  {stageNames[stage].name !== 'Client Review' && (
                    <WDButton>
                      <Button
                        type={'button'}
                        onClick={handleClick}
                        suffixedIcon={
                          <SvgChevronRightSmall
                            fillColor={COLORS.Icons.NeutralInverted}
                          />
                        }
                        disabled={disableConditionAgreements()}
                      >
                        {getFooterButtons(
                          stageNames?.[stage]?.name,
                          newModelData?.wipDetails?.wipstatusid
                        )}
                      </Button>
                    </WDButton>
                  )}
                </AccountInfoBtnWrapper>
              </AccountFooterButtonGroup>
            ) : (
              <CreateWipFooterGroup className="footer_group">
                <AccountInfoBtnWrapper>
                  <WDButton>
                    <Button
                      type={'button'}
                      //ssn condition for create wip
                      // disabled={
                      //   primaryClient?.clientIds?.filter(
                      //     (id) => id.idType === 'US Social Security'
                      //   )?.[0]?.idNumber.length != 9
                      // }
                      onClick={() => {
                        createWip()
                      }}
                    >
                      {'Create WIP'}
                    </Button>
                  </WDButton>
                  <Divider
                    horizontal={false}
                    thickness={'0.1rem'}
                    bgColor={COLORS.Text.Brand2}
                  />
                  <WDButtonOutLine>
                    <Button type={'button'} onClick={() => gotoDashboard()}>
                      <WDLabelPrimaryText>{'Cancel'}</WDLabelPrimaryText>
                    </Button>
                  </WDButtonOutLine>
                </AccountInfoBtnWrapper>
              </CreateWipFooterGroup>
            )}
          </FooterWrapper>
        </GridAreas>
      )}
    </>
  )
}

const NewGrid = () => {
  return (
    <LandingPageLayout>
      <AccountApplicationContent />
    </LandingPageLayout>
  )
}

export default NewGrid
