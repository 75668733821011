import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'

export const RootContainer = styled.div`
  position: fixed;
  display: grid;
  grid-auto-flow: row;
  width: 100%;
  height: 3rem;
  background-color: ${COLORS.Background.White};
  z-index: 999;
  padding: 0 15px;
  box-shadow: 0px 3px 6px -2px ${COLORS.BoxShadow.Shadow1};
`
export const NavContainer = styled.nav`
  display: grid;
  grid-auto-flow: column;
  z-index: 99;
`
export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 0 1 70%;
  color: ${COLORS.Text.Brand3};
  font-weight: bolder;
  font-size: 22px;
  gap: 0.5rem;
  cursor: pointer;
`
export const NewAccountWrapper = styled.div`
  display: flex;
  flex: 0 1 30%;
  align-items: center;
  justify-content: flex-end;
`
export const MenuWrapper = styled.div`
  display: flex;
  flex: 0 1 90%;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  padding: 0 1.5rem;
`
export const RightNavWrapper = styled.div`
  display: flex;
  flex: 0 1 20%;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
`
export const UserDetailsWrapper = styled.div`
  display: flex;
  flex: 0 1 20%;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  position: relative;
`
export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  padding: 0.5rem;
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 2rem;
  background-color: #cdefc4;
  color: ${COLORS.Text.NeutralText};
  cursor: default;
  &#secondary {
    background-color: ${COLORS.Background.OffWhite7};
  }
`
export const UserActionWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 2.75rem;
  z-index: 999;
  background-color: ${COLORS.Background.White};
  color: ${COLORS.Text.NeutralText};
  box-shadow: 0px 2px 6px -2px ${COLORS.BoxShadow.Shadow2};
  border-radius: 0.5rem;
  width: 8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`
export const DashboardButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 600;
    padding: 0.25rem 1rem;
    height: 2rem;
  }
`
export const CreateButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
`
export const WipLabel = styled.div`
  align-content: center;
  justify-content: center;
  display: flex;
  width: 5rem;
  /* background-color: grey; */
  padding: 0.1rem;
  border-radius: 3px;
  border: 1px solid #2563eb;
  font-size: 0.875rem;
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
`
export const SearchWrapper = styled.div`
  display: grid;
  align-content: center;
  justify-content: center;
  grid-auto-flow: column;
  gap: 0.25rem;
  input {
    width: 100%;
    border-radius: 0.5rem;
  }
  button {
    border-top-left-radius: 0rem;
    border-bottom-left-radius: 0rem;
  }
`
export const TextBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 85%;
  position: relative;
  height: 2rem;
  //gap: 1rem;
  img {
    position: absolute;
    left: 0.5rem;
  }
  .input {
    position: absolute;
  }
`
