import Lottie from 'lottie-react'
import React, {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import Loader from '../../../../../../assets/lottiefiles/loader.json'
import Button from '../../../../../../components/button/Button'
import Divider from '../../../../../../components/divider/Divider'
import Label from '../../../../../../components/label/Label'
import {
  SvgChevronDownMedium,
  SvgChevronUpMedium,
} from '../../../../../../components/svg/SvgChevron'
import SvgEye from '../../../../../../components/svg/SvgEye'
import { SvgNoVisible } from '../../../../../../components/svg/SvgNoVisible'
import SvgSearch from '../../../../../../components/svg/SvgSearch'
import { Textbox } from '../../../../../../components/textbox/Textbox'
import {
  WDButtonDisabled,
  WDButtonPrimaryOutlined,
  WDButtonTransparent,
} from '../../../../../../components/ui/WDButton'
import {
  WDLabel,
  WDLabelClient,
  WDLabelError,
  WDLabelPrimaryTextSmall,
  WDLabelSemiBold,
} from '../../../../../../components/ui/WDLabel'
import { WDTextboxBorderRadius } from '../../../../../../components/ui/WDTextbox'
import { ResponsiveWrapper } from '../../../../../../pages/accountApplicationPage/styles'
import { ApplicationInfoContext } from '../../../../../../services/applicationContext/ApplicationPageContext'
import { AuthContext } from '../../../../../../services/AuthContext'
import {
  IGovtIdsInterface,
  PartyData,
} from '../../../../../../services/clientAndAccountSearch/IClientAndAccountSearchContext'
import ToastContext from '../../../../../../services/toast/ToastContext'
import { COLORS } from '../../../../../../theme/Colors'
import { ClientSearch } from '../../../../../clientSearch/ClientSearch'
import { LottieWrapper } from '../../../../../interceptor/styles'
import { UserWrapper } from '../../../../../navbar/styles'
import { StyledDocumentPanel } from '../../../../accountInformation/styles'
import { WipHeadingWrapper } from '../../../../contactInfo/styles'
import { PartyDetails, PartyModel } from '../../../../formWip/IFormWip'
import { SpinnerWrapper } from '../ProductFeatures/style'
import RenderApplicantInfo from './RenderApplicantInfo'
import {
  AppInfoWrapper,
  BtnWrap,
  ClientSearchCard,
  ClientSearchWrapper,
  ContentWrapper,
  DividerWrapper,
  EditWrapper,
  HeaderWrapper,
  ItemListChildWrapper,
  ItemsListWrapper,
  ParentInfoWrapper,
  PersonalInfoWrapper,
  SearchWrapper,
  StyledButtonTransparent,
  TabWrapper,
  TitleGrouping,
  ToolTipHoverWrapper,
  ToolTipParentWrapper,
} from './styles'
import appConfig from '../../../../../../settings/appConfig'

const ApplicantInfoPage = (props: {
  pdfNodeRef?: (pdfCanvasElement: HTMLCanvasElement) => HTMLCanvasElement
  className?: string
}) => {
  const { accessToken, loggedUser } = useContext(AuthContext)
  const [partyData, setPartyData] = useState(PartyData)
  const wrapperRef = useRef(null)
  const [searchData, setSearchData] = useState<string>('')
  const [visibility, setVisibility] = useState(false)
  const [toggleHeader, setToggleHeader] = useState(true)
  const [loaderIcon, setLoaderIcon] = useState(false)
  const [viewMore, setViewMore] = useState(false)
  const [pAvatar, setPAvatar] = useState('PA')
  const [sAvatar, setSAvatar] = useState('CA')
  const [primary, setPrimary] = useState(true)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [secondary, setSecondary] = useState(false)
  const [openSearchClientPopup, setOpenSearchClientPopup] =
    useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<number>(1)
  const {
    selectedClient,
    getIndividualPartyData,
    individualPartyData,
    setIndividualPartyData,
    secondaryPartyData,
    setSecondaryPartyData,
    primaryClient,
    setPrimaryClient,
    setInvProfileDetails,
    secondaryClient,
    setSecondaryClient,
    getWipResponse,
    newModelData,
    wipId,
    readOnly,
    selectedAccount,
    selectedSubAccount,
    isDashboardNavigate,
    setAllClientData,
    showError,
    setShowError,
    productInfoFieldCheck,
    setProductInfoFieldCheck,
    wipFormData,
    setWipFormData,
    relationList,
    setRelationList,
    accountTypes,
    accountType,
    primaryGovtIds,
    setPrimaryGovtIds,
    secondaryGovtIds,
    setSecondaryGovtIds,
    primaryInvProfileDetails,
    setPrimaryInvProfileDetails,
    secondaryInvProfileDetails,
    setSecondaryInvProfileDetails,
  } = useContext(ApplicationInfoContext)
  const getUrl = () => {
    const getEnvName = appConfig.envName
    if (getEnvName?.toLowerCase().includes('dev'))
      return 'https://clientmaintenance.dev.wedbush.com'
    else if (getEnvName?.toLowerCase().includes('uat'))
      return 'https://clientmaintenance-uat.azurewebsites.net'
    else if (getEnvName?.toLowerCase().includes('prod'))
      return 'https://clientmaintenance.azurewebsites.net'
    else return 'https://clientmaintenance.dev.wedbush.com'
  }

  const goToClientMaintenance = () => {
    if (selectedClient.clientId) {
      const concatEnvUrl = getUrl()
      window.open(`${concatEnvUrl}?clientID=${partyData.partyId}`)
      // window.open(`http://localhost:3000?clientID=${partyData.partyId}`)
    }
  }
  const { setToast } = useContext(ToastContext)
  const [govtIds, setGovtIds] = useState({} as IGovtIdsInterface)

  const maskSSN = (value: string) => {
    if (value) {
      return value.slice(-4)
    }
    return ''
  }
  const formatSSN = (value: string) => {
    const first = value.substring(0, 3)
    const second = value.substring(3, 5)
    const third = value.substring(5)
    return first + '-' + second + '-' + third
  }
  useEffect(
    () => {
      if (!isDashboardNavigate) {
        setLoaderIcon(true)
        getIndividualPartyData({ partyId: selectedClient.clientId })
          .then((data: any) => {
            if (
              data.clientIds?.filter((id: any) => {
                id.idType === 'US Social Security' && id.idNumber.length == 9
              })
            ) {
              setLoaderIcon(false)
              const tempData = {
                ...data?.partyDemographic,
                partyId: data?.partyId,
                partyTypeId: data?.partyTypeId,
                prefix: data?.prefix,
                firstName: data?.firstName,
                middleName: data?.middleName,
                lastName: data?.lastName,
                suffix: data?.suffix,
                fullName: data?.fullName,
                salutation: data?.salutation,
                socialSecurityNumber: data?.socialSecurityNumber,
              }
              const tempClientIds = {
                expirydate: '',
                number: '',
                type: '',
                issuedate: '',
              }
              tempClientIds.type = data?.clientIds?.[0]?.idType
              tempClientIds.number = data?.clientIds?.[0]?.idNumber
              tempClientIds.issuedate = data?.clientIds?.[0]?.issueDate
              tempClientIds.expirydate = data?.clientIds?.[0]?.expiryDate

              if (primary) {
                if(tempData.partyId !== secondaryClient.partyId){
                setPrimaryClient(tempData)
                setPrimaryGovtIds(tempClientIds)
              } else {
                setToast('error', 'Cannot select the same client as primary and secondary')
              }
              } else {
                if (tempData.partyId !== primaryClient.partyId) {
                  setSecondaryClient(tempData)
                  setSecondaryGovtIds(tempClientIds)
                } else {
                  setToast('error', 'Cannot select the same client as primary and secondary')
                }
              }
            } else {
              setLoaderIcon(false)
              //setToast('error', 'Invalid SSN')
            }
          })
          .catch(() => setLoaderIcon(false))
      } else if (
        isDashboardNavigate &&
        newModelData?.wipAccountDetailslist &&
        newModelData?.wipAccountDetailslist.length > 0
      ) {
        setLoaderIcon(true)
        // const currSelectedClient =
        //   newModelData?.wipAccountDetailslist?.[0]?.parties.find(
        //     (obj) => obj.relationshipTypeId === selectedTab
        //   )
        setLoaderIcon(false)
        // if (!currSelectedClient) return
        // const getApplicant = currSelectedClient?.partydetails
        // if (getApplicant) {
        //   const parseData = JSON.parse(getApplicant)
        //   if (primary) {
        //     setPrimaryClient(parseData.IndividualDetails)
        //   }
        //   if (parseData) {
        //     setAllClientData({
        //       partyid: currSelectedClient?.partyId,
        //       wipacctid: currSelectedClient?.wipacctid,
        //       value: parseData,
        //     })
        //   }
        // }
        newModelData?.wipAccountDetailslist?.[0]?.parties.map((item, index) => {
          if (index === 0) {
            const getApplicant = item?.partydetails
            const parseData = JSON.parse(getApplicant)
            setPrimaryClient(parseData?.IndividualDetails)
            setPrimaryInvProfileDetails(parseData?.InvestmentDetails)
            setPrimaryGovtIds(parseData?.GovernmentID)
          } else {
            const getApplicant = item?.partydetails
            const parseData = JSON.parse(getApplicant)
            setSecondaryClient(parseData?.IndividualDetails)
            setSecondaryInvProfileDetails(parseData?.InvestmentDetails)
            setSecondaryGovtIds(parseData?.GovernmentID)
          }
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedClient, newModelData, selectedTab]
  )

  const getTitleByOwnerType = (
    relationid: any,
    ownerType: any,
    existingName: any
  ) => {
    if (ownerType === 2) {
      if (relationid === 1) return 'Primary Applicant Minor'
      else if (relationid === 2) return 'Co-Applicant Custodian'
    } else if (ownerType === 3) {
      if (relationid === 1) return existingName?.relationshipname ?? ''
      else if (relationid === 2) return 'Co-Applicant Authorized Individual'
    } else {
      return existingName?.relationshipname ?? 'Primary Applicant'
    }
  }

  useEffect(() => {
    if (primary) {
      setIndividualPartyData(primaryClient)
      setInvProfileDetails(primaryInvProfileDetails)
      setGovtIds({ ...primaryGovtIds })
      if (
        wipFormData?.t_WIP_Account?.[0] &&
        wipFormData?.t_WIP_Account?.[0]?.t_Party_Role &&
        wipFormData?.t_WIP_Account?.[0]?.t_Party_Role.length > 0 &&
        primaryClient?.partyId
      ) {
        wipFormData.t_WIP_Account[0].t_Party_Role[0].partyid =
          primaryClient?.partyId ?? ''
        wipFormData.t_WIP_Account[0].t_Party_Role[0].partydetails = null
        wipFormData.t_WIP_Account[0].t_Party_Role[0].firstname =
          primaryClient?.firstName
        wipFormData.t_WIP_Account[0].t_Party_Role[0].lastname =
          primaryClient?.lastName
        if (wipFormData?.t_WIP_Account?.[0]?.accttitle === 'Retirement') {
          wipFormData.t_WIP_Account[0].t_Party_Role[0].relationshipToPrimaryOwner =
            getTitleByOwnerType(
              wipFormData?.t_WIP_Account[0]?.t_Party_Role?.[0]
                ?.relationshipTypeId,
              wipFormData?.t_WIP?.iraaccountownertypeid,
              accountTypes?.accountTypeDetails?.find(
                (obj) =>
                  obj.relationshiptypeid ===
                    wipFormData?.t_WIP_Account[0]?.t_Party_Role?.[0]
                      ?.relationshipTypeId &&
                  obj.accttypeid === Number(accountType.value)
              )
            )
        } else {
          wipFormData.t_WIP_Account[0].t_Party_Role[0].relationshipToPrimaryOwner =
            accountTypes?.accountTypeDetails?.find(
              (obj) =>
                obj.relationshiptypeid ===
                  wipFormData?.t_WIP_Account[0]?.t_Party_Role?.[0]
                    ?.relationshipTypeId &&
                obj.accttypeid === Number(accountType.value)
            )?.relationshipname ?? null
        }

        setWipFormData(wipFormData)
      }

      if (relationList.length > 0) {
        relationList[0].clientDetails = primaryClient
        setRelationList(relationList)
      }
    } else if (secondary) {
      setSecondaryPartyData(secondaryClient)
      setInvProfileDetails(secondaryInvProfileDetails)
      setGovtIds({ ...secondaryGovtIds })
      if (
        wipFormData?.t_WIP_Account?.[0] &&
        wipFormData?.t_WIP_Account?.[0]?.t_Party_Role &&
        wipFormData?.t_WIP_Account?.[0]?.t_Party_Role.length > 1 &&
        secondaryClient?.partyId
      ) {
        wipFormData.t_WIP_Account[0].t_Party_Role[1].partyid =
          secondaryClient?.partyId ?? ''
        wipFormData.t_WIP_Account[0].t_Party_Role[1].partydetails = null
        wipFormData.t_WIP_Account[0].t_Party_Role[1].firstname =
          secondaryClient?.firstName ?? ''
        wipFormData.t_WIP_Account[0].t_Party_Role[1].lastname =
          secondaryClient?.lastName ?? ''
        if (wipFormData?.t_WIP_Account?.[0]?.accttitle === 'Retirement') {
          wipFormData.t_WIP_Account[0].t_Party_Role[1].relationshipToPrimaryOwner =
            getTitleByOwnerType(
              wipFormData?.t_WIP_Account[0]?.t_Party_Role?.[1]
                ?.relationshipTypeId,
              wipFormData?.t_WIP?.iraaccountownertypeid,
              accountTypes?.accountTypeDetails?.find(
                (obj) =>
                  obj.relationshiptypeid ===
                    wipFormData?.t_WIP_Account[0]?.t_Party_Role?.[1]
                      ?.relationshipTypeId &&
                  obj.accttypeid === Number(accountType.value)
              )
            )
        } else {
          wipFormData.t_WIP_Account[0].t_Party_Role[1].relationshipToPrimaryOwner =
            accountTypes?.accountTypeDetails?.find(
              (obj) =>
                obj.relationshiptypeid ===
                  wipFormData?.t_WIP_Account[0]?.t_Party_Role?.[1]
                    ?.relationshipTypeId &&
                obj.accttypeid === Number(accountType.value)
            )?.relationshipname ?? null
        }

        setWipFormData(wipFormData)
      }
      if (relationList.length > 0) {
        relationList[1].clientDetails = secondaryClient
        setRelationList(relationList)
      }
    }
  }, [
    primary,
    primaryClient,
    secondaryClient,
    setIndividualPartyData,
    setSecondaryPartyData,
    setGovtIds,
    primaryGovtIds,
    secondaryGovtIds,
  ])

  useEffect(() => {
    if (primary) {
      setPartyData(individualPartyData)
      setInvProfileDetails(primaryInvProfileDetails)
      setGovtIds({ ...primaryGovtIds })
    } else {
      setPartyData(secondaryPartyData)
      setInvProfileDetails(secondaryInvProfileDetails)
      setGovtIds({ ...secondaryGovtIds })
    }
  }, [
    individualPartyData,
    secondaryPartyData,
    primary,
    primaryInvProfileDetails,
    secondaryInvProfileDetails,
    primaryGovtIds,
    secondaryGovtIds,
    setGovtIds,
  ])

  const idCheck = newModelData.wipAccountDetailslist
    ?.flatMap((obj: any) => obj.parties)
    ?.find((obj: any) => obj.relationshipTypeId)

  const getSecondaryApplicantData = () => {
    setPartyData({} as any)
    setSecondary(true)
    setPrimary(false)
    if (
      isDashboardNavigate &&
      newModelData?.wipAccountDetailslist &&
      newModelData?.wipAccountDetailslist.length > 0
    ) {
      setLoaderIcon(true)

      const currSelectedClient =
        newModelData?.wipAccountDetailslist?.[0]?.parties.find(
          (obj) => obj.relationshipTypeId === 2
        )
      if (!currSelectedClient) return
      setLoaderIcon(false)
      const getApplicant = currSelectedClient?.partydetails
      if (getApplicant) {
        const parseData = JSON.parse(getApplicant)
        setSecondaryClient(parseData?.IndividualDetails)
        if (parseData) {
          setAllClientData({
            partyid: currSelectedClient?.partyId,
            wipacctid: currSelectedClient?.wipacctid,
            value: parseData,
          })
        }
      }
    }
  }

  const toggleClientSearchModal = (open: boolean) => {
    setOpenSearchClientPopup(open)
    if (!open) setSearchData('')
  }

  const useOutsideAlerter = (ref: RefObject<HTMLDivElement>) => {
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          if (!ref?.current?.contains(event.target as Node)) {
            setOpenSearchClientPopup(false)
          }
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }
  useOutsideAlerter(wrapperRef)

  const handleClientKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter' && searchData) {
      if (searchData.length > 1) {
        toggleClientSearchModal(true)
      }
    }
  }
  const handleSearchClient = () => {
    if (searchData) {
      if (searchData.length > 1) {
        toggleClientSearchModal(true)
      }
    }
  }

  const getPAvatar = useCallback(
    (value: string) => {
      const text = value
      const full_name = text?.split(' ')
      let subAvatar = full_name[0][0]
      if (text) {
        if (full_name.length >= 2 && full_name[1]) {
          subAvatar += full_name[1][0]
        }
      } else {
        subAvatar = ''
      }
      const relationshipCheck =
        newModelData?.wipAccountDetailslist?.[0]?.parties?.find(
          (obj) => obj?.relationshipTypeId === 1
        )
      if (relationshipCheck) {
        const getApplicant = relationshipCheck?.partyname

        if (getApplicant) setPAvatar(subAvatar?.toUpperCase())
        else setSAvatar('PA')
      }
    },
    [getWipResponse?.wipDTO?.firstname, getWipResponse?.wipDTO?.lastname]
  )

  const getAvtar = (isCoAppliCant: any, isCustodian: any) => {
    const text = isCustodian ? isCustodian.partyname : isCoAppliCant.partyname

    const full_name = text?.split(' ')
    let subAvatar = full_name[0][0]
    if (text) {
      if (full_name?.length >= 2 && full_name[1]) {
        subAvatar += full_name[1][0]
      }
    } else {
      subAvatar = ''
    }
    if (
      getWipResponse?.wipDTO?.firstname +
      '' +
      getWipResponse?.wipDTO?.lastname
    )
      return subAvatar?.toUpperCase()
    else return 'PA'
  }
  const getName = (isCoAppliCant: any, isCustodian: any) => {
    let text = ''
    if (isCoAppliCant) {
      text = isCoAppliCant?.partyname ? isCoAppliCant?.partyname : ''
    } else if (isCustodian) {
      text =
        isCustodian?.custodianpartyid != 0
          ? isCustodian?.custodianfirstname +
            ' ' +
            (isCustodian?.custodianlastname
              ? isCustodian?.custodianlastname
              : '')
          : ''
    }

    return text
  }
  const getSAvatar = useCallback(
    (value: string) => {
      const text = value
      const full_name = text?.split(' ')
      let subAvatar = full_name?.[0][0]
      if (text) {
        if (full_name?.length >= 2 && full_name?.[1]) {
          subAvatar += full_name?.[1][0]
        }
      } else {
        subAvatar = ''
      }
      const coApplicantDetails =
        getWipResponse?.wipaccount[0]?.coApplicantDetails?.find(
          (obj) => obj.iscoapplicant
        )
      if (
        coApplicantDetails?.firstname +
        '' +
        (coApplicantDetails?.lastname ? coApplicantDetails?.lastname : '')
      )
        setSAvatar(subAvatar?.toUpperCase())
      else setSAvatar('CA')
    },
    [getWipResponse?.wipaccount]
  )

  useEffect(() => {
    if (wipId) {
      const currSelectedClient =
        newModelData?.wipAccountDetailslist?.[0]?.parties.find(
          (obj) => obj.relationshipTypeId === 1
        )
      const getApplicant = currSelectedClient?.partyname
      if (getApplicant) {
        getPAvatar(getApplicant)
      }
    }
  }, [getPAvatar, newModelData, wipId])

  const [ssnValidate, setSssnValidate] = useState(false)
  //ssn validation
  // useEffect(() => {
  //   if (
  //     partyData?.clientIds?.filter(
  //       (id) => id.idType === 'US Social Security'
  //     )?.[0] &&
  //     partyData?.clientIds?.filter(
  //       (id) => id.idType === 'US Social Security'
  //     )?.[0]?.idNumber.length != 9
  //   ) {
  //     setSssnValidate(true)
  //   }
  // }, [partyData, partyData?.clientIds])
  useEffect(() => {
    if (
      wipId &&
      getWipResponse?.wipaccount &&
      getWipResponse?.wipaccount[0]?.coApplicantDetails
    ) {
      const coApplicantDetails =
        getWipResponse?.wipaccount[0]?.coApplicantDetails?.find(
          (obj) => obj.iscoapplicant
        )
      const name =
        coApplicantDetails?.firstname +
        '' +
        (coApplicantDetails?.lastname ? coApplicantDetails?.lastname : '')
      getSAvatar(name)
    }
  }, [getSAvatar, getWipResponse?.wipaccount, wipId])

  useEffect(() => {
    setProductInfoFieldCheck({
      repCode: true,
      productType: true,
      subAccountType: true,
      nickname: true,
      ownerType: true,
    })
  }, [setProductInfoFieldCheck])
  useEffect(() => {
    if (!wipId) {
      setIndividualPartyData({
        partyId: 0,
        partyTypeId: 0,
        prefix: '--',
        firstName: '--',
        middleName: '--',
        lastName: '--',
        suffix: '--',
        fullName: '--',
        salutation: '--',
        socialSecurityNumber: '--',
        updateBy: '--',
        partyDemographic: {
          partyDemographicId: 0,
          dateOfBirth: '--',
          countryOfResidence: '--',
          countryOfTaxResidence: '--',
          citizenshipCountry: '--',
          maritalStatus: '--',
          numberOfDependents: '--',
          employmentStatus: '--',
          employerName: '--',
          occupation: '--',
          occupationCategoryId: 0,
          typeOfBusiness: '--',
          businessPhone: '--',
          employerPhone: null,
          contactId: 0,
          contactTypeId: 0,
          businessAddress: '--',
          city: '--',
          cityId: 0,
          stateProvinceId: 0,
          stateName: '--',
          stateCode: '--',
          countryName: '--',
          countryAlpha2Code: '--',
          zipCode: '--',
          addressTypeId: 0,
          addressId: 0,
          deceasedFlag: false,
          deceasedDate: '--',
          parent: '--',
          emailAddress: '--',
        },
        clientIds: [
          {
            idType: '--',
            idNumber: '--',
            issueDate: '--',
            expiryDate: '--',
            countryOfIssuance: '--',
          },
        ],
      })
      setPrimaryGovtIds({} as IGovtIdsInterface)
      setSecondaryGovtIds({} as IGovtIdsInterface)
    }
  }, [wipId])

  return (
    // <Container >
    <ResponsiveWrapper id="common">
      <StyledDocumentPanel ref={props.pdfNodeRef}>
        <AppInfoWrapper>
          <WipHeadingWrapper id="wrap_row">
            {!wipId && (
              <TabWrapper>
                {wipFormData?.t_WIP_Account?.[0]?.t_Party_Role?.map(
                  (item: PartyModel, index: number) => {
                    const currentResponseName =
                      accountTypes?.accountTypeDetails?.find(
                        (obj) =>
                          obj.relationshiptypeid === item.relationshipTypeId &&
                          obj.accttypeid === Number(accountType.value)
                      )
                    let appHeader
                    if (
                      wipFormData?.t_WIP_Account?.[0]?.accttitle ===
                      'Retirement'
                    ) {
                      appHeader = getTitleByOwnerType(
                        item.relationshipTypeId,
                        wipFormData?.t_WIP?.iraaccountownertypeid,
                        currentResponseName
                      )
                    } else {
                      appHeader = accountTypes?.accountTypeDetails?.find(
                        (obj) =>
                          obj.relationshiptypeid === item.relationshipTypeId &&
                          obj.accttypeid === Number(accountType.value)
                      )
                        ? accountTypes?.accountTypeDetails?.find(
                            (obj) =>
                              obj.relationshiptypeid ===
                                item.relationshipTypeId &&
                              obj.accttypeid === Number(accountType.value)
                          )?.relationshipname
                        : 'Primary Applicant'
                    }
                    return (
                      <TabWrapper key={index}>
                        <HeaderWrapper
                          id={
                            index === selectedIndex
                              ? 'selected'
                              : 'not-selected'
                          }
                        >
                          <ToolTipParentWrapper id="primary_text">
                            <ToolTipHoverWrapper>
                              <StyledButtonTransparent
                                id={
                                  index === selectedIndex
                                    ? 'selected'
                                    : 'not-selected'
                                }
                              >
                                <BtnWrap
                                  onClick={() => {
                                    if (index === 0) {
                                      setPrimary(true)
                                      setSecondary(false)
                                    } else {
                                      setPrimary(false)
                                      setSecondary(true)
                                    }
                                    setSelectedIndex(index)
                                  }}
                                >
                                  <TitleGrouping>
                                    <UserWrapper>
                                      <WDLabelSemiBold>
                                        {appHeader
                                          .split(' ')
                                          .slice(0,2)
                                          .map((word: any) => word[0])
                                          .join('')}
                                      </WDLabelSemiBold>
                                    </UserWrapper>
                                    {appHeader}
                                  </TitleGrouping>
                                </BtnWrap>
                              </StyledButtonTransparent>
                            </ToolTipHoverWrapper>
                          </ToolTipParentWrapper>
                        </HeaderWrapper>
                      </TabWrapper>
                    )
                  }
                )}
              </TabWrapper>
            )}
            <TabWrapper>
              {wipId &&
                newModelData?.wipAccountDetailslist?.[0].parties
                  ?.filter(
                    (obj) =>
                      obj.relationshipTypeId == 1 || obj.relationshipTypeId == 2
                  )
                  ?.map((item: PartyDetails, index: number) => {
                    return (
                      <TabWrapper key={index}>
                        <HeaderWrapper
                          id={
                            index === selectedIndex
                              ? 'selected'
                              : 'not-selected'
                          }
                        >
                          <ToolTipParentWrapper id="primary_text">
                            <ToolTipHoverWrapper>
                              <StyledButtonTransparent
                                id={
                                  index === selectedIndex
                                    ? 'selected'
                                    : 'not-selected'
                                }
                              >
                                <BtnWrap
                                  onClick={() => {
                                    if (index === 0) {
                                      setPrimary(true)
                                      setSecondary(false)
                                    } else {
                                      setPrimary(false)
                                      setSecondary(true)
                                    }
                                    setSelectedIndex(index)
                                  }}
                                >
                                  <TitleGrouping>
                                    <UserWrapper>
                                      <WDLabelSemiBold>
                                        {item.partyname
                                          .split(' ')
                                          .slice(0,2)
                                          .map((word) => word[0])
                                          .join('')}
                                      </WDLabelSemiBold>
                                    </UserWrapper>
                                    {item.partyname}
                                  </TitleGrouping>
                                </BtnWrap>
                              </StyledButtonTransparent>
                            </ToolTipHoverWrapper>
                          </ToolTipParentWrapper>
                        </HeaderWrapper>
                      </TabWrapper>
                    )
                  })}
            </TabWrapper>
            <>
              {selectedClient.clientId !== undefined &&
                selectedClient.clientId !== 0 &&
                toggleHeader &&
                !readOnly &&
                !wipId && (
                  <EditWrapper id="primary-applicant">
                    <WDButtonPrimaryOutlined>
                      <Button
                        type="button"
                        onClick={() => goToClientMaintenance()}
                      >
                        {'Client Maintenance'}
                      </Button>
                    </WDButtonPrimaryOutlined>
                  </EditWrapper>
                )}
            </>
            {wipId && !toggleHeader && (
              <WDButtonTransparent>
                <Button
                  type="button"
                  onClick={() => setToggleHeader(!toggleHeader)}
                >
                  <SvgChevronDownMedium fillColor={`${COLORS.Text.Gray}`} />
                </Button>
              </WDButtonTransparent>
            )}
            {wipId && toggleHeader && (
              <WDButtonTransparent>
                <Button
                  type="button"
                  onClick={() => setToggleHeader(!toggleHeader)}
                >
                  <SvgChevronUpMedium fillColor={`${COLORS.Text.Gray}`} />
                </Button>
              </WDButtonTransparent>
            )}
          </WipHeadingWrapper>

          {!wipId && (
            <SearchWrapper>
              <ClientSearchWrapper>
                <WDTextboxBorderRadius>
                  <Textbox
                    placeholder="Enter Client Name"
                    onChange={(value) => {
                      setSearchData(value)
                      setShowError({ ...showError, client: false })
                    }}
                    value={searchData}
                    onKeyDown={handleClientKeyPress}
                    disabled={
                      productInfoFieldCheck.repCode ||
                      productInfoFieldCheck.productType ||
                      productInfoFieldCheck.subAccountType ||
                      productInfoFieldCheck.nickname ||
                      (productInfoFieldCheck.productType == '4' &&
                        !productInfoFieldCheck.ownerType)
                    }
                  />
                </WDTextboxBorderRadius>
                <WDButtonDisabled>
                  <Button
                    type={'button'}
                    prefixedIcon={
                      <SvgSearch fillColor={COLORS.Icons.Neutral} />
                    }
                    onClick={handleSearchClient}
                  />
                </WDButtonDisabled>
                {showError.client && (
                  <WDLabelError className="label_responsive">
                    {'*Required'}
                  </WDLabelError>
                )}
                {openSearchClientPopup && (
                  <ClientSearchCard ref={wrapperRef}>
                    <ClientSearch
                      searchValue={searchData}
                      closeModalCallbackFn={toggleClientSearchModal}
                    />
                  </ClientSearchCard>
                )}
              </ClientSearchWrapper>
              {ssnValidate && (
                <WDLabelError>
                  {'Social Security Number should be of 9 digits'}
                </WDLabelError>
              )}
            </SearchWrapper>
          )}

          {toggleHeader && (
            <DividerWrapper>
              <Divider
                horizontal={true}
                bgColor={`${COLORS.Background.Neutral}`}
                thickness={'0.1rem'}
              />
            </DividerWrapper>
          )}
          {toggleHeader && (
            <ContentWrapper>
              {loaderIcon ? (
                <SpinnerWrapper>
                  <LottieWrapper>
                    <Lottie animationData={Loader} loop={true} />
                  </LottieWrapper>
                </SpinnerWrapper>
              ) : (
                <>
                  {selectedClient.clientId !== undefined &&
                    selectedClient.clientId !== 0 &&
                    toggleHeader &&
                    !readOnly &&
                    wipId && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <EditWrapper id="primary-applicant">
                          <WDButtonPrimaryOutlined>
                            <Button
                              type="button"
                              onClick={() => goToClientMaintenance()}
                            >
                              {'Client Maintenance'}
                            </Button>
                          </WDButtonPrimaryOutlined>
                        </EditWrapper>
                      </div>
                    )}
                  <ParentInfoWrapper id="paScroll">
                    <PersonalInfoWrapper>
                      <ItemsListWrapper>
                        <ItemListChildWrapper>
                          <WDLabel className="label_responsive">
                            <Label>{'Prefix '}</Label>
                          </WDLabel>
                          <WDLabelClient className="label_responsive">
                            <Label>
                              {partyData?.prefix ? partyData?.prefix : '--'}
                            </Label>
                          </WDLabelClient>
                        </ItemListChildWrapper>
                        <ItemListChildWrapper>
                          <WDLabel className="label_responsive">
                            {'First Name'}
                          </WDLabel>
                          <WDLabelClient className="label_responsive">
                            <Label>
                              {partyData?.firstName
                                ? partyData?.firstName
                                : '--'}
                            </Label>
                          </WDLabelClient>
                        </ItemListChildWrapper>
                        {!wipId && (
                          <ItemListChildWrapper>
                            <WDLabel className="label_responsive">
                              <Label>{'Suffix '}</Label>
                            </WDLabel>
                            <WDLabelClient className="label_responsive">
                              <Label>
                                {partyData?.suffix ? partyData?.suffix : '--'}
                              </Label>
                            </WDLabelClient>
                          </ItemListChildWrapper>
                        )}
                        {!wipId && (
                          <ItemListChildWrapper>
                            <WDLabel className="label_responsive">
                              <Label>{'Full Name '}</Label>
                            </WDLabel>
                            <WDLabelClient className="label_responsive">
                              <Label>
                                {partyData?.fullName
                                  ? partyData?.fullName
                                  : '--'}
                              </Label>
                            </WDLabelClient>
                          </ItemListChildWrapper>
                        )}
                      </ItemsListWrapper>
                      {wipId && (
                        <ItemsListWrapper>
                          <ItemListChildWrapper>
                            <WDLabel className="label_responsive">
                              <Label>{'Suffix '}</Label>
                            </WDLabel>
                            <WDLabelClient className="label_responsive">
                              <Label>
                                {partyData?.suffix ? partyData?.suffix : '--'}
                              </Label>
                            </WDLabelClient>
                          </ItemListChildWrapper>
                          <ItemListChildWrapper>
                            <WDLabel className="label_responsive">
                              <Label>{'Full Name '}</Label>
                            </WDLabel>
                            <WDLabelClient className="label_responsive">
                              <Label>
                                {partyData?.fullName
                                  ? partyData?.fullName
                                  : '--'}
                              </Label>
                            </WDLabelClient>
                          </ItemListChildWrapper>
                        </ItemsListWrapper>
                      )}
                      <ItemsListWrapper>
                        <ItemListChildWrapper>
                          <WDLabel className="label_responsive">
                            <Label>{'Middle Initial'}</Label>
                          </WDLabel>
                          <WDLabelClient className="label_responsive">
                            <Label>
                              {partyData?.middleName
                                ? partyData?.middleName
                                : '--'}
                            </Label>
                          </WDLabelClient>
                        </ItemListChildWrapper>
                        <ItemListChildWrapper>
                          <WDLabel className="label_responsive">
                            <Label>{'Last Name'}</Label>
                          </WDLabel>
                          <WDLabelClient className="label_responsive">
                            <Label>
                              {partyData?.lastName ? partyData?.lastName : '--'}
                            </Label>
                          </WDLabelClient>
                        </ItemListChildWrapper>
                        {!wipId && (
                          <ItemListChildWrapper>
                            <WDLabel className="label_responsive">
                              <Label>{'Salutation'}</Label>
                            </WDLabel>
                            <WDLabelClient className="label_responsive">
                              <Label>
                                {partyData?.salutation
                                  ? partyData?.salutation
                                  : '--'}
                              </Label>
                            </WDLabelClient>
                          </ItemListChildWrapper>
                        )}
                        {!wipId && (
                          <ItemListChildWrapper>
                            <WDLabel className="label_responsive">
                              {'Social Security Number'}
                            </WDLabel>
                            {visibility &&
                              partyData?.clientIds
                                ?.filter(
                                  (id) => id.idType === 'US Social Security'
                                )
                                ?.map((id) => {
                                  return (
                                    <WDButtonTransparent key={id.idNumber}>
                                      <Button
                                        type="button"
                                        onClick={() =>
                                          setVisibility(!visibility)
                                        }
                                      >
                                        <SvgEye
                                          fillColor={COLORS.Text.NeutralText}
                                        />
                                        <WDLabelClient className="label_responsive">
                                          {formatSSN(id.idNumber)}
                                        </WDLabelClient>
                                      </Button>
                                    </WDButtonTransparent>
                                  )
                                })}
                            {!visibility &&
                              partyData?.clientIds
                                ?.filter(
                                  (id) => id.idType === 'US Social Security'
                                )
                                ?.map((id) => {
                                  return (
                                    <WDButtonTransparent key={id.idNumber}>
                                      <Button
                                        type="button"
                                        onClick={() =>
                                          setVisibility(!visibility)
                                        }
                                      >
                                        <SvgNoVisible
                                          fillColor={COLORS.Text.NeutralText}
                                        />
                                        <WDLabelClient className="label_responsive">
                                          &bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&nbsp;
                                          {id.idNumber !== null
                                            ? maskSSN(id.idNumber)
                                            : '--'}
                                        </WDLabelClient>
                                      </Button>
                                    </WDButtonTransparent>
                                  )
                                })}
                          </ItemListChildWrapper>
                        )}
                      </ItemsListWrapper>
                      {wipId && (
                        <ItemsListWrapper>
                          <ItemListChildWrapper>
                            <WDLabel className="label_responsive">
                              <Label>{'Salutation'}</Label>
                            </WDLabel>
                            <WDLabelClient className="label_responsive">
                              <Label>
                                {partyData?.salutation
                                  ? partyData?.salutation
                                  : '--'}
                              </Label>
                            </WDLabelClient>
                          </ItemListChildWrapper>
                          <ItemListChildWrapper></ItemListChildWrapper>
                        </ItemsListWrapper>
                      )}
                    </PersonalInfoWrapper>
                    {wipId && (
                      <RenderApplicantInfo
                        wipId={wipId}
                        partyData={partyData}
                        govtIds={govtIds}
                        visibility={visibility}
                        setVisibility={setVisibility}
                      />
                    )}
                    {viewMore && !wipId && (
                      <RenderApplicantInfo
                        wipId={wipId}
                        partyData={partyData}
                        govtIds={govtIds}
                        visibility={visibility}
                        setVisibility={setVisibility}
                      />
                    )}
                  </ParentInfoWrapper>
                  {!wipId && (
                    <WDButtonTransparent>
                      <Button
                        type="button"
                        onClick={() => setViewMore(!viewMore)}
                      >
                        <WDLabelPrimaryTextSmall>
                          {viewMore ? 'View Less' : 'View More'}
                        </WDLabelPrimaryTextSmall>
                      </Button>
                    </WDButtonTransparent>
                  )}
                </>
              )}
            </ContentWrapper>
          )}
        </AppInfoWrapper>
      </StyledDocumentPanel>
    </ResponsiveWrapper>
    // </Container>
  )
}
export default ApplicantInfoPage
