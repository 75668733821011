import Lottie from 'lottie-react'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import Loader from '../../../../../../assets/lottiefiles/loader.json'
import Button from '../../../../../../components/button/Button'
import Divider from '../../../../../../components/divider/Divider'
import SelectDropdown from '../../../../../../components/extra/selectDropdown/SelectDropdown'
import Label from '../../../../../../components/label/Label'
import {
  SvgChevronDownMedium,
  SvgChevronUpMedium,
} from '../../../../../../components/svg/SvgChevron'
import { SvgFunding } from '../../../../../../components/svg/SvgDollar'
import { SvgExclamation } from '../../../../../../components/svg/SvgExclamation'
import { SvgExclamationSmall } from '../../../../../../components/svg/SvgExclamationSmall'
import { Textbox } from '../../../../../../components/textbox/Textbox'
import { Toast } from '../../../../../../components/toast/Toast'
import { WDButtonOutLine } from '../../../../../../components/ui/WDButton'
import {
  WDLabel,
  WDLabelClient,
  WDLabelError,
  WDLabelPrimaryText,
  WDLabelStyledCardHeader,
} from '../../../../../../components/ui/WDLabel'
import { WDTextboxBorderRadius } from '../../../../../../components/ui/WDTextbox'
import { OptionProps } from '../../../../../../composites/selectDropdown/ISelectDropDown'
import { DomnCode } from '../../../../../../constant/DomnCode'
import { FundingErrorMessage } from '../../../../../../constant/Messages'
import { ResponsiveWrapper } from '../../../../../../pages/accountApplicationPage/styles'
import { RoleContext } from '../../../../../../services/roleContext/RoleContext'
import ToastContext from '../../../../../../services/toast/ToastContext'
import { getXrefDatabyDomainCode } from '../../../../../../shared/Comman'
import { numericType, toastType } from '../../../../../../shared/config/enums'
import {
  default as FormFundingContext,
  default as FundingContext,
} from '../../../../../../store/FormFundingContext'
import { COLORS } from '../../../../../../theme/Colors'
import { LottieWrapper } from '../../../../../interceptor/styles'
import { StyledDocumentPanel } from '../../../../accountInformation/styles'
import {
  LottieButtonWrapper,
  SpinnerButtonWrapper,
} from '../../../../electronicPrefs/styles'
import {
  TitleGrouping,
  ToastWrapper,
  WipHeadingWrapper,
} from '../../../../formWip/styles'
import { Row } from '../AccountFeature/styles'
import { DividerWrapper } from '../ApplicantInfo/styles'
import { SpinnerWrapper } from '../ProductFeatures/style'
import {
  DdlRow,
  DocumentRowContainer,
  ErrorMsg,
  FeatureInfoColumn,
  FeatureInfoRow,
  FormContainer,
  FundingButtonWrapper,
  FundingWrapper,
  FundWrapper,
  ImageWrapper,
} from './styles'
import { ApplicationInfoContext } from '../../../../../../services/applicationContext/ApplicationPageContext'
import { getWipDetails } from '../../../../../../services/formRequest/FormRequestAPI'
import { GetWipRequestInterface } from '../../../../formWip/IFormWip'

const FundingInfo = (props: { isReview?: boolean; isPdf?: boolean }) => {
  const [showOption, setShowOption] = useState(false)
  const { accessList } = useContext(RoleContext)
  const [confirmAccountnumber, setConfirmAccountnumber] = useState('')

  const [loader, setLoader] = useState(false)
  const { setToast } = useContext(ToastContext)
  const [isFocused, setIsFocused] = useState(false)
  const [transfertype, setTransfertype] = useState<OptionProps[]>([
    {
      label: '',
      value: '-1',
    },
  ])

  const [recurring, setRecurring] = useState<OptionProps[]>([
    {
      label: '',
      value: '-1',
    },
  ])

  const [recurringDropOptions] = useState([
    {
      label: 'Yes',
      value: '1',
    },
    {
      label: 'No',
      value: '2',
    },
  ])
  const [showFrequency, setShowFrequency] = useState(true)
  const [hideRecurDropdown, setHideRecurDropdown] = useState(false)
  const {
    wipId,
    readOnly,
    autoSaveFunction,
    featureaddFunding,
    setfeatureaddFunding,
    banknumber,
    setbanknumber,
    transferamount,
    settransferamount,
    depositaccountnumber,
    setdepositaccountnumber,
    buttonTextFund,
    setButtonTextFund,
    formErrors,
    setFormErrors,
    setDetectChangeFund,
    xrefDetails,
    Validate,
    newModelData,
    setNewModelData,
  } = useContext(ApplicationInfoContext)

  const [isSameAcNumber, setIsSameAcNumber] = useState(true)
  const [isDirty, setIsDirty] = useState(false)
  const [toggleHeader, setToggleHeader] = useState(true)
  const [render, setRender] = useState<boolean>(false)
  const [apiPreviewStatus, setAPIPreviewStatus] = useState<{
    status: string
    text: string
  }>({
    status: '',
    text: '',
  })

  const handleFundingChange = (
    objName: string,
    value: string | number | undefined
  ) => {
    setIsDirty(true)
    //setFormErrors({ ...formErrors, transfertype: '' })
    let addObj = { ...featureaddFunding }
    addObj = Object.assign({}, addObj, { [objName]: value })
    addObj.transferamount = parseFloat(transferamount)
    if (objName === 'recurringyn' && value === '3') {
      addObj.recurring = ''
    }
    if (objName === 'transfertype') {
      setbanknumber('')
      setdepositaccountnumber('')
      setConfirmAccountnumber('')
      settransferamount('')
      featureaddFunding.recurringyn = ''
      featureaddFunding.recurring = ''
    }
    setfeatureaddFunding(addObj)

    if (addObj.fundid != null) {
      let updateObj = { ...featureaddFunding }
      updateObj = Object.assign({}, updateObj, { [objName]: value })
      updateObj.transferamount = parseFloat(transferamount)
      if (objName === 'recurringyn' && value === '3') {
        updateObj.recurring = ''
      }
      setfeatureaddFunding(updateObj)
    }
  }

  const showMessage = (errorMessage: string, status: string) => {
    if (errorMessage != '') {
      setAPIPreviewStatus({
        status: status,
        text: errorMessage,
      })
    }
  }

  const handlebnkChange = (value: { length: number }) => {
    if (value.length > parseInt(numericType.LENGTH)) {
      showMessage(
        FundingErrorMessage.RoutingBankLengthValidate,
        toastType.DANGER
      )
      return
    }
    setbanknumber(value?.toString())
  }

  useEffect(() => {
    if (newModelData?.wipAccountDetailslist?.[0]) {
      setfeatureaddFunding({
        fundid: 0,
        wipacctid: newModelData?.wipAccountDetailslist?.[0].wipacctid ?? '',
        depositaccountnumber:
          newModelData?.wipAccountDetailslist?.[0].depositaccountnumber ?? '',
        banknumber: newModelData?.wipAccountDetailslist?.[0].banknumber ?? '',
        transferamount:
          newModelData?.wipAccountDetailslist?.[0].transferamount ?? '',
        transfertype:
          newModelData?.wipAccountDetailslist?.[0].transfertypeid ?? '',
        recurring:
          newModelData?.wipAccountDetailslist?.[0]?.recurringfrequencyid?.toString() ??
          '',
        recurringyn:
          newModelData?.wipAccountDetailslist?.[0].recurring == true
            ? '1'
            : newModelData?.wipAccountDetailslist?.[0].recurring == false
            ? '2'
            : '',
      })
      setHideRecurDropdown(newModelData?.wipAccountDetailslist?.[0]?.recurring)
      setdepositaccountnumber(
        newModelData?.wipAccountDetailslist?.[0]?.depositaccountnumber
      )
      setConfirmAccountnumber(
        newModelData?.wipAccountDetailslist?.[0]?.depositaccountnumber
      )
      setbanknumber(newModelData?.wipAccountDetailslist?.[0]?.banknumber)
      settransferamount(
        newModelData?.wipAccountDetailslist?.[0]?.transferamount?.toString()
      )
      setShowFrequency(newModelData?.wipAccountDetailslist?.[0]?.recurring)
      setShowOption(
        newModelData?.wipAccountDetailslist?.[0]?.transfertypeid === 3 ||
          !newModelData?.wipAccountDetailslist?.[0]?.transfertypeid
          ? false
          : true
      )
    }
  }, [newModelData])

  useEffect(
    () => {
      if (xrefDetails) {
        const transfertype = getXrefDatabyDomainCode(
          xrefDetails,
          DomnCode.TRANSFERTYPE
        )
        const recurring = getXrefDatabyDomainCode(
          xrefDetails,
          DomnCode.RECURRING
        )
        const transferList: OptionProps[] = []
        const recurrList: OptionProps[] = []

        transfertype.map((feature: { code: string; value: string }) => {
          transferList.push({
            label: feature.value,
            value: feature.code,
          })
        })

        recurring.map((feature: { code: string; value: string }) => {
          if (feature.value !== '--Please Select--') {
            recurrList.push({
              label: feature.value,
              value: feature.code,
            })
          }
        })
        setTransfertype(transferList)
        setRecurring(recurrList)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [xrefDetails]
  )
  const popupConfirmation = () => {
    if (depositaccountnumber !== confirmAccountnumber) {
      setIsSameAcNumber(false)
      return
    }
    if (Validate()) {
      setLoader(true)
      autoSaveFunction().then((data) => {
        if (data === 200) {
          setLoader(false)
          setToast('success', 'Funding details saved')
          const payload: GetWipRequestInterface = {
            appId: '1',
            wipId: parseInt(wipId),
          }
          getWipDetails(payload).then((getWipRecord) => {
            setNewModelData(getWipRecord.data)
          })
        }
      })
    }
    // if (Validate()) {
    // setTimeout(() => {
    //   setFormErrors({
    //     depositaccountnumber: '',
    //     banknumber: '',
    //     transferamount: '',
    //     transfertype: '',
    //     recurring: '',
    //     recurringyn: '',
    //   })
    // }, 5000)
    setIsSameAcNumber(true)
    // if (getWipResponse.wipaccount && getWipResponse.wipaccount.length > 0) {
    //   setLoader(true)
    //   if (buttonTextFund === 'Save') {
    //     onSaveUpdateFunding()
    //       .then((data) => {
    //         setLoader(false)
    //         if (data == 200) {
    //           setToast('success', 'Funding details saved')
    //         } else {
    //           setToast('error', 'Failed to save the record')
    //         }
    //       })
    //       .catch(() => {
    //         setLoader(false)
    //       })
    //   } else {
    //     onSaveUpdateFunding()
    //       .then((data) => {
    //         setLoader(false)
    //         if (data == 200) {
    //           setToast('success', 'Funding details updated')
    //         } else {
    //           setToast('error', 'Failed to update the record')
    //         }
    //       })
    //       .catch(() => {
    //         setLoader(false)
    //       })
    //   }
    // }
    // }
  }

  const formatCurrency = (val: any) => {
    if (!val) return ''
    const numberValue = parseFloat(val.replace(/[^0-9.]/g, ''))
    return numberValue
      ? numberValue.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
      : ''
  }

  return (
    <ResponsiveWrapper id="common">
      <StyledDocumentPanel id={props.isPdf ? 'remove' : ''}>
        <WipHeadingWrapper>
          <TitleGrouping
            onClick={() => {
              setToggleHeader(!toggleHeader)
              // setIsEdit(false)
            }}
          >
            {/* <SvgFunding />
            <WDLabelStyledCardHeader className="header_responsive">
              {'Funding Details'}
            </WDLabelStyledCardHeader> */}

            <SvgFunding />
            <WDLabelStyledCardHeader className="header_responsive">
              {'Funding Details'}
            </WDLabelStyledCardHeader>
          </TitleGrouping>
          {/* {!readOnly && toggleHeader && (
          <EditWrapper>
            <WDButtonTransparent>
              <Button
                type="button"
                onClick={() => {
                  setIsEdit(true)
                  setToggleHeader(true)
                }}
                prefixedIcon={<SvgPencil fillColor={COLORS.UI.PrimaryText} />}
              >
                <WDLabelPrimaryText>{'Edit'}</WDLabelPrimaryText>
              </Button>
            </WDButtonTransparent>
          </EditWrapper>
        )} */}
          <ImageWrapper
            onClick={() => {
              setToggleHeader(!toggleHeader)
              // setIsEdit(false)
            }}
          >
            {!toggleHeader && !props.isPdf && (
              <SvgChevronDownMedium fillColor={`${COLORS.Text.Gray}`} />
            )}
            {toggleHeader && !props.isPdf && (
              <SvgChevronUpMedium fillColor={`${COLORS.Text.Gray}`} />
            )}
          </ImageWrapper>
        </WipHeadingWrapper>
        {toggleHeader && (
          <DividerWrapper id="product_feature">
            <Divider
              horizontal={true}
              bgColor={`${COLORS.Background.Neutral}`}
              thickness={'0.1rem'}
            />
          </DividerWrapper>
        )}
        {toggleHeader && (
          <>
            {loader ? (
              <SpinnerWrapper>
                <LottieWrapper>
                  <Lottie animationData={Loader} loop={true} />
                </LottieWrapper>
              </SpinnerWrapper>
            ) : (
              <FundWrapper>
                <FundingWrapper>
                  <FormContainer id="FormContainer">
                    <DocumentRowContainer>
                      <FeatureInfoRow className={'fullWidth'}>
                        <FeatureInfoColumn>
                          <Row>
                            <WDLabel className="label_responsive">
                              <Label>{'Funding Source*'}</Label>
                            </WDLabel>
                            {formErrors.transfertype.length > 0 && (
                              <DdlRow>
                                <SvgExclamation
                                  fillColor={COLORS.Text.Danger}
                                />
                                <WDLabelError className="label_responsive">
                                  {'Required'}
                                </WDLabelError>
                              </DdlRow>
                            )}
                          </Row>
                        </FeatureInfoColumn>
                        {!readOnly ? (
                          <FeatureInfoColumn>
                            <SelectDropdown
                              placeholder="Select"
                              width={'100%'}
                              border={formErrors.transfertype ? true : false}
                              options={transfertype}
                              onChange={(option) => {
                                const value = option ? option : ''
                                if (value == '3') {
                                  setShowOption(false)
                                } else {
                                  setShowOption(true)
                                }
                                handleFundingChange('transfertype', value)
                                setDetectChangeFund(true)
                                setFormErrors({
                                  depositaccountnumber: '',
                                  banknumber: '',
                                  transferamount: '',
                                  transfertype: '',
                                  recurring: '',
                                  recurringyn: '',
                                })
                              }}
                              value={
                                transfertype.filter(
                                  (x) =>
                                    x.value ==
                                    featureaddFunding.transfertype?.toString()
                                )[0]
                              }
                              name={'TransferType'}
                              borderColor={COLORS.Border.Gray50}
                              borderRadius={'0rem'}
                              placeholderColor={COLORS.Text.NeutralTextWeak}
                              padding={'0 8px'}
                              height="2rem"
                            />
                          </FeatureInfoColumn>
                        ) : (
                          <FeatureInfoColumn>
                            <WDLabelClient className="label_responsive">
                              {transfertype.filter(
                                (x) =>
                                  x.value ==
                                  featureaddFunding.transfertype?.toString()
                              )[0]
                                ? transfertype.filter(
                                    (x) =>
                                      x.value ==
                                      featureaddFunding.transfertype?.toString()
                                  )[0].label
                                : '--'}
                            </WDLabelClient>
                          </FeatureInfoColumn>
                        )}
                      </FeatureInfoRow>
                    </DocumentRowContainer>
                    {featureaddFunding.transfertype?.toString() !=
                      numericType.FOUR && (
                      <React.Fragment>
                        {showOption && (
                          <DocumentRowContainer>
                            <FeatureInfoRow className={'fullWidth'}>
                              <FeatureInfoColumn>
                                <Row>
                                  <WDLabel className="label_responsive">
                                    <Label>{'Bank Number* (ABA)'}</Label>
                                  </WDLabel>
                                  {formErrors.banknumber.length > 0 && (
                                    <DdlRow>
                                      <SvgExclamation
                                        fillColor={COLORS.Text.Danger}
                                      />
                                      <WDLabelError className="label_responsive">
                                        {'Required'}
                                      </WDLabelError>
                                    </DdlRow>
                                  )}
                                </Row>
                              </FeatureInfoColumn>
                              {!readOnly ? (
                                <FeatureInfoColumn>
                                  <WDTextboxBorderRadius className="inputBox_responsive">
                                    <Textbox
                                      placeholder=""
                                      type={'text'}
                                      border={
                                        formErrors.banknumber
                                          ? COLORS.Text.Danger
                                          : ''
                                      }
                                      name="banknumber"
                                      value={banknumber}
                                      onChange={(value) => {
                                        handlebnkChange(value.trim())
                                        setDetectChangeFund(true)
                                        setFormErrors({
                                          ...formErrors,
                                          banknumber: '',
                                        })
                                      }}
                                    />
                                  </WDTextboxBorderRadius>
                                </FeatureInfoColumn>
                              ) : (
                                <FeatureInfoColumn>
                                  <WDLabelClient className="label_responsive">
                                    {banknumber ? banknumber : '--'}
                                  </WDLabelClient>
                                </FeatureInfoColumn>
                              )}
                            </FeatureInfoRow>
                          </DocumentRowContainer>
                        )}
                        {showOption && (
                          <DocumentRowContainer>
                            <FeatureInfoRow>
                              <FeatureInfoColumn>
                                <Row>
                                  <WDLabel className="label_responsive">
                                    {'Deposit Account Number* (DDA)'}
                                  </WDLabel>
                                  {formErrors.depositaccountnumber.length >
                                    0 && (
                                    <DdlRow>
                                      <SvgExclamation
                                        fillColor={COLORS.Text.Danger}
                                      />
                                      <WDLabelError className="label_responsive">
                                        {'Required'}
                                      </WDLabelError>
                                    </DdlRow>
                                  )}
                                </Row>
                              </FeatureInfoColumn>
                              {!readOnly ? (
                                <FeatureInfoColumn>
                                  <WDTextboxBorderRadius className="inputBox_responsive">
                                    <Textbox
                                      placeholder=""
                                      // disabled={!isEdit}
                                      type={'text'}
                                      border={
                                        formErrors.depositaccountnumber
                                          ? COLORS.Text.Danger
                                          : ''
                                      }
                                      name="depositaccountnumber"
                                      value={depositaccountnumber}
                                      onChange={(value) => {
                                        setdepositaccountnumber(value)
                                        setDetectChangeFund(true)
                                        setFormErrors({
                                          ...formErrors,
                                          depositaccountnumber: '',
                                        })
                                      }}
                                    />
                                  </WDTextboxBorderRadius>
                                </FeatureInfoColumn>
                              ) : (
                                <FeatureInfoColumn>
                                  <WDLabelClient className="label_responsive">
                                    {depositaccountnumber
                                      ? depositaccountnumber
                                      : '--'}
                                  </WDLabelClient>
                                </FeatureInfoColumn>
                              )}
                            </FeatureInfoRow>
                          </DocumentRowContainer>
                        )}
                        {showOption && (
                          <DocumentRowContainer>
                            <FeatureInfoRow>
                              <FeatureInfoColumn>
                                <Row>
                                  <WDLabel className="label_responsive">
                                    {'Confirm Deposit Account Number* (DDA)'}
                                  </WDLabel>
                                </Row>
                              </FeatureInfoColumn>
                              {!readOnly ? (
                                <>
                                  <FeatureInfoColumn>
                                    <WDTextboxBorderRadius className="inputBox_responsive">
                                      <Textbox
                                        border={
                                          !isSameAcNumber
                                            ? COLORS.Text.Danger
                                            : ''
                                        }
                                        placeholder=""
                                        // disabled={!isEdit}
                                        type={'text'}
                                        name="confirmdepositaccountnumber"
                                        value={confirmAccountnumber}
                                        onChange={(value) => {
                                          setIsSameAcNumber(true)
                                          setConfirmAccountnumber(value)
                                          setDetectChangeFund(true)
                                        }}
                                      />
                                    </WDTextboxBorderRadius>
                                  </FeatureInfoColumn>
                                  {!isSameAcNumber && (
                                    <DdlRow>
                                      <ErrorMsg>
                                        <SvgExclamation
                                          fillColor={COLORS.Text.Danger}
                                        />
                                      </ErrorMsg>
                                      <WDLabelError className="label_responsive">
                                        {'Deposit Account Number doesn’t match'}
                                      </WDLabelError>
                                    </DdlRow>
                                  )}
                                </>
                              ) : (
                                <FeatureInfoColumn>
                                  <WDLabelClient className="label_responsive">
                                    {confirmAccountnumber
                                      ? confirmAccountnumber
                                      : '--'}
                                  </WDLabelClient>
                                </FeatureInfoColumn>
                              )}
                            </FeatureInfoRow>
                          </DocumentRowContainer>
                        )}
                        <DocumentRowContainer>
                          <FeatureInfoRow>
                            <FeatureInfoColumn>
                              <Row>
                                <WDLabel className="label_responsive">
                                  <Label>{'Funding Amount* ($)'}</Label>
                                </WDLabel>
                                {formErrors.transferamount.length > 0 && (
                                  <DdlRow>
                                    <SvgExclamation
                                      fillColor={COLORS.Text.Danger}
                                    />
                                    <WDLabelError className="label_responsive">
                                      {'Required'}
                                    </WDLabelError>
                                  </DdlRow>
                                )}
                              </Row>
                            </FeatureInfoColumn>
                            {!readOnly ? (
                              <FeatureInfoColumn>
                                <WDTextboxBorderRadius className="inputBox_responsive">
                                  <Textbox
                                    // disabled={!isEdit}
                                    type={'text'}
                                    name="transferamount"
                                    placeholder=""
                                    border={
                                      formErrors.transferamount
                                        ? COLORS.Text.Danger
                                        : ''
                                    }
                                    value={
                                      isFocused
                                        ? transferamount
                                        : formatCurrency(transferamount)
                                    }
                                    onChange={(value) => {
                                      // handleNumericChange(value)
                                      const numericValue = value.replace(
                                        /[^0-9.]/g,
                                        ''
                                      )
                                      settransferamount(numericValue)
                                      // settransferamount(value)
                                      setDetectChangeFund(true)
                                      setFormErrors({
                                        ...formErrors,
                                        transferamount: '',
                                      })
                                    }}
                                    onBlur={() => {
                                      setIsFocused(false)
                                    }}
                                    onFocus={() => {
                                      setIsFocused(true)
                                    }}
                                  />
                                </WDTextboxBorderRadius>
                              </FeatureInfoColumn>
                            ) : (
                              <FeatureInfoColumn>
                                <WDLabelClient className="label_responsive">
                                  {transferamount
                                    ? formatCurrency(transferamount)
                                    : '--'}
                                </WDLabelClient>
                              </FeatureInfoColumn>
                            )}
                          </FeatureInfoRow>
                        </DocumentRowContainer>
                        {!hideRecurDropdown && (
                          <DocumentRowContainer>
                            {!hideRecurDropdown && (
                              <FeatureInfoRow
                                className={!showFrequency ? 'fullWidth' : ''}
                              >
                                <FeatureInfoColumn>
                                  <Row>
                                    <WDLabel className="label_responsive">
                                      <Label>{'Recurring*'}</Label>
                                    </WDLabel>
                                    {formErrors.recurringyn.length > 0 && (
                                      <DdlRow>
                                        <SvgExclamationSmall
                                          fillColor={COLORS.UI.Danger}
                                        />
                                        <WDLabelError className="label_responsive">
                                          {'Required'}
                                        </WDLabelError>
                                      </DdlRow>
                                    )}
                                  </Row>
                                </FeatureInfoColumn>
                                <FeatureInfoColumn id="recurring">
                                  {!readOnly ? (
                                    <SelectDropdown
                                      placeholder="Select"
                                      width={'100%'}
                                      border={
                                        formErrors.recurringyn ? true : false
                                      }
                                      options={recurringDropOptions}
                                      onChange={(option) => {
                                        const value = option ? option : ''
                                        if (value == '1') {
                                          setShowFrequency(true)
                                          setHideRecurDropdown(true)
                                        } else {
                                          setShowFrequency(false)
                                        }
                                        handleFundingChange(
                                          'recurringyn',
                                          value
                                        )
                                        setDetectChangeFund(true)
                                        setFormErrors({
                                          ...formErrors,
                                          recurringyn: '',
                                        })
                                      }}
                                      value={
                                        featureaddFunding.recurringyn
                                          ? recurringDropOptions.filter(
                                              (x) =>
                                                x.value ==
                                                featureaddFunding.recurringyn
                                            )[0]
                                          : { label: 'Select', value: 'Select' }
                                      }
                                      name={'Recurring'}
                                      borderRadius={'0rem'}
                                      borderColor={COLORS.Border.Gray50}
                                      indicatorColor={COLORS.Text.Gray50}
                                      padding={'0 8px'}
                                      height="2rem"
                                      placeholderColor={
                                        COLORS.Text.NeutralTextWeak
                                      }
                                    />
                                  ) : (
                                    <WDLabelClient className="label_responsive">
                                      {featureaddFunding.recurringyn
                                        ? recurringDropOptions.filter(
                                            (x) =>
                                              x.value ==
                                              featureaddFunding.recurringyn
                                          )[0].label
                                        : '--'}
                                    </WDLabelClient>
                                  )}
                                </FeatureInfoColumn>
                              </FeatureInfoRow>
                            )}
                          </DocumentRowContainer>
                        )}
                        {hideRecurDropdown && (
                          <DocumentRowContainer>
                            {hideRecurDropdown && (
                              <FeatureInfoRow
                                className={!showFrequency ? 'fullWidth' : ''}
                              >
                                <FeatureInfoColumn>
                                  <Row>
                                    <WDLabel className="label_responsive">
                                      <Label>{'Recurring*'}</Label>
                                    </WDLabel>
                                    {formErrors.recurringyn.length > 0 && (
                                      <DdlRow>
                                        <SvgExclamationSmall
                                          fillColor={COLORS.UI.Danger}
                                        />
                                        <WDLabelError className="label_responsive">
                                          {'Required'}
                                        </WDLabelError>
                                      </DdlRow>
                                    )}
                                  </Row>
                                </FeatureInfoColumn>
                                <FeatureInfoColumn>
                                  {!readOnly ? (
                                    <SelectDropdown
                                      placeholder="Select Recurring"
                                      width={'100%'}
                                      border={
                                        formErrors.recurringyn ? true : false
                                      }
                                      options={recurringDropOptions}
                                      onChange={(option) => {
                                        const value = option ? option : ''
                                        if (value == '1') {
                                          setShowFrequency(true)
                                          setHideRecurDropdown(true)
                                        } else {
                                          setShowFrequency(false)
                                          setHideRecurDropdown(false)
                                        }
                                        handleFundingChange(
                                          'recurringyn',
                                          value
                                        )
                                        setDetectChangeFund(true)
                                        setFormErrors({
                                          ...formErrors,
                                          recurringyn: '',
                                        })
                                      }}
                                      value={
                                        featureaddFunding.recurringyn
                                          ? recurringDropOptions.filter(
                                              (x) =>
                                                x.value ==
                                                featureaddFunding.recurringyn
                                            )[0]
                                          : { label: 'Select', value: 'Select' }
                                      }
                                      name={'Recurring'}
                                      borderRadius={'0rem'}
                                      borderColor={COLORS.Border.Gray50}
                                      indicatorColor={COLORS.Text.Gray50}
                                      padding={'0 8px'}
                                      height="2rem"
                                      placeholderColor={
                                        COLORS.Text.NeutralTextWeak
                                      }
                                    />
                                  ) : (
                                    <WDLabelClient className="label_responsive">
                                      {featureaddFunding.recurringyn
                                        ? recurringDropOptions.filter(
                                            (x) =>
                                              x.value ==
                                              featureaddFunding.recurringyn
                                          )[0].label
                                        : '--'}
                                    </WDLabelClient>
                                  )}
                                </FeatureInfoColumn>
                              </FeatureInfoRow>
                            )}
                          </DocumentRowContainer>
                        )}
                        {hideRecurDropdown && (
                          <DocumentRowContainer>
                            <FeatureInfoRow>
                              <FeatureInfoColumn>
                                <Row>
                                  <WDLabel className="label_responsive">
                                    <Label>{'Recurring Frequency*'}</Label>
                                  </WDLabel>
                                  {formErrors.recurring.length > 0 && (
                                    <DdlRow>
                                      <SvgExclamationSmall
                                        fillColor={COLORS.UI.Danger}
                                      />
                                      <WDLabelError className="label_responsive">
                                        {'Required'}
                                      </WDLabelError>
                                    </DdlRow>
                                  )}
                                </Row>
                              </FeatureInfoColumn>
                              <FeatureInfoColumn>
                                {!readOnly ? (
                                  <SelectDropdown
                                    placeholder="Select"
                                    width={'100%'}
                                    options={recurring}
                                    border={formErrors.recurring ? true : false}
                                    onChange={(option: string) => {
                                      const value = option ? option : ''
                                      handleFundingChange('recurring', value)
                                      setDetectChangeFund(true)
                                      setFormErrors({
                                        ...formErrors,
                                        recurring: '',
                                      })
                                    }}
                                    value={
                                      featureaddFunding.recurring !== ''
                                        ? recurring.filter(
                                            (x) =>
                                              x.value ==
                                              featureaddFunding.recurring?.toString()
                                          )[0]
                                        : { label: 'Select', value: 'Select' }
                                    }
                                    name={'Recurring'}
                                    borderRadius={'0.5rem'}
                                    borderColor={COLORS.Border.Gray50}
                                    indicatorColor={COLORS.Text.Gray50}
                                    padding={'0 8px'}
                                    placeholderColor={
                                      COLORS.Text.NeutralTextWeak
                                    }
                                    height="2rem"
                                  />
                                ) : (
                                  <WDLabelClient className="label_responsive">
                                    {featureaddFunding.recurring !== '' &&
                                    featureaddFunding.recurring !== undefined &&
                                    featureaddFunding.recurring !== null
                                      ? recurring.filter(
                                          (x) =>
                                            x.value ==
                                            featureaddFunding.recurring
                                        )?.[0]?.label
                                      : '--'}
                                  </WDLabelClient>
                                )}
                              </FeatureInfoColumn>
                            </FeatureInfoRow>
                          </DocumentRowContainer>
                        )}
                      </React.Fragment>
                    )}
                    {apiPreviewStatus.status !== '' && (
                      <ToastWrapper>
                        <Toast
                          text={apiPreviewStatus.text}
                          type={apiPreviewStatus.status}
                          openStatusCallback={(status: boolean) =>
                            setAPIPreviewStatus({
                              status: '',
                              text: '',
                            })
                          }
                        />
                      </ToastWrapper>
                    )}
                  </FormContainer>
                </FundingWrapper>
                {/* <FundingButtonWrapper>
       
                  {accessList.funding && !readOnly && (
                    <WDButtonOutLine>
                      <Button
                        type={'button'}
                        onClick={() => {
                          popupConfirmation()
                        }}
                      >
                        {loader ? (
                          <SpinnerButtonWrapper>
                            <LottieButtonWrapper>
                              <Lottie animationData={Loader} loop={true} />
                            </LottieButtonWrapper>
                          </SpinnerButtonWrapper>
                        ) : (
                          <WDLabelPrimaryText className="label_responsive">
                            {'Save'}
                          </WDLabelPrimaryText>
                        )}
                      </Button>
                    </WDButtonOutLine>
                  )}
                </FundingButtonWrapper> */}
              </FundWrapper>
            )}
          </>
        )}
      </StyledDocumentPanel>
    </ResponsiveWrapper>
  )
}
export default FundingInfo
