import { useRowSelect, useTable } from 'react-table'
import {
  RootContainer,
  TableBody,
  TableHead,
  TableHeaderCell,
  TableRow,
  TableWrapper,
  ThContentWrapper,
  ThWrapper,
} from './styles'
import { useMemo } from 'react'
import { TableProps } from './ITable'

const Table = ({
  tableColumns,
  tableData,
  onClick,
  hasFooter = false,
}: TableProps) => {
  const data = useMemo(() => tableData, [tableData])
  const columns = useMemo(() => tableColumns, [tableColumns])
  const tableInstance = useTable({ columns, data }, useRowSelect)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = tableInstance

  return (
    <RootContainer>
      <TableWrapper {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup, index) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, i) => (
                <TableHeaderCell {...column.getHeaderProps()} key={i}>
                  <ThWrapper>
                    <ThContentWrapper className="label_responsive">
                      {column.render('Header')}
                    </ThContentWrapper>
                  </ThWrapper>
                </TableHeaderCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                key={index}
                onClick={() => {
                  onClick(row.original)
                }}
                className="table-row"
              >
                {row.cells.map((cell, i) => {
                  return (
                    <td {...cell.getCellProps()} key={i} className="label_responsive">
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </TableBody>
        {hasFooter ? (
          <tfoot>
            {footerGroups.map((group, index) => (
              <tr {...group.getFooterGroupProps()} key={index}>
                {group.headers.map((column, i) => (
                  <td {...column.getFooterProps()} key={i}>
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        ) : null}
      </TableWrapper>
    </RootContainer>
  )
}

export default Table
